import { uploadFile } from "./api";

export const handleFileUpload = async (file, callback) => {
    if (file !== undefined) {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 2) return alert('File size exceeds 2 MiB');

        var reader = new FileReader();
        reader.onload = async function () {
            const res = await uploadFile(file)
            callback(res)
        };
        reader.readAsDataURL(file);
    }
}