import style from './style.module.css';
import MembershipTitle from "../../MembershipTitle";
import Info from "../../Info";
import RedButton from "../../RedButton";
import { useUser } from '../../../context/UserContext';
import { goToUrl } from '../../../utils/constants';
import { format } from 'date-fns';
import { useState } from 'react';
import { cancelSubscription } from '../../../utils/api';
import RedButtonLogin from '../../RedButtonLogin';
import LoginPopup from '../../LoginPopup';
import { toast, ToastContainer } from 'react-toastify';

export default function MembershipInfo(){
    const [showCancelationModal, setShowCancelationModal] = useState(false)
    const [showCancelationConfirm, setShowCancelationConfirm] = useState(false)
    const title = 'Membership Information';
    const infoSpanLeft = 'Status';
    const infoSpanRight = 'Expiration Date';

    const {user} = useUser()

    const doCancel = async () => {
       const res = await cancelSubscription()
       if (res) {
           setShowCancelationConfirm(true)
           setShowCancelationModal(false)
           toast.success("Subscription canceled!")
       } else toast.error("Something went wrong")
    }

    return(
        <>
        <div className={`${style['membership__container']}`}>
           {!showCancelationConfirm ? <>
                <MembershipTitle title={title}/>
                <div className={`${style['membership__flex']}`}>
                    <Info infoSpan={infoSpanLeft} infoText={user.membership ? user.status === 'trialing' ? '7-Days Trial' : "Currently subscribed" : 'Unsubscribed'}/>
                    {user.membership && <Info infoSpan={infoSpanRight} infoText={format(new Date(user.status === 'trialing' ? user.subscription_trial_end : user.subscription_date_end), 'dd/MM/yyyy')}/>}
                </div>
                <RedButton textButton={user.status === 'trialing' ? 'Start Membership' : 'Renew Membership'} onClick={(e) => goToUrl('renew-membership', e)}/>
                {user.membership && <p className={`${style['cancel-subscription__text']}`}>Do you want to cancel your subscription? <a className={`${style['cancel-subscription__link']}`} href="#" onClick={(e) => {
                    e.preventDefault()
                    setShowCancelationModal(true)
                }}>Cancel Subscription</a></p>}
           </> : <>
                <MembershipTitle title={"Your subscription has been cancelled "}/>
                <p>Your subscription has been removed from your cart. If you need further assistance please contact editorial.board@idiproject.com</p>
                <RedButtonLogin textButton="Go to the Homepage" onClick={(e) => goToUrl('', e)} />
           </>}
        </div>
        {showCancelationModal && <LoginPopup
                onClose={() => setShowCancelationModal(false)}
                onSubmit={() => {}}
                cardLabel="Cancel Subscription" 
                cardTitle="Are you sure to cancel your subscription?" 
                cardDescription="Your subscription will be removed from your cart.">
                    <div className={`${style['cancel-subscription__button-container']}`}>
                        <button className={`${style['cancel-subscription__button']}`} onClick={() => setShowCancelationModal(false)}>Close</button>
                        <RedButtonLogin 
                        textButton={"Yes, Proceed"}
                        onClick={(e) => {
                            e.preventDefault()
                            doCancel()
                        }} />
                    </div>
                </LoginPopup>}
                <ToastContainer
                    position="bottom-right"
                    autoClose={3000}
                />
        </>
    )
}