import React, { useEffect, useState } from 'react'
import style from './style.module.css'
import SearchDropdown from '../SearchDropdown'
import searchImg from '../../assets/Searchbar.svg'
import whiteSearch from '../../assets/whiteSearch.svg'
import { getReportsCountries, getSubjects } from '../../utils/api';
import { toast } from 'react-toastify'
import { set } from 'date-fns'

export default function ReportsSearch({searchReports, getDefaults}) {
    const [countries, setCountries] = useState([]);
    const [categories, setCategories] = useState([])
    const [country, setCountry] = useState('')
    const [category, setCategory] = useState('')
    const [filtersReset, setFiltersReset] = useState(false)
    const [shouldShowReset, setShouldShowReset] = useState(false)


    useEffect(() => {
        getCountriesData();
        getCategoriesData();
    }, []);

    const getCountriesData = async () => {
        const res = await getReportsCountries();
        if (res) {
            const countriesData = res;
            setCountries(countriesData);
            
        }
    }

    const getCategoriesData = async () => {
        const res = await getSubjects();
        if (res) {
            const categoriesData = res;
            setCategories(categoriesData);
        }
    }

    const handleChoice = (option, dropdownName) => {
        if (dropdownName === "Country") {
            setCountry(option.title);
        } else {
            setCategory(option.name);
        }
    }

    const sendSearch = async () => {
        if (country === '' && category === '') {
            toast.error('Please select at least one search parameter');
        } else {
            searchReports(country, category)
            setShouldShowReset(!shouldShowReset);
        }
    }

    const resetFilters = () => {
        setCountry('');
        setCategory('');
        setShouldShowReset(!shouldShowReset);
        setFiltersReset(!filtersReset);
        getDefaults();
    }

    const resetValue = (dropdownName) => {
        if (dropdownName === "Country") {
            setCountry('');
        } else {
            setCategory('');
        }
    }

  return (
    <>
        <h4>Search for country or category</h4>
        <div className={`${style['search-wrapper']}`}>
            <div className={`${style['filters-wrapper']}`}>
                <SearchDropdown
                    defaultString={"Country"}
                    options={countries}
                    stringAttribute={"title"}
                    handleChoice={handleChoice}
                    shouldReset={filtersReset}
                    resetValue={resetValue}
                />
                <SearchDropdown 
                    defaultString={"Category"}
                    options={categories}
                    stringAttribute={"name"}
                    handleChoice={handleChoice}
                    shouldReset={filtersReset}
                    resetValue={resetValue}
                />
            </div>
            <div className={`${style['search-actions']}`}>
                { shouldShowReset && <span className={`${style['reset-button']}`} onClick={resetFilters}>Reset</span>}
                <img src={searchImg} className={`${style["input_search_img"]}`} alt="search_img" onClick={sendSearch}/>
            </div>
            <div className={`${style["search__mobile-button"]}`} onClick={sendSearch}>
                <span>Search</span>
                <img src={whiteSearch} />
            </div>
        </div>
    </>
  )
}
