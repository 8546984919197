import React, { useEffect, useState } from 'react'
import style from './style.module.css'
import SearchBar from '../SearchBar'
import SearchDropdown from '../SearchDropdown'
import whiteSearch from '../../assets/whiteSearch.svg'
import SearchInput from '../SearchInput'
import SearchAutocomplete from '../searchAutocomplete'
import { casesAutocomplete, casesAutocompleteArbitral, getContracts, partiesAutocomplete, partiesAutocompleteArbitralCases } from '../../utils/api'

export default function ArbitralCasesSearch({doSearch, searchArbitralCases, getDefaults}) {
    const [contracts, setContracts] = useState([]);
    const [casesSuggestions, setCasesSuggestions] = useState([]);
    const [partiesSuggestions, setPartiesSuggestions] = useState([])
    const [caseNumber, setCaseNumber] = useState("");
    const [contract, setContract] = useState("");
    const [year, setYear] = useState("");
    const [parties, setParties] = useState("");
    const [filtersReset, setFiltersReset] = useState(false);
    const [shouldShowReset, setShouldShowReset] = useState(false)


    const handleChoice = (option, dropdownName) => {
        setContract(option.name);
    }

    const getContractsData = async () => {
        const res = await getContracts();
        if (res) {
            const contracts = res;
            setContracts(contracts);
        }
    }

    const handleChange = async (e) => {
        if (e.target.name === "search") {
            setCaseNumber(e.target.value);
            if (e.target.value.length > 2) {
                const res = await casesAutocompleteArbitral(e.target.value);
                if (res) {
                    setCasesSuggestions(res);
                }
            } else if (e.target.value.length < 3) {
                setCasesSuggestions([]);
            }
        } else if (e.target.name === "year") {
            setYear(e.target.value);
        } else if (e.target.name === "parties") {
            setParties(e.target.value);
            if (e.target.value.length > 2) {
                const res = await partiesAutocompleteArbitralCases(e.target.value);
                if (res) {
                    setPartiesSuggestions(res);
                }
            } else if (e.target.value.length < 3) {
                setPartiesSuggestions([]);
            }
        }
    }

    const handleAutocompleteClick = (suggestion, inputName) => {

        if (inputName === "search") {
            setCaseNumber(suggestion.title.split("-")[0].trim());
            setCasesSuggestions([])
        } else if (inputName === 'parties') {
            setParties(suggestion.content)
            setPartiesSuggestions([])
        }
    }

    const sendSearchData = () => {
        const data = {
            caseNumber,
            contract,
            year,
            parties
        }
        searchArbitralCases(data)
        setShouldShowReset(!shouldShowReset)
    }

    const resetFilters = () => {
        setCaseNumber('');
        setContract('');
        setYear('');
        setParties('');
        setFiltersReset(!filtersReset);
        getDefaults();
        setShouldShowReset(!shouldShowReset)
    }

    const resetValue = (dropdownName) => {
        if (dropdownName === "Contracts") {
            setContract('');
        }
    }

    useEffect(() => {
        getContractsData()
    }, [])
    

  return (
    <div className={`${style["searchbar__wrapper"]}`}>
        <p>Search for Case No, Contracts, Year, Parties</p>
        <div className={`${style['input-autocomplete__wrapper']}`}>
            <SearchBar
            placeholderText="Search..."
            onClick={sendSearchData}
            onChange={handleChange}
            value={caseNumber}
            />
            {casesSuggestions && casesSuggestions.length > 0 && <SearchAutocomplete size={"large"} category={'Arbitral Cases'} attribute={"title"} inputName={"search"} handleClick={handleAutocompleteClick} suggestions={casesSuggestions} />}
        </div>
        <div className={`${style['search-wrapper']} ${style['no-border']}`}>
            <div className={`${style["filters-wrapper"]}`}>
                <SearchDropdown 
                    defaultString={"Contracts"}
                    stringAttribute={"name"}
                    options={contracts}
                    handleChoice={handleChoice}
                    shouldReset={filtersReset}
                    resetValue={resetValue}
                />
                <SearchInput placeholder="Year" onChange={handleChange} value={year} name={"year"} />
                <div className={`${style['input-autocomplete__wrapper']}`}>
                    <SearchInput placeholder="Parties" onChange={handleChange} value={parties} name={"parties"} />
                    {partiesSuggestions && partiesSuggestions.length > 0 && <SearchAutocomplete size={"small"} attribute={"content"} inputName={"parties"} handleClick={handleAutocompleteClick} suggestions={partiesSuggestions} />}
                </div>
            </div>
            <div className={`${style["search__mobile-button"]}`} onClick={sendSearchData}>
                <span>Search</span>
                <img src={whiteSearch} />
            </div>
            {shouldShowReset && <span className={`${style['reset-button']}`} onClick={resetFilters} >Reset</span>}
        </div>
    </div>
  )
}

