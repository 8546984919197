import { useEffect, useState } from 'react'
import {useUser} from '../../context/UserContext'
import Sidebar from '../../components/Profile/Sidebar'
import style from './style.module.css'
import ProfilePersonalInformation from '../../components/Profile/ProfilePersonalInformation'
import ProfileCompanyInformation from '../../components/Profile/ProfileCompanyInformation'
import ProfileAccountInformation from '../../components/Profile/ProfileAccountInformation'
import MembershipInfo from '../../components/Profile/MembershipInfo'
import WhiteButton from '../../components/WhiteButton'
import RedButton from '../../components/RedButton'
import Area from '../../components/Profile/Area'
import queryString from 'query-string'
import NotloggedIn from '../../components/NotLoggedIn'
import CommonBanner from '../../components/CommonBanner'
import { goToUrl } from '../../utils/constants'




function MyProfile() {
    const {isLogged, user} = useUser()
    const [activeView, setActiveView] = useState('personalInformation')
    const query = new URLSearchParams(window.location.search);
    const param = query.get("param")
    useEffect(()=>{
        if (param != null){
            setActiveView(param);
        }
    },[])
    
    //in base al parametro mostrare la corrispettiva sezione
    return (
        isLogged ? <>
            <div className="main__container member__flex top__margin">
            <Sidebar sectionActive={activeView} onChange={setActiveView} />
            {activeView === 'accountInformation' && <ProfileAccountInformation />}
            {activeView === 'companyInformation' && <ProfileCompanyInformation />}  {/* && user.company_founder === 1  */}
            {activeView === 'personalInformation' && <ProfilePersonalInformation />}
            {activeView === 'membershipInformation'  && <MembershipInfo />}
            {activeView === 'memberArea' && <Area />}
            </div>
            {activeView === 'accountInformation' &&
                <div className="main__container">
                    <div className={`${style["banner_container"]}`}>
                        <h3 className={`${style["banner_title"]}`} >Do you want to delete your account?</h3>
                        <a href="mailto: info@idiproject.com" style={{textDecoration: 'none'}}>
                            <RedButton textButton={"Contact Us"}/>
                        </a>
                    </div>
                </div>
            }
        </> : <NotloggedIn/>
    )
}

export default MyProfile
