import MembershipTitle from '../../components/MembershipTitle';
import style from './style.module.css';
import CountryExpertCard from '../../components/CountryExpertCard';
import SearchBar from '../../components/SearchBar';
import chevronDown from '../../assets/chevronDown.svg'
import chevronRight from '../../assets/chevronRight.svg'
import whiteSearch from '../../assets/whiteSearch.svg'
import clear from '../../assets/clear.svg'
import { useEffect, useState } from 'react';
import { filterExperts, getCountries, getExperts } from '../../utils/api';
import Pagination from '../../components/Pagination';
import { toast } from 'react-toastify'

const ITEMS_PER_PAGE = 24

function CountryExpertPage() {
  const title = "Country Experts";
  const [experts, setExperts] = useState([]);
  const [filteredExperts, setFilteredExperts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageElements, setPageElements] = useState([]);
  const [search, setSearch] = useState("");
  const [country, setCountry] = useState(null);
  const [countryString, setCountryString] = useState("Country");
  const [countries, setCountries] = useState([]);
  const [countryDropdown, setCountryDropdown] = useState(false);
  const [shouldShowReset, setShouldShowReset] = useState(false)


  useEffect(() => {
    return () => {
      setCurrentPage(0);
    };
  }, []);

  const getCountriesData = async () => {
    const res = await getCountries();
    if (res) {
      console.log(res);
      const countries = res;
      setCountries(countries);
    }
  };

  const handleCountryChoice = (country) => {
    setCountryString(country.name);
    setCountry(country.id);
    setCountryDropdown(false);
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const s = urlParams.get("search");
    if (s) {
      setSearch(s);
      doSearch();
    }
  }, [experts]);

  useEffect(() => {
    if (filteredExperts) {
      const start = currentPage * ITEMS_PER_PAGE; // 1 * 10 = 10
      const end = currentPage * ITEMS_PER_PAGE + ITEMS_PER_PAGE; // 1 * 10 + (10 - 1) = 9
      setPageElements(filteredExperts.slice(start, end));
    }
  }, [currentPage, filteredExperts]);

  const doSearch = () => {
    // const results = search !== "" ? experts.filter(expert => expert.name.toLowerCase().split(" ").includes(search.toLowerCase()) || expert.surname.toLowerCase().split(" ").includes(search.toLowerCase()) || expert.job_title.toLowerCase().split(" ").includes(search.toLowerCase()) || expert.country_name.toLowerCase() === search.toLowerCase()) : experts
    // setCurrentPage(0)
    // setFilteredExperts(results)
    console.log(search);
    console.log(country);
    if (search === "" && country === null) {
      toast.error("Please select at least one search parameter")
    } else {
      const data = {
        fullname: search,
        country_id: country,
      };
      filterExperts(data).then((res) => {
        if (res) {
          console.log(res);
          setFilteredExperts(res);
          setShouldShowReset(!shouldShowReset);
        } else {
          setFilteredExperts([]);
        }
      });
    }
  };

  const handleDropdown = () => {
    if (countryString === "Country") {
      setCountryDropdown(!countryDropdown);
    }
  };

  const resetCountry = () => {
    setCountryString("Country");
    setCountry(null);
  };

  const resetFilters = () => {
    setSearch("");
    setCountry(null);
    setCountryString("Country");
    setCountryDropdown(false);
    setFilteredExperts(experts);
    setShouldShowReset(!shouldShowReset);
  }

  useEffect(async () => {
    const res = await getExperts();
    if (res) {
      const expertsResult = res.sort((a, b) =>
        a.surname.toLowerCase() > b.surname.toLowerCase() ? 1 : -1
      );
      setExperts(expertsResult);
      setFilteredExperts(expertsResult);
    }
    getCountriesData();
  }, []);

  return (
    <div className="main__container text-center membership__section">
      <MembershipTitle title={title} AlignText="center" />
      <p className={`${style["member__text"]}`}>
        Over 90 CVs of country specialists (IDI Country Experts) in agency,
        distribution and franchising. The country experts draft the country
        reports and are responsible for all information regarding the
        legislation of their countries.
      </p>
      <div className={`${style["searchbar__wrapper"]}`}>
        <p className={`${style["search-paragraph"]}`}>Search for Expert name or Country and <u>click on the hand lens</u></p>
        <SearchBar
          placeholderText="Expert name..."
          onClick={doSearch}
          onChange={(event) => setSearch(event.target.value)}
          value={search}
        />
        <div className={`${style["filters-wrapper"]}`}>
          <div className={`${style["country__select"]}`}>
            <div onClick={handleDropdown} className={`${style["custom-select"]} ${countryString !== "Country" ? style["selected"] : ""}`}>
              <span className={`${style["select__text"]}`}>{countryString}</span>
              {countryString !== "Country" ? (
                <img src={clear} onClick={resetCountry} />
              ) : (
                <img src={chevronDown} />
              )}
            </div>
            {countryDropdown && (
              <div className={`${style["country-dropdown"]}`}>
                <ul>
                  {countries.map((country) => (
                    <li
                      key={country.id}
                      onClick={() => handleCountryChoice(country)}
                    >
                      <span>{country.name}</span>
                      <img src={chevronRight} />
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          {shouldShowReset && <span onClick={resetFilters} className={`${style['reset-button']}`}>Reset</span>}
        </div>
        <div className={`${style["search__mobile-button"]}`} onClick={doSearch}>
          <span>Search</span>
          <img src={whiteSearch} />
        </div>
      </div>
      {filteredExperts.length != 0 ? (
        <div className={`${style["memberpage__flex"]}`}>
          {pageElements.map((expert) => (
            <CountryExpertCard key={expert.id} expert={expert} />
          ))}
        </div>
      ) : (
        <div className={`${style["no-expert-found"]}`}>No expert found</div>
      )}
      <Pagination
        currentPage={currentPage}
        items={filteredExperts}
        setCurrentPage={setCurrentPage}
        itemsPerPage={ITEMS_PER_PAGE}
      />
    </div>
  );
}

export default CountryExpertPage;