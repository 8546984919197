import style from './style.module.css';
import Avatar from '../../components/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { getIdarb, getIdarbs } from '../../utils/api';
import { getFile, getImage, goToUrl } from '../../utils/constants';
import DistributionCase from '../../components/DistributionCase';
import avatarPlaceholder from '../../assets/avatar-placeholder.svg';

function IdarbProfile() {
    const [idarb, setIdarb] = useState({})
    const [showDistributionCase, setShowDistributionCase] = useState({})
    

    useEffect(async () => {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString);
        const id = urlParams.get('id')
        if (id) {
            const res = await getIdarb(id)
            if (res) setIdarb(res)
        } else {
            const idarb = urlParams.get('idarb')
            if (idarb) {
                const idarbName = idarb.toLowerCase().split("-")
                const idarbs = await getIdarbs()
                const foundIdarb = idarbs.find(idarb => idarb.name.toLowerCase().split(" ").every(element => {
                    return idarbName.includes(element);
                  }) &&  idarb.surname.toLowerCase().split(" ").every(element => {
                    return idarbName.includes(element);
                  }))

                if (foundIdarb) {
                    const res = await getIdarb(foundIdarb.id)
                    if (res) setIdarb(res)
                }
            }
        }
    }, [])

    console.log(showDistributionCase, Object.keys(showDistributionCase).length)

    return idarb ? (
        <>     
    {/*header*/}
    <div className={`${style["s-country__header"]}`}>
        <div className={`${style["main__container"]}`}>
            <div className={`${style["flex__start-center"]}`}>
            <Avatar imgSrc={idarb.image ? getImage(idarb.image) : avatarPlaceholder} imgSize="extra-large"/>  
              <div className={`${style["s-country__detail"]}`}>
                    <h1 id="sce-name" className={`${style["s-country__name"]}`}>{idarb.name} {idarb.surname}</h1>
                    <h2 id="sce-details" className={`${style["s-country__details"]}`}><strong>{idarb.country}</strong> | Arbitrator</h2>
                </div>
            </div>
        </div>
    </div>
    {/*end header*/}
    {/*country idarb boxes*/}
    {/*DESKTOP*/}
    <div className={`${style["main__container"]} ${style["sce__desktop"]}`}>
        <div className={`${style["flex__start-between"]} ${style["s-country__content"]}`}>
            {/*SX*/}
            <div className={`${style["s-country__large"]}`}>
                <div className={`${style["s-country__box"]}`}>
                    {/*section 1*/}
                    <div className={`${style["box__detail"]}`}>
                        <div className={`${style["box__title"]}`}>
                            <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Year of birth</h3>
                        </div>
                        <div className={`${style["box__description"]}`}>
                            <p id="sce-birth-yr" className={`${style["box__description-text"]} ${style["m-0"]}`}>{idarb.year_of_birth}</p>
                        </div>
                    </div>
                    {/*end section 2*/}
                    {/*section*/}
                    {idarb.languages != "" &&
                        <div className={`${style["box__detail"]}`}>
                            <div className={`${style["box__title"]}`}>
                                <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Languages</h3>
                            </div>
                            <div className={`${style["box__description"]}`}>
                                <p id="sce-languages" className={`${style["box__description-text"]} ${style["m-0"]}`}>{idarb.languages}</p>
                            </div>
                        </div>
                    }
                    {/*end section*/}
                     {/*section*/}
                     {idarb.professional_qualification != "" &&
                        <div className={`${style["box__detail"]}`}>
                            <div className={`${style["box__title"]}`}>
                                <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Professional qualifications</h3>
                            </div>
                            <div className={`${style["box__description"]}`}>
                                <p id="sce-professional_qualification" className={`${style["box__description-text"]} ${style["m-0"]}`}>{idarb.professional_qualification}</p>
                            </div>
                        </div>
                    }
                    {/*end section*/}
                    {/*section*/}
                    {idarb.experience_field_distribution != '' && idarb.experience_field_distribution != '' &&  idarb.experience_field_distribution != null &&
                        <div className={`${style["box__detail"]}`}>
                            <div className={`${style["box__title"]}`}>
                                <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Experience in the field of distribution</h3>
                            </div>
                            <div className={`${style["box__description"]}`}>
                                <p id="sce-experience_field_distribution" className={`${style["box__description-text"]} ${style["m-0"]}`}>{idarb.experience_field_distribution}</p>
                            </div>
                        </div>
                       
                    }
                    {/*end section*/}
                    {/*section 3*/}
                    {idarb.litigation_experience != '...' &&
                        <div className={`${style["box__detail"]}`}>
                            <div className={`${style["box__title"]}`}>
                                <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Litigation experience</h3>
                            </div>
                            <div className={`${style["box__description"]}`}>
                                <p id="sce-litigation_experience" className={`${style["box__description-text"]} ${style["m-0"]}`}>{idarb.litigation_experience}</p>
                            </div>
                        </div>
                    }
                    {/*end section*/}
                    {/*section 4*/}
                    {idarb.arbitration_experience != '' &&
                        <div className={`${style["box__detail"]}`}>
                            <div className={`${style["box__title"]}`}>
                                <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Arbitration experience</h3>
                            </div>
                            <div className={`${style["box__description"]}`}>
                                <p id="sce-others" className={`${style["box__arbitration_experience-text"]} ${style["m-0"]}`}>{idarb.arbitration_experience}</p>
                            </div>
                        </div>
                    }
                    {/*end section*/}
                </div>
                <div className={`${style["s-country__box"]}`}>
                    <div className={`${style["box__detail"]}`}>
                        <div className={`${style["box__title"]}`}>
                            <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Distribution Case</h3>
                        </div>
                        <div className={`${style["box__description"]}`}>
                            {idarb.distribution_cases && idarb.distribution_cases.map(caseItem => <li className={`${style["box__case"]} ${style["m-0"]}`} onClick={() => setShowDistributionCase(caseItem)}>{caseItem.title}</li>)}
                        </div>
                    </div>   
                </div>
            </div>
            {/*DX*/}
            <div className={`${style["s-country__small"]}`}>
               {/*contacts*/}
                <div className={`${style["s-country__box-small"]}`}>
                    <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Contacts</h3>
                    <div className={`${style["flex__start-between"]} ${style["sce-contact__box"]}`}>
                       {idarb.phone != '...' && <div className={`${style["flex__start-center"]} ${style["contact__idarb-m"]}`}>
                           <div className={`${style["contact-icon-box"]} `}>
                           <FontAwesomeIcon icon={faPhoneAlt} className={`${style["contact__icon"]} `}/>
                           </div>
                            <p id="sce-xnumber" className={`${style["box__description-text"]} `}><a className={`${style["contact__link"]}`} href={`tel:+${idarb.phone}`}>{idarb.phone}</a></p>
                       </div>}
                       {idarb.email != '...' && <div className={`${style["flex__start-center"]} ${style["contact__idarb-m"]}`}>
                            <div className={`${style["contact-icon-box"]} `}>
                            <FontAwesomeIcon icon={faEnvelope} className={`${style["contact__icon"]} `} />
                            </div>
                            <p id="sce-mail" className={`${style["box__description-text"]} `}><a className={`${style["contact__link"]}`} href={`mailto:${idarb.email}`}>{idarb.email}</a></p>
                        </div>}
                        {idarb.website != '...' && <div className={`${style["flex__start-center"]} ${style["contact__idarb-m"]}`}>
                            <div className={`${style["contact-icon-box"]} `}>
                            <FontAwesomeIcon icon={faGlobe} className={`${style["contact__icon"]} `} />
                            </div>
                            <p id="sce-website" className={`${style["box__description-text"]} `}><a className={`${style["contact__link"]}`} href={idarb.website}>{idarb.website}</a></p>
                        </div>}
                    </div>
                </div>
                
                {/*curriculum*/}
                <div className={`${style["s-country__box-small-article"]}`}>
                    <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Curriculum</h3>
                    <div className={`${style["flex-column"]}${style["sce-contact__box"]} `}>
                        {idarb.curriculum != '...' && idarb.curriculum != null ? <div className={`${style["sce-article__link"]}`}>
                            <a href={getFile(idarb.curriculum)} className={`${style["idi-arrow__link"]} ${style["sce-article__link"]}`} >Download CV <FontAwesomeIcon icon={faArrowRight} className={`${style["arrow-right"]}`} /></a>
                        </div> : <div></div>}
                    </div>
                </div>
                
                {/*first_reccomandation*/}
               {/* <div className={`${style["s-country__box-small-article"]}`}>
                    <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Reccomendations</h3>
                    { idarb.first_reccomandation === "" && idarb.second_reccomandation === "" ? "There are no reccomendations" :  <><div className={`${style["flex-column"]}${style["sce-contact__box"]} `}>
                        {idarb.first_reccomandation != "" && idarb.first_reccomandation != null ? <div className={`${style["sce-article__link"]}`}>
                            <a href={getFile(idarb.first_reccomandation)} className={`${style["idi-arrow__link"]} ${style["sce-article__link"]}`}>Download First Reccomendation <FontAwesomeIcon icon={faArrowRight} className={`${style["arrow-right"]}`} /></a>
                        </div> : <div></div>}
                    </div>
                    <div className={`${style["flex-column"]}${style["sce-contact__box"]} `}>
                        {idarb.second_reccomandation != "" && idarb.second_reccomandation != null ? <div className={`${style["sce-article__link"]}`}>
                            <a href={getFile(idarb.second_reccomandation)} className={`${style["idi-arrow__link"]} ${style["sce-article__link"]}`}>Download Second Reccomendation <FontAwesomeIcon icon={faArrowRight} className={`${style["arrow-right"]}`} /></a>
                        </div> : <div></div>}
                    </div></>}
                </div> */}
                
                {/*second_reccomandation*/}
                {/* <div className={`${style["s-country__box-small-article"]}`}>
                    <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Second Reccomandation</h3>
                    <div className={`${style["flex-column"]}${style["sce-contact__box"]} `}>
                        {idarb.second_reccomandation != '...' && idarb.second_reccomandation != null ? <div className={`${style["sce-article__link"]}`}>
                            <a href={getFile(idarb.second_reccomandation)} className={`${style["idi-arrow__link"]} ${style["sce-article__link"]}`}>Download Second Reccomandation <FontAwesomeIcon icon={faArrowRight} className={`${style["arrow-right"]}`} /></a>
                        </div> : <div></div>}
                    </div>
                </div> */}
            </div>
        </div>
    </div>
    {/*end country idarb boxes*/}
    {/*END DESKTOP*/}  

    {/*MOBILE*/}
    <div className={`${style["main__container"]} ${style["sce__mobile"]}`}>
        {/*contacts*/}
        <div className={`${style["s-country__box__small"]}`}>
            <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Contacts</h3>
            <div className={`${style["flex__start-between"]} ${style["sce-contact__box"]}`}>
               <div className={`${style["flex__start-center"]} ${style["contact__idarb-m"]}`}>
               <div className={`${style["contact-icon-box"]} `}>
                    <FontAwesomeIcon icon={faPhoneAlt} className={`${style["contact__icon"]} `}/>
                </div>                    
                <p id="sce-number" className={`${style["box__description-text"]} `}><a className={`${style["contact__link"]}`} href={`tel:+${idarb.phone}`}>{idarb.phone}</a></p>
               </div>
               <div className={`${style["flex__start-center"]} ${style["contact__idarb-m"]}`}>
               <div className={`${style["contact-icon-box"]} `}>
                    <FontAwesomeIcon icon={faEnvelope} className={`${style["contact__icon"]} `}/>
                </div>                    
                <p id="sce-mail" className={`${style["box__description-text"]} `}><a className={`${style["contact__link"]}`} href={`mailto:${idarb.email}`}>{idarb.email}</a></p>
                </div> 
                <div className={`${style["flex__start-center"]} ${style["contact__idarb-m"]}`}>
                <div className={`${style["contact-icon-box"]} `}>
                    <FontAwesomeIcon icon={faGlobe} className={`${style["contact__icon"]} `}/>
                </div>                    
                <p id="sce-website" className={`${style["box__description-text"]} `}><a className={`${style["contact__link"]}`} href={idarb.website}>{idarb.website}</a></p>
                </div>
            </div>
        </div>
        {/*main content*/}
        <div className={`${style["s-country__large"]}`}>
            <div className={`${style["s-country__box"]}`}>
                
                    {/*section 1*/}
                    <div className={`${style["box__detail"]}`}>
                        <div className={`${style["box__title"]}`}>
                            <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Year of birth</h3>
                        </div>
                        <div className={`${style["box__description"]}`}>
                            <p id="sce-birth-yr" className={`${style["box__description-text"]} ${style["m-0"]}`}>{idarb.year_of_birth}</p>
                        </div>
                    </div>
                    {/*end section 2*/}
                    {/*section*/}
                    {idarb.languages != "" &&
                        <div className={`${style["box__detail"]}`}>
                            <div className={`${style["box__title"]}`}>
                                <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Languages</h3>
                            </div>
                            <div className={`${style["box__description"]}`}>
                                <p id="sce-languages" className={`${style["box__description-text"]} ${style["m-0"]}`}>{idarb.languages}</p>
                            </div>
                        </div>
                    }
                    {/*end section*/}
                     {/*section*/}
                     {idarb.professional_qualification != "" &&
                        <div className={`${style["box__detail"]}`}>
                            <div className={`${style["box__title"]}`}>
                                <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Professional qualifications</h3>
                            </div>
                            <div className={`${style["box__description"]}`}>
                                <p id="sce-professional_qualification" className={`${style["box__description-text"]} ${style["m-0"]}`}>{idarb.professional_qualification}</p>
                            </div>
                        </div>
                    }
                    {/*end section*/}
                    {/*section*/}
                    {idarb.experience_field_distribution != '' && idarb.experience_field_distribution != '' &&  idarb.experience_field_distribution != null &&
                        <div className={`${style["box__detail"]}`}>
                            <div className={`${style["box__title"]}`}>
                                <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Experience in the field of distribution</h3>
                            </div>
                            <div className={`${style["box__description"]}`}>
                                <p id="sce-experience_field_distribution" className={`${style["box__description-text"]} ${style["m-0"]}`}>{idarb.experience_field_distribution}</p>
                            </div>
                        </div>
                       
                    }
                    {/*end section*/}
                    {/*section 3*/}
                    {idarb.litigation_experience != '...' &&
                        <div className={`${style["box__detail"]}`}>
                            <div className={`${style["box__title"]}`}>
                                <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Litigation experience</h3>
                            </div>
                            <div className={`${style["box__description"]}`}>
                                <p id="sce-litigation_experience" className={`${style["box__description-text"]} ${style["m-0"]}`}>{idarb.litigation_experience}</p>
                            </div>
                        </div>
                    }
                    {/*end section*/}
                    {/*section 4*/}
                    {idarb.arbitration_experience != '' &&
                        <div className={`${style["box__detail"]}`}>
                            <div className={`${style["box__title"]}`}>
                                <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Arbitration experience</h3>
                            </div>
                            <div className={`${style["box__description"]}`}>
                                <p id="sce-others" className={`${style["box__arbitration_experience-text"]} ${style["m-0"]}`}>{idarb.arbitration_experience}</p>
                            </div>
                        </div>
                    }
                    {/*end section*/}

                
            </div>
            <div className={`${style["s-country__box"]}`}>
                <div className={`${style["box__detail"]}`}>
                    <div className={`${style["box__title"]}`}>
                        <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Distribution Case</h3>
                    </div>
                    <div className={`${style["box__description"]}`}>
                        {idarb.distribution_cases && idarb.distribution_cases.map(caseItem => <li className={`${style["box__case"]} ${style["m-0"]}`} onClick={() => setShowDistributionCase(caseItem)}>{caseItem.title}</li>)}
                    </div>
                </div>   
            </div>
        </div>
        {/*end main content*/}

        {/*curriculum*/}
        <div className={`${style["s-country__box-small-article"]}`}>
            <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Curriculum</h3>
            <div className={`${style["flex-column"]}${style["sce-contact__box"]} `}>
                {idarb.curriculum != '...' && idarb.curriculum != null ? <div className={`${style["sce-article__link"]}`}>
                    <a href={getFile(idarb.curriculum)} className={`${style["idi-arrow__link"]} ${style["sce-article__link"]}`} >Download CV <FontAwesomeIcon icon={faArrowRight} className={`${style["arrow-right"]}`} /></a>
                </div> : <div></div>}
            </div>
        </div>
        
        {/*first_reccomandation*/}
        {/* <div className={`${style["s-country__box-small-article"]}`}>
            <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>First Reccomandation</h3>
            <div className={`${style["flex-column"]}${style["sce-contact__box"]} `}>
                {idarb.first_reccomandation != "" && idarb.first_reccomandation != null ? <div className={`${style["sce-article__link"]}`}>
                    <a href={getFile(idarb.first_reccomandation)} className={`${style["idi-arrow__link"]} ${style["sce-article__link"]}`}>Download First Reccomandation <FontAwesomeIcon icon={faArrowRight} className={`${style["arrow-right"]}`} /></a>
                </div> : <div></div>}
            </div>
        </div>
        
        <div className={`${style["s-country__box-small-article"]}`}>
            <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Second Reccomandation</h3>
            <div className={`${style["flex-column"]}${style["sce-contact__box"]} `}>
                {idarb.second_reccomandation != "" && idarb.second_reccomandation != null ? <div className={`${style["sce-article__link"]}`}>
                    <a href={getFile(idarb.second_reccomandation)} className={`${style["idi-arrow__link"]} ${style["sce-article__link"]}`}>Download Second Reccomandation <FontAwesomeIcon icon={faArrowRight} className={`${style["arrow-right"]}`} /></a>
                </div> : <div></div>}
            </div>
        </div> */}

         
    </div>
    {/*END MOBILE*/}
                
    {Object.keys(showDistributionCase).length > 0 && <DistributionCase cardTitle={showDistributionCase.title} cardDescription={showDistributionCase.description}  onClose={()=>setShowDistributionCase({})} />}
    </>
    ) : <div></div>
}

export default IdarbProfile