import style from './style.module.css';

export default function Info({infoSpan , infoText}){


    return(
        <div className={`${style['info__container']}`}>
           <span className={`${style['info__span']}`}>{infoSpan}</span>
           <span className={`${style['info__text']}`}>{infoText}</span>
        </div>
    )
}