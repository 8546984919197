import style from './style.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';



export default function MemberCardIcons({values}){


    return(
        <section className={`${style['membercard-icons__flex']}`}>
            <div className={`${style['membercard__phone']}`}>
            {values.phone && <a className={`${style["telephone__container"]}`} href={`tel:${values.phone}`}>
                        <div className={`${style['icon__circle']}`}>
                            <FontAwesomeIcon icon={faPhoneAlt}  />
                        </div>
                        <a className={`${style['telephone__numb']}`} href={`tel:${values.phone}`}>{values.phone}</a>
                </a>}
                
            </div>
            <div className={`${style['member__card-other-info']}`}>
                {values.email && <a className={`${style["membercard_icon"]}`} href={`mailto:${values.email}`}>
                    <div className={`${style['icon__circle']}`}>
                        <FontAwesomeIcon icon={faEnvelope} />
                    </div>
                </a>}
                {values.website && <a className={`${style["membercard_icon"]}`} href={values.website}>
                    <div className={`${style['icon__circle']}`}>
                        <FontAwesomeIcon icon={faGlobe} />
                    </div>
                </a>}
            </div>
        </section>
    )
}