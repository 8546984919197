import { differenceInDays, startOfToday } from "date-fns";

export async function doAuth(email, password) {
    const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/auth/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({email, password})
    })

    const result = await res.json()

    // result.data should contain the jwt
    // store the token into localStorage or a COOKIE
    if (result.status === "success") {
        const event = new CustomEvent('doLogin', { detail: result.data });
        localStorage.setItem('token', result.data.auth_token)
        window.dispatchEvent(event);


        console.log("Login", result.data.membership, result.data.subscription_trial_end, result.data.membership && result.data.subscription_trial_end)
        if (result.data.membership && result.data.status === "trialing" && result.data.subscription_trial_end) {
            const trialEvent = new Event('showTrial');
            window.dispatchEvent(trialEvent);
        }

        if (result.data.membership && result.data.status === "active" && result.data.subscription_end) {
            if (differenceInDays(new Date(result.data.subscription_end), startOfToday()) === 7) {
                const membershipEvent = new Event('showMembershipExpire');
                window.dispatchEvent(membershipEvent);
            }
        }
        
        return {...result.data, status: "success"}
    }
    return result
}

export async function checkAuth(token) {
    const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/auth/check-token`, {
        method: "POST",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
    })

    const result = await res.json()
    
    if (result.status === "success") {
        const event = new CustomEvent('checkAuth', { detail: result.data });
        window.dispatchEvent(event);
        return result.data
    }
    return false
}

export async function checkPasswordToken(token) {
    const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/auth/check-password-token/${token}`, {
        method: "GET",
        headers: { "Content-Type": "application/json"}
    })

    const result = await res.json()
    
    if (result.status === "success") {
        return true
    }
    return false
}

export async function resetPassword(token, password) {
    const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/auth/reset-password/${token}`, {
        method: "POST",
        headers: { "Content-Type": "application/json"},
        body: JSON.stringify({data: {
            new_password: password,
            new_password_confirm: password
        }})
    })

    const result = await res.json()
    
    if (result.status === "success") {
        return true
    }
    return false
}

export async function emailConfirm(token) {
    const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/email-confirm`, {
        method: "POST",
        headers: { "Content-Type": "application/json"},
        body: JSON.stringify({token})
    })

    const result = await res.json()
    
    if (result.status === "success") {
        return true
    }
    return false
}

export async function doForgotPassword(email) {
    const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/auth/forgot-password/${email}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" }
    })

    const result = await res.json()

    if (result.status === "success") {
        return result.message
    }
    return result
}


export async function getActiveConference() {
    const token = localStorage.getItem('token')

    const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/conference/active`, {
        method: "GET",
        headers: token ? { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` } :{ "Content-Type": "application/json"} ,
    })

    const result = await res.json()

    return result.data
}

export async function getExperts() {
    try {
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/experts`, {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        })

        const result = await res.json()
        if (result.status === "success") return result.experts
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function getCountries() {
    try {
        const token = localStorage.getItem('token')
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/countries`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })

        const result = await res.json()
        if (result.status === "success") return result.countries
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function getReportsCountries() {
    try {
        const token = localStorage.getItem('token')
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/search/report-countries`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })

        const result = await res.json()
        if (result.status === "success") return result.data
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function getUsersCountries() {
    try {
        const token = localStorage.getItem('token')
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/search/users/countries`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })

        const result = await res.json()
        if (result.status === "success") return result.data
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function getSubjects() {
    try {
        const token = localStorage.getItem('token')
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/search/subjects`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })

        const result = await res.json()
        if (result.status === "success") return result.data
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function getExpert(id) {
    try {
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/experts/${id}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        })

        const result = await res.json()
        if (result.status === "success") return result.expert
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function filterExperts(data) {
    try {
        const token = localStorage.getItem('token')
        const params = new URLSearchParams();
        if (data.fullname) {
            params.append("fullname", data.fullname);
        }
        if (data.country_id) {
            params.append("country_id", data.country_id);
        }
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/search/experts?${params.toString()}`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })

        const result = await res.json()
        if (result.status === "success") return result.experts
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function getReports(country) {
    try {
        const token = localStorage.getItem('token')
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/search/reports/${country}`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })

        const result = await res.json()
        if (result.status === "success") return result.data
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}


export async function getIdarbs() {
    try {
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/arbitrators`, {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        })

        const result = await res.json()
        if (result.status === "success") return result.arbitrators
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function getJudges() {
    const token = localStorage.getItem('token')
    try {
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/search/judges`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })

        const result = await res.json()
        if (result.status === "success") return result.data
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function getContracts() {
    const token = localStorage.getItem('token')
    try {
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/search/contracts`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })

        const result = await res.json()
        if (result.status === "success") return result.data
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function casesAutocomplete(caseNumber) {
    const token = localStorage.getItem('token')
    try { 
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/search/case-number?case=${caseNumber}`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })

        const result = await res.json()
        if (result.status === "success") return result.data
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function casesAutocompleteArbitral(caseNumber) {
    const token = localStorage.getItem('token')
    try { 
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/search/case-number-arbitral?case=${caseNumber}`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })

        const result = await res.json()
        if (result.status === "success") return result.data
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function partiesAutocompleteEUCaseLaw(party) {
    const token = localStorage.getItem('token')
    try {
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/search/parties/eu-case-law?party=${party}`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })

        const result = await res.json()
        if (result.status === "success") return result.data
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function partiesAutocompleteArbitralCases(party) {
    const token = localStorage.getItem('token')
    try {
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/search/parties/arbitral-cases?party=${party}`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })

        const result = await res.json()
        if (result.status === "success") return result.data
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function searchEUCaseLaw(data) {
    const token = localStorage.getItem('token')
    const params = new URLSearchParams();
    if (data.caseNumber) {
        params.append("case", data.caseNumber);
    }
    if (data.judge) {
        params.append("judge", data.judge);
    }
    if (data.contract) {
        params.append("contracts", data.contract);
    }
    if (data.year) {
        params.append("date", data.year);
    }
    if (data.parties) {
        params.append("parties", data.parties);
    }
    try {
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/search/eu-case-law?${params.toString()}`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })

        const result = await res.json()
        if (result.status === "success") return result.data
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function searchArbitralCases(data) {
    const token = localStorage.getItem('token')
    const params = new URLSearchParams();
    if (data.caseNumber) {
        params.append("case", data.caseNumber);
    }
    if (data.contract) {
        params.append("contracts", data.contract);
    }
    if (data.year) {
        params.append("date", data.year);
    }
    if (data.parties) {
        console.log(data.parties);
        params.append("parties", data.parties);
    }
    try {
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/search/arbitral-cases?${params.toString()}`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` }
        })

        const result = await res.json()
        if (result.status === "success") return result.data
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function getDemoCase() {
    try {
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/search/demo/eu-case-law`, {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        })

        const result = await res.json()
        if (result.status === "success") return result.data
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

//check company
export async function checkCompany(data) {
    // const token = localStorage.getItem('token')
    try {
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/check-company?name=${data.name}&country=${data.country}&postal_code=${data.postal_code}`, {
            method: "GET",
            headers: { "Content-Type": "application/json"}
        })

        const result = await res.json()
        if (result.status === "success") return result
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}


export async function getIdarb(id) {
    try {
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/arbitrators/${id}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        })

        const result = await res.json()
        if (result.status === "success") return result.arbitrator
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function getDocuments(category) {
    const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/documents-by-category/${category}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" }
    })

    const result = await res.json()

    return result.data
}

export async function getJudicialDocuments() {
    const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/judicial_documents`, {
        method: "GET",
        headers: { "Content-Type": "application/json" }
    })

    const result = await res.json()

    return result.judicial_documents
}

export async function getJudicialTexts() {
    const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/judicial_texts`, {
        method: "GET",
        headers: { "Content-Type": "application/json" }
    })

    const result = await res.json()

    return result.judicial_texts
}

export async function getDocument(id) {
    const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/documents/${id}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" }
    })

    const result = await res.json()

    return result.data
}


export async function updateUser(id, data) {
    const token = localStorage.getItem('token')
    const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/users/${id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        body: JSON.stringify({user: data})
    })

    const result = await res.json()
    if (result.status === "success") return true
    return result.message
}

export async function updateCompany(id, data) {
    const token = localStorage.getItem('token')
    const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/company/${id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        body: JSON.stringify({company: data})
    })

    const result = await res.json()
    if (result.status === "success") return true
    return false
}

export async function getMembership() {
    try {
        const token = localStorage.getItem('token')
        if (token) {
            const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/renew-membership`, {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
            })

            const result = await res.json()
            if (result.status === "success") return result
            return false
        }
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function renewMembership(cart, customer) {
    try {
        const token = localStorage.getItem('token')
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/renew-membership`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
            body: JSON.stringify(
                {
                    user: {stripe_customer_id: customer},
                    add_users: cart.additionalUsers,
                    billing_information: {...cart.billing_information}
                }
            ),
        })

        const result = await res.json()
        if (result.status === "success") return result
        return {status: "error", message: result.message}
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function signUp(cart, customer) {
    try {
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/sign-up`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(
                {
                    user: {...cart.mainUser, stripe_customer_id: customer},
                    company: cart.company,
                    add_users: cart.additionalUsers,
                    conferences: cart.conferences && cart.conferences.length > 0 ? cart.conferences.map(conference => { return {...conference, streaming: cart.streaming ? true : false}}) : null,
                    billing_information: cart.billing_information ? {...cart.billing_information} : null
                }
            ),
        })

        const result = await res.json()

        if (result.status === "success") return result
        return {status: "error", message: result.message}

    } catch (e) {
        console.log(e)
        return false
    }
}

export async function cancelSubscription() {
    try {
        const token = localStorage.getItem('token')
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/stripe/cancel-subscription`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        })

        const result = await res.json()

        if (result.status === "success") return true
        return false

    } catch (e) {
        console.log(e)
        return false
    }
}

export async function bookConference(cart) {
    try {
        const token = localStorage.getItem('token')
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/booking/conference`, {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
            body: JSON.stringify(
                {
                    add_users: cart.additionalUsers,
                    conferences: cart.conferences.map(conference => { return {...conference, streaming: cart.streaming ? true : false}}),
                }
            ),
        })

        const result = await res.json()

        if (result.status === "success") return result
        return {status: "error", message: result.message}

    } catch (e) {
        console.log(e)
        return false
    }
}


export async function getPrices() {
    const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/prices`, {
        method: "GET",
        headers: { "Content-Type": "application/json" }
    })

    const result = await res.json()
    if (result.status === "success") {
        return result.data
    }
    
    return false
}

export async function getCoupon(coupon) {
    const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/stripe/coupon/${coupon}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" }
    })

    const result = await res.json()
    if (result.status === "success") {
        return result.coupon
    }
    
    return false
}

export async function getMemberArea() {
    try{
        const token = localStorage.getItem('token')
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/member-area`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        })

        const result = await res.json()
        if (result.status === "success") {
            return result.user
        }
        
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function getMemberAreaSearch(query) {
    try{
        const token = localStorage.getItem('token')
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/search/member-area${query}`, {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        })

        const result = await res.json()
        if (result.status === "success") {
            return result
        }
        
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}


export async function uploadFile(file) {
    const token = localStorage.getItem('token')

    const fd = new FormData();
    fd.append('file', file);

    try {

    const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/upload`, {
        method: "POST",
        headers: { 'Authorization': `Bearer ${token}` },
        body: fd
    })

    const result = await res.json()

    if (result.status === "success") {
        return result.file
    }
    
    return false

    } catch (e) {
        console.log(e)
        return false
    }
}

export async function getSearchResults(query) {
    const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/search/${query}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" }
    })

    const result = await res.json()
    if (result.status === "success") {
        return result.data
    }
    
    return false
}

export async function getWPSearchResults(query) {
    const res = await fetch(`${process.env.REACT_APP_PUBLIC_SITE}/wp-json/wp/v2/search?search=${query}&per_page=100`, {
        method: "GET",
        headers: { "Content-Type": "application/json" }
    })

    const result = await res.json()
    return result
    
    return false
}

export async function getHomepageSearchResults(s){
    const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/search/all${s}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" }
    })

    const result = await res.json()
    if (result.status === "success") {
        return result.data
    }
    
    return false
}