import style from './style.module.css';

export default function MembershipTitle({title,AlignText}){


    return(
        
            <h1 className={`${style['membership__title']}`} style={{textAlign:AlignText}}>{title}</h1>
        
    )
}