import React, {useEffect, useState} from 'react'
import { getHomepageSearchResults } from '../../utils/api';
import style from './style.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../../components/Pagination';

const ITEMS_PER_PAGE = 10;

export default function HomepageSearchResults() {
    const [results, setResults] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [pageElements, setPageElements] = useState([])
    const [s, setS] = useState('')
    const url = new URL(window.location.href);

    const country = url.searchParams.get("selectedCountry");
    const categoryId = url.searchParams.get("selectedDocument");
    //Form query: ?submit=&selectedCountry=Italy&selectedDocument=&hp=hp
    //Example query to send to backend: /france?category_id=1

    function getData(){
        let queryString = '?category_id=';
        if (!country && categoryId){
            fetchData(`${queryString}${categoryId}`);
            // setS(`${queryString}${categoryId}`);
        } else if(country && !categoryId){
            //setS(`/${country}${queryString}`);
            fetchData(`/${country}${queryString}`);
        } else if (country && categoryId){
            //setS(`/${categoryId}${queryString}${categoryId}`)
            fetchData(`/${country}${queryString}${categoryId}`);
        }
        //const queryString = '/france?category_id='; for local testing
        //fetchData(s)
    }

    const fetchData = async (s) => {
        
        const res = await getHomepageSearchResults(s)
        if (res) setResults(res.reduce((previousValue, currentValue) => {
            const sections = currentValue.sections.map(section => {
                return {...section, name: currentValue.name}
            })
            //console.log(sections);
            return previousValue.concat(sections)
        }, []))
        //console.log(results);
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        return () => {
          setCurrentPage(1)
        };
    }, []);
    
    useEffect(() => {
        if (results && results.length > 0) {
            const start = currentPage * ITEMS_PER_PAGE 
            const end = currentPage * ITEMS_PER_PAGE + ITEMS_PER_PAGE 
            setPageElements(results.slice(start, end))
        }
    }, [currentPage, results]);  

    return (
        <>
        {pageElements.length !== 0 ? (
            <>
            {pageElements.map((result, index) => 
            <div className='container' key={index}>
                {(index === 0 || (pageElements[index-1].name !== result.name)) && <p className={`${style['result__category']}`}>{result.name}</p>}
                <a className={`${style['result__link']}`} href={`${process.env.REACT_APP_PUBLIC_SITE}/documents-${result.name.toLowerCase()}/?search=${result.title}`}>
                    <div className={`${style['search-result__box']}`}>
                        <h2 className={`${style['result__title']}`} >{result.title}</h2>
                        <FontAwesomeIcon icon={faArrowRight} className={`${style["arrow_icon"]}`} />
                    </div>
                </a>
            </div>)}
            <div className='container'>
                <Pagination currentPage={currentPage} items={results} setCurrentPage={setCurrentPage} itemsPerPage={ITEMS_PER_PAGE} />
            </div>
            </>
        ) : (
            <div className={`${style['no_result']}`}>
                <h1 className={`${style['no_result-title']}`}>Nothing found</h1>
                <p className={`${style['no_result-description']}`}>Sorry, but nothing matched your search terms. Please try again with some different keywords</p>
            </div>
        )}
        </>
    )
}