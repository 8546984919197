import React, { useRef, useEffect, useState } from "react"
import { differenceInDays, startOfToday } from 'date-fns'
import style from './style.module.css'
import Avatar from '../../components/Avatar'
import hamburgerMenu from '../../assets/hamburger_menu.svg'
import notLoggedAvatar from '../../assets/login_avatar.svg'
import { useUser } from "../../context/UserContext"
import { useLogin } from "../../context/LoginContext"
import LoginPopup from "../LoginPopup"
import LoginSubscribe from "../LoginSubscribe"
import { getImage, goToUrl } from "../../utils/constants"
import { toast, ToastContainer } from 'react-toastify';

function LoggedOutput () {
    const {doLogout} = useLogin()
    return(
        <>
            <h3 className={`${style["login-popup-content"]}`} onClick={(e) => goToUrl('user-profile/?param=personalInformation', e)}>My Profile</h3>
            <h3 className={`${style["login-popup-content"]}`} onClick={(e) => goToUrl('user-profile/?param=membershipInformation', e)}>Membership Status</h3>
            <h3 className={`${style["login-popup-content"]}`} onClick={(e) => goToUrl('member-area/', e)}>Member Area</h3>
            <div className={`${style["login-popup-content-linee"]}`}></div>
            <h3 className={`${style["login-popup-content"]}`} onClick={doLogout}>Log Out</h3>
        </>
    )
}

function NotLoggedOutput ({LoginProcedure}) {
    return(
        <>
            <h3 className={`${style["login-popup-content"]}`} onClick={LoginProcedure}>Login</h3>
            <h3 className={`${style["login-popup-content"]}`} onClick={(e) => goToUrl('sign-up', e)}>Sign Up</h3>
        </>
    )
}

function NavbarUserLogin() {
    const [showLoginTab, setShowLoginTab] = useState(false)
    const {isLogged, showTrialModal, hideTrialModal, showMembershipExpireModal, hideMembershipExpireModal , user} = useUser()
    const {showLoginModal, hideLogin, doLogin, showLogin} = useLogin()
    let ref = useRef(null);


    const openLoginTab = () =>{
        setShowLoginTab(!showLoginTab)
    }

    const closeAllTabs = (event) =>{
        if (ref.current && !ref.current.contains(event.target)){
            setShowLoginTab(false)
        }
    }

    const startLogin = () => {
        showLogin()
        setShowLoginTab(false)
    }

    useEffect(() =>{
        window.addEventListener('showLogin', () => showLogin())
        document.addEventListener('click', closeAllTabs, true);
        return () => {
            document.removeEventListener('click', closeAllTabs, true)
        };
    }, [])



    return (
        <>
            <div className={`${style["navbar_login-wrapper"]}`} ref={ref}>
                <div className={`${style["navbar_login-container"]}`} onClick={openLoginTab}>
                    <img className={`${style["navbar_login-hambuger"]}`} src={hamburgerMenu} alt="hamburger_Image"></img>
                    <Avatar imgSrc={isLogged ? user.image ? getImage(user.image) : notLoggedAvatar : notLoggedAvatar} imgSize="very-small"/>
                </div>
                {showLoginTab &&
                    <div className={`${style["navbar_login-popup"]}`}>
                        {isLogged ? <LoggedOutput /> : <NotLoggedOutput LoginProcedure={startLogin} />} 
                    </div>
                }
            </div>
            {showLoginModal && <LoginPopup
                onClose={hideLogin}
                onSubmit={doLogin}
                cardLabel="Log in" 
                cardTitle="Welcome to IDI" 
                cardDescription="Please enter your access codes in the boxes below.">
                <LoginSubscribe onSubmit={doLogin} />
            </LoginPopup>}
        
            {showTrialModal && <LoginPopup
                onClose={hideTrialModal}
                onSubmit={() => {}}
                cardLabel="Free Trial" 
                cardTitle="" 
                cardDescription="">
                    <div>
                        <h1 className={`${style["counter__title"]}`}>Your subscription expires in</h1>
                        <div className={`${style["counter__container"]}`}>
                            <span className={`${style["counter__days-left"]}`} >{differenceInDays(new Date(user.subscription_trial_end), startOfToday())}</span><br></br>
                            <span className={`${style["counter__days-left-text"]}`}>days</span>
                        </div>
                        <button className="empty__button-black white-button__margin" onClick={hideTrialModal}>Close</button>
                        <button className="red__button" onClick={(e) => goToUrl('user-profile/?param=personalInformation', e)}>Go To My Profile</button>
                    </div>
                </LoginPopup>}

            {showMembershipExpireModal && <LoginPopup
                onClose={hideMembershipExpireModal}
                onSubmit={() => {}}
                cardLabel="Membership is going to Expire soon" 
                cardTitle="" 
                cardDescription="">
                    <div>
                        <h1 className={`${style["counter__title"]}`}>Your subscription expires in</h1>
                        <div className={`${style["counter__container"]}`}>
                            <span className={`${style["counter__days-left"]}`} >{differenceInDays(new Date(user.subscription_end), startOfToday())}</span><br></br>
                            <span className={`${style["counter__days-left-text"]}`}>days</span>
                        </div>
                        <button className="empty__button-black white-button__margin" onClick={hideTrialModal}>Close</button>
                        <button className="red__button" onClick={(e) => goToUrl('user-profile/?param=personalInformation', e)}>Go To My Profile</button>
                    </div>
                </LoginPopup>}
                <ToastContainer
                    position="top-right"
                    autoClose={1500}
                />
        </>
    )
}

export default NavbarUserLogin