import React, { useState } from "react";
import MembershipTitle from '../MembershipTitle';
import PageDescription from '../PageDescription';
import style from './style.module.css';
import RedButton from '../RedButton';
import ConferenceCompletedBlock from '../../pages/ConferenceCompletedBlock';
import { goToUrl } from "../../utils/constants";

function ThankYou({conference, title, content}) {
    return (
        <>
            <header>
                <div className= "main__container membership__section ty-description">
                    <MembershipTitle title={title} AlignText='center' />
                    <PageDescription textContent={content} AlignText='center'/>
                </div>
            </header>
            <div className="main__container bottom">
                {conference ? <ConferenceCompletedBlock/> : null}
                <div className={`${style['flex-centered']}`}>
                    <RedButton textButton='Go to the Homepage' onClick={(e) => goToUrl("", e)}/>
                </div>
            </div>
           
        </>
    )
}

export default ThankYou
