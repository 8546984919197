import { useEffect, useState } from 'react';
import { format, set } from 'date-fns'
import deepFilter from '../../utils/deepFilter'
import CountrySelect from '../../components/CountrySelect';
import MembershipTitle from '../../components/MembershipTitle';
import PageDescription from '../../components/PageDescription';
import AccessMembersOnly from '../../components/AccessMembersOnly';
import LegislationFooter from '../../components/LegislationFooter';
import CommonBanner from '../../components/CommonBanner';
import LastUpdate from '../../components/LastUpdate';
import RegularSection from '../../components/RegularSection';
import AccordionSection from '../../components/AccordionSection';
import { getDocuments, getReports, searchArbitralCases, searchEUCaseLaw } from '../../utils/api';
import { useUser } from '../../context/UserContext';
import { useAccessDenied } from '../../context/AccessDeniedContext';
import ReportsPage from '../../components/ReportsPage';
import SearchBar from '../../components/SearchBar';
import DocumentsSearch from '../../components/DocumentsSearch/DocumentsSearch';
import LoginPopup from '../../components/LoginPopup';
import LoginSubscribe from '../../components/LoginSubscribe';
import { useLogin } from '../../context/LoginContext';
import RedButton from '../../components/RedButton';
import { goToUrl } from '../../utils/constants';
import style from './style.module.css'

const goToBanners = {
    'Arbitration': {
        label: 'Go to Arbitral Cases',
        link: 'documents-arbitral-cases'
    },
    'Antitrust': {
        label: 'Go to EU Case Law',
        link: 'documents-eu-case-law'
    },
    'EU Case Law': {
        label: 'Go to EU Section',
        link: 'documents-eu-section'
    },
    'EU Section': {
        label: 'Go to EU Case Law',
        link: 'documents-eu-case-law'
    }
}

const searchBarPlaceHolder = {
    'Reports': 'Choose Country, Category',
    'EU Case Law': 'Case No., Judge, Date, Parties, Contracts',
    'Arbitral Cases': 'Case No., Parties, Date, Contracts'
}

const documentDescription = {
   'Legislation' : 'Country cards of around 140 countries, containing a brief description of the laws on commercial agency, distribution, franchising and other related matters, such as applicable law, jurisdiction and arbitration are available in this section. Over 500 national laws, in English or in other commonly used languages (e.g.: French, Spanish, German) are also available.',
   'International Conventions' : 'The texts of the main international conventions and model laws (as well as the updated status of ratification) regarding commercial agency, distribution, franchising, applicable law, jurisdiction, arbitration and recognition of foreign judgments are available in this section.',
   'Reports' : 'Detailed country reports on commercial agency, distribution and franchising law, drafted by highly qualified country experts, are available in this section. Each report is drafted by filling-in a standard form with specific questions that cover all the main issues concerning specific subject matters.',
   'Contracts' : 'Specific model contracts on commercial agency and distribution, drafted by IDI, are available in this section. Long forms contain detailed provisions, comments and explanations. These are especially intended to be used as a helpful tool for the drafting of tailor-made contracts. Short forms contain only the main clauses. These are directed to those who are looking for an easy-to- use tool. Each type of contract is drafted in three different versions: one on the side of the agent/distributor; one on the side of the principal/supplier, while the third tries to strike a reasonable balance between the two. The balanced short forms are also translated by the IDI country experts in more than 24 commonly used languages such us Japanese, Chinese, Arabic, Greek, etc.. with the English text in front.',
   'EU Section' : 'This section contains the main EU Rules of hard law and soft law applicable to commercial agency, distribution, franchising and related matters. Moreover about 200 texts of decisions rendered by European Courts in the field of distribution, are also available.',
   'EU Case Law' : 'About 200 texts of decisions rendered by European courts in the field of distribution, are available in this section. A detailed keyword search tool will help you go through the uploaded documents.',
   'Arbitral Cases' : 'About 100 abstracts of international arbitral awards issued in the field of distribution, by national and international arbitral institutions are available in this section. A detailed keyword search tool will help you go through the uploaded documents.',
   'Antitrust': 'This section contains the IDI Special Report on EU Antitrust Law as well as the main EU Rules applicable on competition.',
   'Applicable Law & Jurisdiction': 'This section contains the IDI Special Report on Applicable Law and Jurisdiction as well as the main International Conventions and EU Rules on the same subject.',
   'Arbitration': 'This section contains the main International Conventions on arbitration as well as a data base of about 100 abstracts of international arbitral awards issued in the field of distribution, by national and international arbitral institutions are available in this section.',

}

const bannerText = {
    'Legislation' : 'Users without a membership can only view the legislation data for Italy.',
    'International Conventions' : 'Users without a membership can only view the conventions list.',
    'Contracts' : 'This section can only be seen by users with a valid subscription.',
    'EU Section': 'Users without a membership can only view EU Rules list and the Ingmar Judgement N° C-381/98',
    'EU Case Law' : 'Users without a membership can only view the Ingmar judgment No. C-381/98.',
    'Arbitral Cases' : 'Users without a membership can only view Case No. 2626/1977.',
    'Antitrust': 'Documents on this section can be downloaded only by users with a valid subscription.',
    'Applicable Law & Jurisdiction': 'Documents on this section can be downloaded only by users with a valid subscription.',
    'Arbitration': 'Documents on this section can be downloaded only by users with a valid subscription.',
    'Reports' : 'Documents on this section can be downloaded only by users with a valid subscription.',

}

const shouldShowCountrySelection = (category) => ['Legislation'].includes(category) 
const shouldShowLastUpdate = (category) => ['International Conventions'].includes(category)
const shouldShowTwoColumns  = (category) => ['International Conventions', 'Contracts', 'Applicable Law & Jurisdiction', 'Arbitration'].includes(category)
const shouldShowGoToBanner = (category) => ['EU Section', 'EU Case Law', 'Antitrust', 'Arbitration'].includes(category)
const shouldShowIDArbBanner = (category) => ['Arbitration'].includes(category)
const shouldShowFooterLinks = (category) => ['Legislation'].includes(category)
const shouldShowReportsRow = (category) => ['Reports'].includes(category)
const shouldShowSearchBar = (category) => ['Reports', 'EU Case Law', 'Arbitral Cases'].includes(category)

function Documents({category}) {
    const {isLogged, isMember} = useUser()
    const {showAccessDeniedModal, hideAccessDenied} = useAccessDenied()
    const {showLoginModal, hideLogin, doLogin} = useLogin()
    const [currentDocument, setCurrentDocument] = useState({})
    const [originalDocuments, setOriginalDocuments] = useState({})
    const [country, setCountry] = useState("ITALY")
    const [search, setSearch] = useState("")
    const [reportsCategory, setReportsCategory] = useState("")
    const [reportsCountry, setReportsCountry] = useState("")
    const [apiReports, setApiReports] = useState([]);


    const doSearch = (searchValue) => {
        if (!originalDocuments.sections) return
        const searchParam = searchValue
        localStorage.setItem('searchedCountry', searchParam);
        if ((!searchValue || searchValue === '' || searchValue === "undefined")) return setCurrentDocument((_) => {return {...originalDocuments}})
        const sections = deepFilter(originalDocuments.sections, searchParam);
        setCurrentDocument({...currentDocument, sections: sections})
    }

    const searchReports = async (country, category) => {
        setReportsCountry(country)
        setReportsCategory(category);
        const res = await getReports(country)
        if (res) {
            //setCurrentDocument(res);
            setApiReports(res);
        }
    }

    useEffect(() => {
        if (category === 'Reports' && reportsCountry !== "") {
            if (reportsCategory !== "") {
                const document = apiReports?.sections.find((section) => section.title === reportsCountry)
                const documentRow = document.rows[0].documents.find((doc) => doc.title === reportsCategory)
                if (documentRow === undefined) {
                    setCurrentDocument({...currentDocument, sections: []})
                } else {
                    setCurrentDocument({...currentDocument, sections: [...apiReports?.sections]})
                }
            } else {
                setCurrentDocument({...currentDocument, sections: [...apiReports?.sections]})
            }
        }
    }, [apiReports])

    const searchCases = async (data) => {
        const res = await searchEUCaseLaw(data)
        if (res) {
            setCurrentDocument(res);
        }
    }

    const searchArbitral = async (data) => {
        const res = await searchArbitralCases(data)
        if (res) {
            setCurrentDocument(res);
        }
    }

    const resetSearch = () => {
        setSearch("")
        doSearch("")
        setReportsCategory("")
    }

    useEffect(() => {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString);
        const s = urlParams.get('search')

        const countryPreviousSelected = localStorage.getItem('searchedCountry')
      
        if (s) {
            
            if (category === 'Legislation') setCountry(s)
            else {
                setSearch(s)
            }

            doSearch(s)

        } else if(countryPreviousSelected && countryPreviousSelected !== "undefined") {
            if (category === 'Legislation') {
                setCountry(countryPreviousSelected)
                doSearch(countryPreviousSelected)
            } else if (category === "Reports") {
                doSearch(countryPreviousSelected)
            } else {
                doSearch("")
            }
            
        }
    }, [originalDocuments])

    useEffect(async () => {
        const res = await getDocuments(category)
        setOriginalDocuments(res)
        const countryPreviousSelected = localStorage.getItem('searchedCountry')
        if (category === 'Legislation') {
            // Get only section where title === Italy
            if (countryPreviousSelected){
                const filteredSection = res.sections.filter(section => section.title === countryPreviousSelected) 
                setCurrentDocument({...res, sections: filteredSection})
            }else{
                const filteredSection = res.sections.filter(section => section.title === "ITALY") 
                setCurrentDocument({...res, sections: filteredSection})
            }
            
        } else setCurrentDocument(res)

    }, [category]);


    useEffect(() => {
        doSearch(search)
    }, [search])

    return (
        <>
            <header>
                <div className= "main__container membership__section">
                    <MembershipTitle title={currentDocument.name} AlignText='center' />
                    <PageDescription textContent={documentDescription[category]} AlignText='center'/>
                    {!isLogged && <AccessMembersOnly bannerText={bannerText[category]}/>}
                </div>
            </header>
            <main>
                <div className= "main__container membership__section">
                    {/* {isLogged && shouldShowSearchBar(category) && <SearchBar placeholderText={searchBarPlaceHolder[category]} onChange={(event) => setSearch(event.target.value)} />} */}
                    {(isLogged && shouldShowSearchBar(category) || category === 'EU Case Law') && <DocumentsSearch isLogged={isLogged} searchReports={searchReports} searchCases={searchCases} category={category} searchArbitralCases={searchArbitral} getDefaults={resetSearch} />}
                    {isLogged && shouldShowSearchBar(category) && search && <div className={`${style["search-parameter"]}`} style={{textTransform: "capitalize"}} onClick={resetSearch}><span>{search} X</span></div>}
                    {!shouldShowReportsRow(category) ? 
                    <div className="docs__container">
                        {shouldShowLastUpdate(category) && currentDocument.last_update && <LastUpdate updateDate={format(new Date(currentDocument.last_update), 'dd/MM/yyyy')}/>}
                        {isLogged && shouldShowCountrySelection(category) && <CountrySelect onChange={(item) => {doSearch(item); setCountry(item)}} countries={originalDocuments.sections && originalDocuments.sections.map(section => section.title)} initialCountry={country} />}
                        {currentDocument.sections && currentDocument.sections.length > 0 && currentDocument.sections.length <= 3 ? <RegularSection forceAllSections={['Contracts'].includes(category)} sections={currentDocument.sections} twoColumns={shouldShowTwoColumns(category)} /> : <AccordionSection category={category} sections={currentDocument.sections} twoColumns={shouldShowTwoColumns(category)} />}
                        {currentDocument.sections && currentDocument.sections.length === 0 && <><p className={`${style["no-results"]}`}>No results found matching the selected filters {/* category === 'Legislation' ? country : search */}</p><RedButton onClick={resetSearch} textButton={'Go back'}/></>}
                    </div> : <div className="docs__container">
                        {shouldShowReportsRow(category) && currentDocument.sections && currentDocument.sections.length > 0 ? <ReportsPage country={reportsCountry} category={reportsCategory} sections={currentDocument.sections && currentDocument.sections} /> : <><p>No results found matching the selected filters {/*for: "{category === 'Legislation' ? country : search*/}"</p><br></br><RedButton textButton={'Go back'} onClick={resetSearch}/></>}
                    </div>}
                </div> 
            </main>
            <footer>
                <div className= "main__container">
                    {shouldShowFooterLinks(category) && <LegislationFooter country={country} />}
                    {shouldShowGoToBanner (category) && <CommonBanner banner={goToBanners[category]}/>}
                    {shouldShowIDArbBanner(category) && <CommonBanner banner={{label:"Discover IDArb", link: 'idarb-idi-arbitration-project'}}/>}  
                </div>
                {showAccessDeniedModal && (!isLogged || !isMember) && <LoginPopup
                    onClose={hideAccessDenied}
                    cardLabel="Log in" 
                    cardTitle="Access Denied" 
                    cardDescription="The document you requested is only available to IDI members.  Reniew your Membership to download the documents.">
                    {!isLogged ? <LoginSubscribe onSubmit={doLogin} /> : !isMember && <div>
                        <RedButton onClick={(e) => goToUrl('renew-membership') } textButton={"Renew Membership"} />    
                    </div>}
                </LoginPopup>}

                {showLoginModal && <LoginPopup
                    onClose={hideLogin}
                    onSubmit={doLogin}
                    cardLabel="Log in" 
                    cardTitle="Welcome to IDI" 
                    cardDescription="Please enter your access codes in the boxes below.">
                    <LoginSubscribe onSubmit={doLogin} />
                </LoginPopup>}
            </footer>
        </>
    )
}

export default Documents
