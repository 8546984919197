import WhiteButton from '../WhiteButton';
import RedButton from '../RedButton';
import style from './style.module.css'
import { goToUrl } from "../../utils/constants"
import { useLogin } from '../../context/LoginContext';
import LoginPopup from '../../components/LoginPopup';
import LoginSubscribe from '../../components/LoginSubscribe';



function NotloggedIn() {

    const backToHpButtonText = 'Back to Homepage'
    const logInHpButtonText = 'Log In'
    const {showLogin} = useLogin()
    const {showLoginModal, hideLogin, doLogin} = useLogin()


    return (
        <div className={`${style["not-logged-in__container"]}`}>
            <h3>You are not logged in</h3>
            <div className={`${style["not-logged__flex"]}`}>
                <WhiteButton textButton={backToHpButtonText} margin='margin-right' onClick={(e) => goToUrl('/', e)}/>
                <RedButton textButton={logInHpButtonText} onClick={showLogin}/>
            </div>
            {showLoginModal && <LoginPopup
                    onClose={hideLogin}
                    onSubmit={doLogin}
                    cardLabel="Log in" 
                    cardTitle="Welcome to IDI" 
                    cardDescription="Please enter your access codes in the boxes below.">
                    <LoginSubscribe onSubmit={doLogin} />
                </LoginPopup>}
        </div>
    )
}

export default NotloggedIn