import { Fragment, useEffect, useState } from "react";
import MembershipTitle from "../../components/MembershipTitle";
import PageDescription from "../../components/PageDescription";
import AccessMembersOnly from "../../components/AccessMembersOnly";
import { getFile } from "../../utils/constants";
import { getJudicialDocuments, getJudicialTexts } from "../../utils/api";
import { useUser } from "../../context/UserContext";
import { useAccessDenied } from "../../context/AccessDeniedContext";
import LoginPopup from "../../components/LoginPopup";
import LoginSubscribe from "../../components/LoginSubscribe";
import { useLogin } from "../../context/LoginContext";
import style from "./style.module.css";
import RedButton from "../../components/RedButton";
import { goToUrl } from "../../utils/constants";
import parse from "html-react-parser";
import downloadSvg from '../../assets/download-svg.svg'


function DocumentArea() {
  const { isLogged, isMember } = useUser();
  const { showAccessDenied } = useAccessDenied();
  const { showAccessDeniedModal, hideAccessDenied } = useAccessDenied();
  const { showLoginModal, hideLogin, doLogin } = useLogin();
  const [documents, setDocuments] = useState();
  const [texts, setTexts] = useState();
  const pageDescription =
    "Distribution and franchise agreements are almost always concluded between parties having a different negotiating power. Thus, the relationship between supplier and distributor, and especially that between franchisor and franchisee, is generally characterised by an imbalance between the parties. Lawmakers and courts tend to deal with this issue by protecting in various ways the “weaker” parties against possible abuses of their counterparts. This “rebalancing” of the respective positions can be made by imposing protective rules regarding specific situations (such as rules providing minimum terms for termination), or through the recourse to more general rules (like good faith or abuse of economic dependence) applied by the courts (or other authorities) to specific situations of imbalance. The material impact of this protective trend varies substantially from country to country, due to the difference of legal rules and principles in force. Thus, common law courts tend to privilege the strict observance of the provisions agreed between the parties, while civil law courts are more inclined to “correct” or annul unbalanced clauses. <br> The list of cases below refers to judgments and decisions of Courts and Public Authorities in different countries (e.g., Canada, France, Italy, UK, USA). <br> Each list includes links to the relevant texts of the judgments/decisions.";

  useEffect(async () => {
    const res = await getJudicialDocuments();
    setDocuments(res);

    const restText = await getJudicialTexts();
    setTexts(restText);
  }, []);

  function getContent(content) {
    return content.split("\n").join("<br/>");
  }

  return (
    <>
      <header>
        <div
          className={`${style["main__container"]} ${
            style["membership__section"]
          }`}
        >
          <MembershipTitle
            title="Restriction on the freedom of contract"
            AlignText="center"
          />
          <PageDescription textContent={pageDescription} AlignText="center" />
          {!isLogged && (
            <AccessMembersOnly bannerText="Login to download documents on this section" />
          )}
        </div>
      </header>
      <main>
        <div
          className={`${style["main__container"]} ${
            style["membership__section"]
          }`}
        >
          <div className="docs__container">
            {/*  here document section */}
            <div className={`${style["document__area"]}`}>
              <h3 className={`${style["document__area-title"]}`}>
                Collection of judicial cases on the following topics
              </h3>
              {/* documents */}
              <div
                className={`${style["document__row"]} ${
                  style["document__row-border"]
                }`}
              >
                <div className={`${style["title__column"]}`}>
                  Documents by topics
                </div>
                <div className={`${style["documents__column"]}`}>
                  {documents &&
                    documents.map((document, index) => (
                      <div
                        key={index}
                        className={`${style["reports_container-box"]}`}
                        style={{ width: "100%" }}
                      >
                        <a
                          className={`${style["link__container"]}`}
                          href={
                            isLogged && isMember
                              ? getFile(`${document.file}?pdf=true`)
                              : "#"
                          }
                          onClick={(event) => {
                            if (isLogged && isMember) {
                              return false;
                            } else {
                              event.preventDefault();
                              showAccessDenied();
                            }
                          }}
                          download
                        >
                          <img
                            className={`${style["link__svg"]}`}
                            src={downloadSvg}
                            alt="svg-download"
                          />
                            {document.title}
                        </a>
                      </div>
                    ))}
                </div>
              </div>
              {/* autors */}
              <div
                className={`${style["document__row"]} ${style["row__margin"]}`}
              >
                <div className={`${style["title__column"]}`}>Authors</div>
                <div className={`${style["documents__column"]}`}>
                  {texts &&
                    texts.map((text, index) => {
                      return (
                        <Fragment key={index}>
                          <h4 className={`${style["author__title"]}`}>
                            {text.title}
                          </h4>

                          <p className={`${style["author__text"]}`}>
                            {parse(getContent(text.content))}
                          </p>
                        </Fragment>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer>
        {showAccessDeniedModal && (!isLogged || !isMember) && (
          <LoginPopup
            onClose={hideAccessDenied}
            cardLabel="Log in"
            cardTitle="Access Denied"
            cardDescription="The document you requested is only available to IDI members.  Reniew your Membership to download the documents."
          >
            {!isLogged ? (
              <LoginSubscribe onSubmit={doLogin} />
            ) : (
              !isMember && (
                <div>
                  <RedButton
                    onClick={(e) => goToUrl("renew-membership")}
                    textButton={"Renew Membership"}
                  />
                </div>
              )
            )}
          </LoginPopup>
        )}
        {showLoginModal && (
          <LoginPopup
            onClose={hideLogin}
            onSubmit={doLogin}
            cardLabel="Log in"
            cardTitle="Welcome to IDI"
            cardDescription="Please enter your access codes in the boxes below."
          >
            <LoginSubscribe onSubmit={doLogin} />
          </LoginPopup>
        )}
      </footer>
    </>
  );
}

export default DocumentArea;
