import style from './style.module.css'

function InputSelect({name, labelText, values, selectedValue, onChange, errors, placeholderText}) {
    return (
       <div className={`${style["input_select-container"]}`}>
           <label className={`${style["input_select-label"]}`} >{labelText}</label>
           <select name={name} className={`${style["input_select"]}`} onChange={onChange}>
                <option className={`${style["select_option"]}`} value="" disabled selected>{placeholderText}</option>
                {values && values.map(value => <option key={value.value} className={`${style["select_option"]}`} value={value.value} selected={selectedValue === value.value ? true : false}>{value.label}</option>)}
            </select>
            {errors && <span className={`${style["input_error"]}`}>{errors}</span>}
       </div>
    )
}

export default InputSelect
