import React from 'react'
import { useState } from 'react';
import {useDropzone} from 'react-dropzone';
import { handleFileUpload } from '../../utils/fileupload';
import attachment from "../../assets/attachment.svg";

export default function FileUpload({setFieldValue, name, errors}) {
    const [files, setFiles] = useState([]);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      //console.log(acceptedFiles)
      handleFileUpload(acceptedFiles[0], (res) => {
        setFieldValue(name, res)
      })
        setFiles(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })))
    }
  })

  function setReplaceFileButton(){
    if(files.length > 0){
      return(
        <button className='upload_button'>Replace file</button>
      )
    } else {
      return(
        <button className='upload_button'>Choose file</button>
      )
    }
  }

  return (
    <>
      <div {...getRootProps({className: 'dropzone'})}>
      <div className="icon">
                <img
                  className="upload__icon"
                  src={attachment}
                  alt="attachment"
                />
              </div>
          <input {...getInputProps()} />
          <p>{files.length > 0 ? files[0].path : `Drag 'n' drop some files here, or click to select files`}</p>
          {setReplaceFileButton()}
      </div>
      <p className='upload_error'>{errors}</p>
    </>
  )
}
