import style from './style.module.css';

export default function LastUpdate({updateDate}){


    return(
        <div className={`${style['lastUpdate__box']}`}>
           <span className={`${style['strong']}`}>Last Update: </span>{updateDate}
        </div>
    )
}