import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import AddUser from "../../components/AddUser";
import FlowManager from "../../components/FlowManager";
import ShoppingCartSection from "../../components/ShoppingCartSection";
import PaymentScreen from "../../components/PaymentScreen";
import RedButton from "../../components/RedButton";
import { getMembership } from "../../utils/api";
import { useCart } from "../../context/CartContext";
import { useUser } from "../../context/UserContext";
import NotloggedIn from "../../components/NotLoggedIn";
import { goToUrl } from "../../utils/constants";

function RenewMembership() {
  const { cart, saveCart } = useCart();
  const { user, isLogged } = useUser();
  const [showError, setShowError] = useState(false);
  const [cannotRenew, setCannotRenew] = useState(false);
  const [daysLeft, setDaysLeft] = useState(null);

  const steps = [
    {
      id: "additionalUsers",
      title: "Add Person",
      component: AddUser,
      redButtonLabel: "Continue",
      whiteButtonLabel: "Back to",
    },
    {
      id: "cart",
      title: "Shopping Cart",
      component: ShoppingCartSection,
      redButtonLabel: "Checkout",
      whiteButtonLabel: "Back to",
    },
    {
      id: "billing_information",
      title: "Payment Details",
      component: PaymentScreen,
      redButtonLabel: "Pay Now",
      whiteButtonLabel: "Back to",
    },
  ];

  //converting subscription end date to dd-mm-yyyy format
  function convertDateFormat(dateString) {
    // Crea una nuova istanza di Date utilizzando la stringa della data
    var date = new Date(dateString);
    
    // Estrai il giorno, il mese e l'anno dalla data
    var day = date.getDate();
    var month = date.getMonth() + 1; // Mese inizia da 0, quindi aggiungi 1
    var year = date.getFullYear();
    
    // Formatta la data nel formato "dd-mm-yyyy"
    var formattedDate = (day < 10 ? '0' : '') + day + '-' +
                        (month < 10 ? '0' : '') + month + '-' + year;
    
    return formattedDate;
  }

  useEffect(async () => {
    if (isLogged) {
      const res = await getMembership();
      if (res)
        saveCart({
          renew: true,
          stripe_customer_id: res.user.stripe_customer_id,
          mainUser: { ...res.user },
          additionalUsers: [],
          companyUsers: res.add_users.map((add_user) => {
            return { ...add_user, additionalUser: true };
          }),
          billing_information: res.billing_information,
          company: res.company,
        });
      else setShowError(true);
    }
  }, [isLogged]);

  useEffect(() => {
    const today = new Date();
    const subscriptionEndDate = new Date(user.subscription_date_end);
    const diff = subscriptionEndDate.getTime() - today.getTime();
    console.log('diff',diff)
    const oneDay = 1000 * 60 * 60 * 24;
    const daysLeftUntilNextMembership = Math.round(diff / oneDay);
    // renew only if membership expires in 7 days
    if (diff > 691200000) {
      setCannotRenew(true);
      setDaysLeft(daysLeftUntilNextMembership);
    }
  }, [isLogged]);

  //clearly not working - any additional member can renew the membership

  return (
    <>
      <div className={`${style["signup__container"]}`}>
        {cannotRenew ? (
          <div className={`${style["cannotRenew__container"]}`}>
            <p>
              It is not yet possible to renew your subscription. You will be able to renew your membership starting from one week before the expiration date {convertDateFormat(user.subscription_date_end)} 
            </p>
            <RedButton textButton={'Back to Homepage'} onClick={(e)=> goToUrl('', e)}></RedButton>
          </div>
        ) : isLogged ? (
          <FlowManager stepArray={steps} defaultStep={0} />
        ) : (
          <NotloggedIn />
        )}
        {showError ? (
          <div>
            You aren't the company founder. Only the company founder can renew
            the membership
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default RenewMembership;
