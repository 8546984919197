import React, { useEffect, useState } from 'react'
import { getSearchResults, getWPSearchResults } from '../../utils/api'
import style from './style.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../../components/Pagination';

const ITEMS_PER_PAGE = 10;

export default function SearchResults() {

    const [results, setResults] = useState([])
    const [wpResults, setWpResults] = useState([])

    const [currentPage, setCurrentPage] = useState(0)
    const [wpCurrentPage, setWpCurrentPage] = useState(0)
    const [pageElements, setPageElements] = useState([])
    const [wpPageElements, setWpPageElements] = useState([])

    const fetchData = async (s) => {
        const res = await getSearchResults(s)
        if (res) setResults(res.reduce((previousValue, currentValue) => {
            const sections = currentValue.sections.map(section => {
                return {...section, name: currentValue.name}
            })
            return previousValue.concat(sections)
        }, []))
    }

    const wordPressResults = async (s) => {
        const res = await getWPSearchResults(s)
        if (res) setWpResults(res)
    }

    useEffect(() => {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString);
        const s = urlParams.get('s')
        fetchData(s)
        wordPressResults(s)
    }, [])

    useEffect(() => {
        return () => {
          setCurrentPage(1)
          setWpCurrentPage(1)
        };
      }, []);
    
      useEffect(() => {
          if (results && results.length > 0) {

              const start = currentPage * ITEMS_PER_PAGE 
              const end = currentPage * ITEMS_PER_PAGE + ITEMS_PER_PAGE 

              setPageElements(results.slice(start, end))
          }
      }, [currentPage, results]); 

      useEffect(() => {
        if (wpResults && wpResults.length > 0) {
            const start = wpCurrentPage * ITEMS_PER_PAGE 
            const end = wpCurrentPage * ITEMS_PER_PAGE + ITEMS_PER_PAGE   
            setWpPageElements(wpResults.slice(start, end))
        }
    }, [wpCurrentPage, wpResults]); 

  return (
        <>
            {pageElements.length !== 0 || wpPageElements.length !== 0 ? 
                <div>
                    
                    <div>
                        {pageElements.map((result, index) => <div>
                            {(index === 0 || (pageElements[index-1].name !== result.name)) && <p className={`${style['result__category']}`}>{result.name}</p>}
                            <a className={`${style['result__link']}`} href={`${process.env.REACT_APP_PUBLIC_SITE}/documents-${result.name.toLowerCase()}/?search=${result.title}`}>
                                <div className={`${style['search-result__box']}`}>
                                    <h2 className={`${style['result__title']}`} >{result.title}</h2>
                                    <FontAwesomeIcon icon={faArrowRight} className={`${style["arrow_icon"]}`} />
                                </div>
                            </a>
                        </div>)}
                        <Pagination currentPage={currentPage} items={results} setCurrentPage={setCurrentPage} itemsPerPage={ITEMS_PER_PAGE} />
                    </div>

                    <div>
                        {wpPageElements.length !== 0 &&<h2 className={`${style['result__others']}`} >News:</h2>}
                        {wpPageElements.map(wp => <a className={`${style['result__link']}`} href={wp.url}>
                                <div className={`${style['search-result__box']}`}>
                                    <h2 className={`${style['result__title']}`} dangerouslySetInnerHTML={{__html: `${wp.title}`}}></h2>

                                    <FontAwesomeIcon icon={faArrowRight} className={`${style["arrow_icon"]}`} />
                                </div>
                            </a>)}
                        <Pagination currentPage={wpCurrentPage} items={wpResults} setCurrentPage={setWpCurrentPage} itemsPerPage={ITEMS_PER_PAGE} />
                    </div>
                 
                    
                </div>
            : <div className={`${style['no_result']}`}>
                <h1 className={`${style['no_result-title']}`}>Nothing found</h1>
                <p className={`${style['no_result-description']}`}>Sorry, but nothing matched your search terms. Please try again with some different keywords</p>
             </div>}
        </>
    )
}
