import React from 'react'
import style from './style.module.css'
import EUCaseLawSearch from './EUCaseLawSearch'
import ArbitralCasesSearch from './ArbitralCasesSearch'
import ReportsSearch from './ReportsSearch'

export default function DocumentsSearch({category, searchReports, searchCases, searchArbitralCases, getDefaults, isLogged}) {

  const getSearchComponent = (category) => {
    switch (category) {
      case 'EU Case Law':
        return <EUCaseLawSearch searchCases={searchCases} getDefaults={getDefaults} isLogged={isLogged} />
      case 'Arbitral Cases':
        return <ArbitralCasesSearch searchArbitralCases={searchArbitralCases} getDefaults={getDefaults} />
      case 'Reports':
        return <ReportsSearch searchReports={searchReports} getDefaults={getDefaults} />
    }
  }

  return (
    <div>{getSearchComponent(category)}</div>
  )
}

