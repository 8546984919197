import React from 'react';
import style from './style.module.css'
import searchImg from '../../assets/Searchbar.svg'
import searchIcon from '../../assets/searchIcon.svg'
import clear from '../../assets/clear.svg'


function SearchBar({placeholderText, onChange, onClick, value, readOnly}) {


    function handleKeyPress(e){
        if(e.keyCode === 13 || e.charCode === 13){
            onClick();
        }
    }
    
  return (
        <>
        <div className={`${style["searchbar__group"]}`}>
        <div className={`${style["searchbar_container"]}`}>
            <img src={searchIcon} className={`${style["search_icon"]}`} alt="search_icon"/>
            <input
                className={`${style["input_search"]}`}
                type="search"
                name="search"
                placeholder={placeholderText}
                value={value}
                onChange={onChange}
                onKeyPress={(e) => handleKeyPress(e)}
                readOnly={readOnly}
            />
            <img src={searchImg} className={`${style["input_search_img"]}`} alt="search_img" onClick={onClick}/>
        </div>
        {value !== '' &&
        <img src={clear} className={`${style["input_clear_img"]}`} alt="clear_img" onClick={() => onChange({target: {value: ''}})}/>
        }
        </div>
        </>

    )
}

export default SearchBar;
