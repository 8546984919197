import React, { useState } from 'react';
import {Formik} from 'formik'
import * as Yup from "yup";
import CheckboxField from '../CheckboxField'
import style from './style.module.css'
import RedButtonLogin from '../RedButtonLogin';
import InputField from '../InputField';
import { emailValidation } from '../../utils/validations';
import { useLogin } from '../../context/LoginContext';
import { doForgotPassword } from '../../utils/api';
import { goToUrl } from '../../utils/constants';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoginSchema = Yup.object().shape({
  email: Yup.string()
      .required("Required")
      .email("Please enter a valid email")
      .matches(/^\S+$/, "Please remove any spaces")
      .test("email-is-not-used", "Email is already in use", function() {
          return emailValidation;
      }),
  password: Yup.string()
      .required("Required")
      .min(8, "Please use at least 8 characters")
      .matches(/[0-9]+/, "Please use at least 1 number")
      .matches(/[a-z]+/, "Please use at leat one lowercase letter")
      .matches(/[A-Z]+/, "Please use at leat one uppercase letter")
      .matches(/[-+_!@#$%^&*.,?]+/, "Please use at least one special character"),
  });

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
      .required("Required")
      .email("Please enter a valid email")
      .matches(/^\S+$/, "Please remove any spaces")
      .test("email-is-not-used", "Email is already in use", function() {
          return emailValidation;
      })
  });

function LoginSubscribe({onForgotPassword, signupUrl}) {
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const {doLogin} = useLogin()

  return !showForgotPassword ? <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={LoginSchema}
          onSubmit = {async (values) => { 
            const res = await doLogin(values)

            console.log(res)
            if (res.status === "error") {
              toast.error("Email or password invalid")
            } else if (res.status === "password_reset_required") {
              toast.error(res.message, {
                autoClose: 5000
              })
            } else {
              toast.success('Welcome back' + " " + res.name + " " + res.surname )
            }
          }}
        >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <>
            <InputField 
              placeholderText="Insert your Email *" 
              name="email"
              handleChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              errors={errors.email}
              variableWidth="subscribeWidth"
            />
            <InputField 
              placeholderText="Password *" 
              fieldType={'password'}
              name="password"
              handleChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              errors={errors.password}
              variableWidth="subscribeWidth"
            />
            <div className={`${style["login__forgot-text__container"]}`}>
            <span className={`${style["login__forgot-text"]}`}><a href="#" onClick={(e) => {
              e.preventDefault()
              setShowForgotPassword(true)
              onForgotPassword && onForgotPassword()
            }} className={`${style["login__forgot-link"]}`}>Forgot your password?</a></span>
            <span>Don’t have an account? <a href="#" onClick={(e) => {
              goToUrl(signupUrl || "sign-up", e)
            }} className={`${style["login__create-link"]}`}>Create</a></span>
            </div>
            <RedButtonLogin textButton="Continue" onClick={handleSubmit}/>
           
            </>
        )}
        </Formik> : !showMessage ? <Formik
          initialValues={{ email: '' }}
          validationSchema={ForgotPasswordSchema}
          onSubmit = {async (values) => { 
            const res = await doForgotPassword(values.email)
            if (res.status === "error"){ 
              toast.error(res.message, {
              autoCose: 5000
            })
            }
            else {
              setShowMessage(true)
            }
          }}
        >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <>
            <InputField 
              placeholderText="Insert your Email *" 
              name="email"
              handleChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              errors={errors.email}
              variableWidth="subscribeWidth"
            />
            <RedButtonLogin textButton="Continue" onClick={handleSubmit}/>
            </>
        )}
        </Formik> : <p>Email sent.<br></br>If you don't receive the email within few minutes, check your spam folder.</p>
        
}

export default LoginSubscribe;
