import React, { useEffect, useState } from 'react'
import style from './style.module.css'
import SearchBar from '../SearchBar'
import whiteSearch from '../../assets/whiteSearch.svg'
import SearchDropdown from '../SearchDropdown'
import { getDemoCase, casesAutocomplete, getContracts, getJudges, partiesAutocomplete, partiesAutocompleteEUCaseLaw } from '../../utils/api'
import SearchInput from '../SearchInput'
import SearchAutocomplete from '../searchAutocomplete'
import { set } from 'date-fns'
import { toast } from 'react-toastify'
import { is } from 'date-fns/locale'

export default function EUCaseLawSearch({doSearch, searchCases, getDefaults, isLogged}) {
    const [judges, setJudges] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [casesSuggestions, setCasesSuggestions] = useState([]);
    const [partiesSuggestions, setPartiesSuggestions] = useState([])
    const [caseNumber, setCaseNumber] = useState("");
    const [judge, setJudge] = useState("");
    const [contract, setContract] = useState("");
    const [year, setYear] = useState("");
    const [parties, setParties] = useState("");
    const [filtersReset, setFiltersReset] = useState(false);
    const [shouldShowReset, setShouldShowReset] = useState(false)
    const [demoCase, setDemoCase] = useState();



    const handleChoice = (option, dropdownName) => {
        if (dropdownName === "Judge") {
            setJudge(option.name);
        } else {
            setContract(option.name);
        }
    }

    const getJudgesData = async () => {
        const res = await getJudges();
        if (res) {
            const judges = res;
            setJudges(judges);
        }
    }

    const getContractsData = async () => {
        const res = await getContracts();
        if (res) {
            const contracts = res;
            setContracts(contracts);
        }
    }

    const getDemoCaseData = async () => {
        const res = await getDemoCase();
        if (res) {
            const demo = res;
            setDemoCase(demo);
        }
    }

    const handleChange = async (e) => {
        if (e.target.name === "search") {
            setCaseNumber(e.target.value);
            if (e.target.value.length > 2) {
                const res = await casesAutocomplete(e.target.value);
                if (res) {
                    setCasesSuggestions(res);
                }
            } else if (e.target.value.length < 3) {
                setCasesSuggestions([]);
            }
        } else if (e.target.name === "year") {
            setYear(e.target.value);
        } else if (e.target.name === "parties") {
            setParties(e.target.value);
            if (e.target.value.length > 2) {
                const res = await partiesAutocompleteEUCaseLaw(e.target.value);
                if (res) {
                    setPartiesSuggestions(res);
                }
            } else if (e.target.value.length < 3) {
                setPartiesSuggestions([]);
            }
        }
    }

    const handleAutocompleteClick = (suggestion, inputName) => {
        if (inputName === "search") {
            setCaseNumber(suggestion.title)
            setCasesSuggestions([])
        } else if (inputName === 'parties') {
            setParties(suggestion.content)
            setPartiesSuggestions([])
        }
    }

    const sendSearchData = () => {
        if (!isLogged) {
            toast.error("Please log in to access this feature")
            return
        }
        if (caseNumber === "" && judge === "" && contract === "" && year === "" && parties === "") {
            toast.error("Please enter at least one search parameter")
            return
        }
        const data = {
            caseNumber,
            judge,
            contract,
            year,
            parties
        }
        searchCases(data)
        setShouldShowReset(!shouldShowReset);
    }

    const resetFilters = () => {
        if (!isLogged) {
            toast.error("Please log in to access this feature")
            return
        }
        setCaseNumber('');
        setContract('');
        setYear('');
        setParties('');
        setJudge('');
        setFiltersReset(!filtersReset);
        setShouldShowReset(!shouldShowReset);
        getDefaults();
    }

    const resetDropdownValue = (dropdownName) => {
        if (dropdownName === "Judge") {
            setJudge('');
        }
        if (dropdownName === "Contracts") {
            setContract('');
        }
    }

    useEffect(() => {
        getJudgesData()
        getContractsData()
        getDemoCaseData()
    }, [])

    useEffect(() => {
        if (!isLogged) {
            setCaseNumber(demoCase?.sections[0].title)
            setJudge(demoCase?.sections[0].rows[4].content)
            setContract(demoCase?.sections[0].rows[2].content)
            setYear(demoCase?.sections[0].rows[0].content.split("/")[2])
            setParties(demoCase?.sections[0].rows[1].content)
        }
    }, [demoCase])

    useEffect(() => {
        if (isLogged) {
            resetFilters()
            setShouldShowReset(false)
        } else {
            setCaseNumber(demoCase?.sections[0].title)
            setJudge(demoCase?.sections[0].rows[4].content)
            setContract(demoCase?.sections[0].rows[2].content)
            setYear(demoCase?.sections[0].rows[0].content.split("/")[2])
            setParties(demoCase?.sections[0].rows[1].content)
        }
    }, [isLogged])
    

  return (
    <div className={`${style["searchbar__wrapper"]}`}>
        <p>Search for Case No, Judge, Contracts, Year, Parties</p>
        <div className={`${style['input-autocomplete__wrapper']}`}>
            <SearchBar
            placeholderText="Search..."
            onClick={sendSearchData}
            onChange={handleChange}
            value={caseNumber}
            readOnly={isLogged ? false : true}
            />
            {casesSuggestions && casesSuggestions.length > 0 && <SearchAutocomplete size={"large"} attribute={"title"} inputName={"search"} handleClick={handleAutocompleteClick} suggestions={casesSuggestions} />}
        </div>
        <div className={`${style['search-wrapper']} ${style['no-border']}`}>
            <div className={`${style["filters-wrapper"]}`}>
                <SearchDropdown
                    defaultString={"Judge"}
                    options={judges}
                    stringAttribute={"name"}
                    handleChoice={handleChoice}
                    shouldReset={filtersReset}
                    resetValue={resetDropdownValue}
                    demoValue={judge}
                    readOnly={isLogged ? false : true}
                />
                <SearchDropdown 
                    defaultString={"Contracts"}
                    stringAttribute={"name"}
                    options={contracts}
                    handleChoice={handleChoice}
                    shouldReset={filtersReset}
                    resetValue={resetDropdownValue}
                    demoValue={contract}
                    readOnly={isLogged ? false : true}
                />
                <SearchInput placeholder="Year" onChange={handleChange} value={year} name={"year"} readOnly={isLogged ? false : true} />
                <div className={`${style['input-autocomplete__wrapper']}`}>
                    <SearchInput placeholder="Parties" onChange={handleChange} value={parties} name={"parties"} readOnly={isLogged ? false : true} />
                    {partiesSuggestions && partiesSuggestions.length > 0 && <SearchAutocomplete size={"small"} attribute={"content"} inputName={"parties"} handleClick={handleAutocompleteClick} suggestions={partiesSuggestions} />}
                </div>
            </div>
            <div className={`${style["search__mobile-button"]}`} onClick={sendSearchData}>
                <span>Search</span>
                <img src={whiteSearch} />
            </div>
            {shouldShowReset && <span className={`${style['reset-button']}`} onClick={resetFilters}>Reset</span>}
        </div>
      </div>
  )
}
