import passwordLogo from '../../assets/password-logo.svg';
import style from './style.module.css';
import InputField from '../../components/InputField';
import RedButton from '../../components/RedButton';
import { goToUrl } from "../../utils/constants"
import { checkPasswordToken, resetPassword } from '../../utils/api';
import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from "yup";
import { useLogin } from '../../context/LoginContext';
import LoginPopup from '../../components/LoginPopup';
import LoginSubscribe from '../../components/LoginSubscribe';



const validationSchema = Yup.object().shape({
  password: Yup.string()
      .required("Required")
      .min(8, "Please use at least 8 characters")
      .matches(/[0-9]+/, "Please use at least 1 number")
      .matches(/[a-z]+/, "Please use at leat one lowercase letter")
      .matches(/[A-Z]+/, "Please use at leat one uppercase letter")
      .matches(/[-+_!@#$%^&*.,?]+/, "Please use at least one special character"),
  password_confirm: Yup.string().required("Required").oneOf([Yup.ref('password'), null], 'Passwords must match'),  
});


export default function ForgottenPassword() {
  const [showForm, setShowForm] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [currentToken, setCurrentToken] = useState("")
  const ResetPasswordButtonText = 'Reset Password';

  const {showLogin} = useLogin()
  const {showLoginModal, hideLogin, doLogin} = useLogin()


  const fetchData = async (token) => {
    const res = await checkPasswordToken(token)
      if (res) {
        // show form
        setShowForm(true)
        setCurrentToken(token)
      }
      else {
        // Show error message
        setErrorMessage(true)
      }
  }

  const doReset = async (values) => {
    const res = await resetPassword(currentToken, values.password)
    if (res) {
      setShowConfirm(true)
      setShowForm(false)
    }
  }

  useEffect(() => {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString);
      const token = urlParams.get('token')
      if (token) fetchData(token)

  }, [])

  return (
    <div className="main__container">
        <div className={`${style['password__container']}`}>
            <img className={`${style['password__logo']}`} src={passwordLogo}/>
            <div className={`${style['forgotten-password__form']}`}>
                {showConfirm && <>
                  <h1 className={`${style['changed-password__title']}`}>Your password has been changed</h1>
                  <p className={`${style['changed-password__text']}`}>Now you can login to your account using your new password.</p>
                <div className={`${style['login__centered']}`}>
                <RedButton textButton={"Go to login"} onClick={showLogin}/>
                </div>
                </>}
                {errorMessage && <>
                  <h1 className={`${style['forgotten-password__title']}`}>The link you've followed is not valid.</h1>
                </>}
                {showForm && <>
                  <h1 className={`${style['forgotten-password__title']}`}>Create new Password</h1>
                  <p className={`${style['forgotten-password__text']}`}>Type your new password and confirm it</p>
                  <Formik
                    initialValues={{ password: '', confirmPassword: '' }}
                    validationSchema={validationSchema}
                    onSubmit = {(values) => { 
                      doReset(values)
                    }}
                  >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => <>
                    <InputField 
                      variableWidth='subscribeWidth' 
                      placeholderText="Password*" 
                      fieldType="password" 
                      name="password" 
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      errors={errors.password} /> 
                    <InputField 
                      variableWidth='subscribeWidth' 
                      placeholderText="Confirm Password*" 
                      fieldType="password"  
                      name="password_confirm" 
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password_confirm}
                      errors={errors.password_confirm} />
                    <RedButton textButton={ResetPasswordButtonText} onClick={handleSubmit} size='full-width'/>
                  </>}
                  </Formik>
                </>}
            </div>
        </div>
        {showLoginModal && <LoginPopup
            onClose={hideLogin}
            onSubmit={doLogin}
            cardLabel="Log in" 
            cardTitle="Welcome to IDI" 
            cardDescription="Please enter your access codes in the boxes below.">
            <LoginSubscribe onSubmit={doLogin} />
        </LoginPopup>}
    </div>
  )
}
