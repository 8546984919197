import { Oval } from "react-loader-spinner";
import style from "./style.module.css";

export default function RedButton({
  textButton,
  onClick,
  position,
  disabled,
  size,
  loading = false,
}) {
  return (
    <a
      className={`${style[size]} ${style["red__button"]}`}
      onClick={onClick}
      style={position}
      disabled={disabled}
    >
      {textButton} {loading && <Oval color="white" height={20} width={20} />}
    </a>
  );
}
