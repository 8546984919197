import passwordLogo from '../../assets/password-logo.svg';
import style from './style.module.css';
import RedButton from '../../components/RedButton';
import { goToUrl } from "../../utils/constants"
import { emailConfirm } from '../../utils/api';
import { useEffect, useState } from 'react';
import { useLogin } from '../../context/LoginContext';
import LoginPopup from '../../components/LoginPopup';
import LoginSubscribe from '../../components/LoginSubscribe';


export default function ConfirmEmail() {
  const [errorMessage, setErrorMessage] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)

  const {showLogin} = useLogin()
  const {showLoginModal, hideLogin, doLogin} = useLogin()


  const fetchData = async (token) => {
    const res = await emailConfirm(token)
      if (res) {
        // show confirmation
        setShowConfirm(true)
      }
      else {
        // Show error message
        setErrorMessage(true)
      }
  }

  useEffect(() => {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString);
      const token = urlParams.get('token')
      if (token) fetchData(token)
  }, [])

  return (
    <div className="main__container">
        <div className={`${style['password__container']}`}>
            <img className={`${style['password__logo']}`} src={passwordLogo}/>
            {showConfirm && <>
              <h1 className={`${style['changed-password__title']}`}>Account Activated</h1>
              <p className={`${style['changed-password__text']}`}>Your subscription has been successfully activated.</p>
            </>}
            {errorMessage && <>
              <h1 className={`${style['changed-password__title']}`}>Error Message</h1>
              <p className={`${style['changed-password__text']}`}>Something went wrong, please try again later</p>
            </>}
          <RedButton textButton={"Go to login"} onClick={showLogin}/>
        </div>
        {showLoginModal && <LoginPopup
            onClose={hideLogin}
            onSubmit={doLogin}
            cardLabel="Log in" 
            cardTitle="Welcome to IDI" 
            cardDescription="Please enter your access codes in the boxes below.">
            <LoginSubscribe onSubmit={doLogin} />
        </LoginPopup>}
    </div>
  )
}
