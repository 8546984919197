import React, { useState, useEffect } from "react";
import ConferenceDetails from "../ConferenceDetails";
import style from "./style.module.css";
import minus from "../../assets/minus.svg";
import plus from "../../assets/plus.svg";
import { useCart } from "../../context/CartContext";
import { getActiveConference } from "../../utils/api";
import { formatPrice, priceFormatted } from "../../utils/constants";

export default function SignUpConferenceSection({
  shouldValidate,
  saveAndContinue,
  resetValidation,
  values,
}) {
  const { cart } = useCart();
  const [users, setUsers] = useState([]);
  const [usersWorkshops, setUsersWorkshops] = useState({});
  const [usersOptionals, setUsersOptionals] = useState({});
  const [activeConference, setActiveConference] = useState({});
  const [showWorkshopsError, setShowWorkshopsError] = useState(false);

  useEffect(() => {
    // Check che activeConference.optionals e users non siano vuoti prima di inizializzare usersOptionals
    if (activeConference && activeConference.optionals && activeConference.optionals.length > 0 && users && users.length > 0) {
      // Crea un oggetto dove le chiavi sono gli indirizzi email degli utenti e i valori sono oggetti con optional.id: 0
      const initialUserOptionals = {};
      users.forEach(user => {
        initialUserOptionals[user.email] = activeConference.optionals.reduce((acc, optional) => {
          acc[optional.id] = 0;
          return acc;
        }, {});
      });
      // Imposta lo stato usersOptionals con l'oggetto appena creato
      setUsersOptionals(initialUserOptionals);
    }
  }, [activeConference, users]);


  const changeOptionalQty = (productId, userId, type) => {
    const userOption =
      usersOptionals[userId] && usersOptionals[userId][productId]
        ? type === "increase"
          ? usersOptionals[userId][productId] + 1
          : usersOptionals[userId][productId] - 1
        : type === "increase"
        ? 1
        : 0;
    setUsersOptionals((previousState) => {
      return {
        ...previousState,
        [userId]: { ...previousState[userId], [productId]: userOption },
      };
    });
  };


  const setWorkshop = (workshopId, userId) => {
    setUsersWorkshops((previousState) => {
      return { ...previousState, [userId]: [workshopId] };
    });
  };

  const listProducts = (user) =>
    activeConference.optionals.map((product) => (
      <div
        key={`${user.id} ${product.id}`}
        className={`${style["conference-flex"]} ${style["counter-flex"]}`}
      >
        <label
          className={`${style["radio-container"]} ${style["strong-option"]} ${
            style["radio-container-np"]
          }`}
        >
          {product.title}{" "}
          {product.price && `(${priceFormatted(product.price)})`}
        </label>
        <div className={`${style["conference-option-counter"]}`}>
          <img
            onClick={() => changeOptionalQty(product.id, user.id, "decrease")}
            className={`${style["counter-minus"]}`}
            src={minus}
            alt="Minus"
          />
          {(usersOptionals[user.id] && usersOptionals[user.id][product.id]) ||
            0}
          <img
            onClick={() => changeOptionalQty(product.id, user.id, "increase")}
            className={`${style["counter-plus"]}`}
            src={plus}
            alt="Plus"
          />
        </div>
      </div>
    ));

  const generateConferencesObject = () =>
    users.map((user) => {
      return {
        id: activeConference.id,
        country: activeConference.country,
        vat_rule: activeConference.vat_rule,
        email: user.email,
        optionals: usersOptionals[user.id]
          ? Object.keys(usersOptionals[user.id]).map((optionalId) => {
              return {
                id: optionalId,
                quantity: usersOptionals[user.id][optionalId],
              };
            })
          : [],
        workshops: usersWorkshops[user.id] || [],
      };
    });

  useEffect(() => {
    const cartProducts = [];
    cartProducts.push({ ...cart.mainUser, id: cart.mainUser.email });
    if (cart.additionalUsers)
      cart.additionalUsers.map((user) =>
        cartProducts.push({ ...user, id: user.email })
      );
    setUsers(cartProducts);
  }, [cart]);

  useEffect(() => {
    if (shouldValidate) {
      if (cart.mainUser.speaker) {
        if (
          !usersWorkshops[cart.mainUser.email] ||
          (usersWorkshops[cart.mainUser.email] &&
            usersWorkshops[cart.mainUser.email].length === 0)
        ) {
          resetValidation();
          return setShowWorkshopsError(true);
        } else {
          return saveAndContinue(generateConferencesObject());
        }
      }
      saveAndContinue(generateConferencesObject());
    }
  }, [shouldValidate]);

  useEffect(() => {
    if (values)
      values.forEach((user) => {
        const optionals = user.optionals.reduce(
          (optionals, currentOptional) => {
            return {
              ...optionals,
              [currentOptional.id]: currentOptional.quantity,
            };
          },
          {}
        );
        setUsersWorkshops((previousState) => {
          return { ...previousState, [user.email]: user.workshops };
        });
        setUsersOptionals((previousState) => {
          return { ...previousState, [user.email]: optionals };
        });
      });
  }, [values]);

  useEffect(async () => {
    const res = await getActiveConference();
    setActiveConference(res);
  }, []);

  const getPrice = (user) => {
    if (cart.streaming) {
      return priceFormatted(activeConference.price_streaming);
    } else {
      if (user.additionalUser) {
        return priceFormatted(activeConference.discount_price_guests);
      } else {
        return priceFormatted(activeConference.discount_price);
      }
    }
    //return `${formatPrice(cart.streaming ? activeConference.price_streaming : user.additionalUser ? activeConference.discount_price_guests : activeConference.discount_price)} &euro`;
  };

  return (
    <>
      {Object.keys(activeConference).length > 0 &&
        users.map((user) => (
          <div key={`${user.id}`}>
            <h4 className={`${style["user-conference"]}`}>
              {user.name} {user.surname}
            </h4>
            <div className={`${style["conference-flex"]}`}>
              <div className={`${style["flex-large"]}`}>
                <span className={`${style["grey-title"]}`}>Conference</span>
                <ConferenceDetails
                  image={activeConference.image}
                  title={activeConference.title}
                  description={activeConference.description}
                />
              </div>
              <div className={`${style["flex-small"]}`}>
                <span className={`${style["grey-title"]}`}>Price</span>
                <h5 className={`${style["conference-black-text"]}`}>
                  {getPrice(user)}
                </h5>
              </div>
            </div>
            <div className={`${style["conference-box"]}`}>
              <span
                className={`${style["grey-title"]} ${style["event-title"]}`}
              >
                Events
              </span>
              <p className={`${style["grey-text"]}`}>
                Please specify to which workshop you would like to attend (only
                one per person):
              </p>
            </div>
            <div className={`${style["conference-option-section"]}`}>
              <h5 className={`${style["black-option"]}`}>Workshops</h5>
              {activeConference.workshops.map((workshop) => (
                <label
                  key={`${user.id} ${workshop.id}`}
                  className={`${style["radio-container"]}`}
                >
                  {workshop.title}
                  <input
                    type="radio"
                    name={`${user.id} ${workshop.id}`}
                    onChange={() => setWorkshop(workshop.id, user.id)}
                    checked={
                      usersWorkshops[user.id] &&
                      usersWorkshops[user.id].indexOf(workshop.id) > -1
                    }
                  />
                  <span className={`${style["checkmark"]}`} />
                </label>
              ))}
              {showWorkshopsError && user.email === cart.mainUser.email && (
                <div className={`${style["red__error"]}`}>Required</div>
              )}
            </div>
            {!cart.streaming && (
              <div className={`${style["conference-option-section"]}`}>
                {listProducts(user)}
              </div>
            )}
          </div>
        ))}
    </>
  );
}
