import style from './style.module.css';
import Avatar from "../Avatar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import arrow from "../../assets/red-right-arrow.svg"
import { goToUrl } from '../../utils/constants';

export default function CountryExpertCard({expert}){
    return(
        <div className={`${style['country-expert__card']}`}>
            <div className={`${style['expertinfo__flex']}`} onClick={(e) => goToUrl(`country-expert-profile?id=${expert.id}`, e)}> 
                <Avatar imgSrc={expert.image && `${process.env.REACT_APP_API_HOST}/api/image/${expert.image}`} imgSize="large"/>
                <div className={`${style['expertcard__info']}`}>
                    <h4 className={`${style['expertcard__name']}`} >{expert.name} {expert.surname}</h4>
                    <p className={`${style['expertcard__data']}`}><span className={`${style['expertcard__strong']}`}>{expert.country_name}</span> | <span>{expert.job_title}</span></p>
                </div>
            </div>
            <div className={`${style['expertcard-icons__flex']}`}>
                <a href={`mailto:${expert.email}`}>
                    <div className={`${style['icon__circle']}`}>
                        <FontAwesomeIcon icon={faEnvelope} />
                    </div>
                </a>
                <a href={`tel:${expert.phone}`}>
                    <div className={`${style['icon__circle']}`}>
                        <FontAwesomeIcon icon={faPhoneAlt}  />
                    </div>
                </a>
                <a href={expert.website}>
                    <div className={`${style['icon__circle']}`}>
                        <FontAwesomeIcon icon={faGlobe} />
                    </div>
                </a>
            </div>
            <a href="#" onClick={(e) => goToUrl(`country-expert-profile?id=${expert.id}`, e)} className={`${style['red__arrow']}`}>
                <img src={arrow}></img>
            </a>
        </div>
    )
}