import style from './style.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import info from "../../assets/info.svg";


export default function InfoIcon({tooltipText}){


    return(
        <div className={`${style["tooltip"]}`}>
         <span className={`${style["tooltiptext"]}`}>{tooltipText}</span>   
        <img src={info} alt="Info"/>
        </div>
    )
}