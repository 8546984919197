import React, { useState } from 'react'
import LoginSubscribe from '../LoginSubscribe'

export default function LoginStep({signupUrl}) {
    const [forgotPassword, setForgotPassword] = useState(false)

  return (
    <div className='element__container element__margin'>
        <h1>{!forgotPassword ? 'Buy Conference ticket' : 'Forgot Password?'}</h1>
        <h2 className='loginstep__text'>{!forgotPassword ? 'Login or signup.' : 'Insert your email to recover your password.'}</h2>
        <LoginSubscribe onForgotPassword={() => setForgotPassword(true)} signupUrl={signupUrl} />
    </div>
  )
}
