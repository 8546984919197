import { useEffect, useState } from 'react'
import { useAccessDenied } from '../../context/AccessDeniedContext'
import { useUser } from '../../context/UserContext'
import { getFile } from '../../utils/constants'
import style from './style.module.css'

const EXPERT_PATH = `/country-expert-profile/?id=`

function ReportsRow({countryName, documents, category}) {
    const [filteredDocuments, setFilteredDocuments] = useState([])
    
    useEffect(() => {
        setFilteredDocuments(documents)
    }, [])

    useEffect(() => {
        if (category === '') {
            setFilteredDocuments(documents)
        } else {
            setFilteredDocuments(documents.filter(document => document.title === category))
        }
    }, [category])

    function ReportsBox ({documentLink, expert, sectionTitle}){
        const {isLogged, isMember} = useUser()
        const {showAccessDenied} = useAccessDenied()



        return(
            <div className={`${style["reports_container-box"]}`}>
                <a className={`${style["reports_document-download"]}`} href={isLogged && isMember ? getFile(`${documentLink}?pdf=true`) : '#'} onClick={(event) => {
                    if (isLogged && isMember) return false 
                    event.preventDefault();
                    showAccessDenied()
                }}></a>
                {expert && <a className={`${style["reports_expert-link"]} ${style["tooltip"]}`} href={`${EXPERT_PATH}${expert.id}`} target="_blank">
                    <div className={`${style["top"]}`}>Expert</div>
                </a>}
                
                <h4 className={`${style["reports_agency"]}`}>{sectionTitle}</h4>
            </div>
        )
    }

    return (
        <div className={`${style["reports_container"]}`}>
            <h2 className={`${style["reports_country-name"]}`}>{countryName}</h2>
            <div className={`${style["reports_link-container"]}`}>
                {filteredDocuments.map((document, index) => <ReportsBox 
                    key={`${document.id}${index}`} 
                    sectionTitle={document.title} 
                    expert={document.expert} 
                    documentLink={document.file} />)}
            </div>
        </div>
    )
}

export default ReportsRow
