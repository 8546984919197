import style from './style.module.css';

export default function WhiteButton({textButton, onClick, margin}){


    return(
        
            <a className={`${style['red__button']} ${style[margin]}`  } onClick={onClick}>{textButton}</a>
        
    )
}