import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputField from "../InputField";
import MembershipTitle from "../MembershipTitle";
import PageDescription from "../PageDescription";
import style from "./style.module.css";
import FileUpload from "../FileUpload";
import { toast } from "react-toastify";
import { submitIdarb } from "../../utils/idarb";
import { useIdarb } from "../../context/IdarbContext";
import ThankYou from "../ThankYou";
import info from '../../assets/info.svg';
import InfoIcon from "../InfoIcon";

const schema = Yup.object().shape({
  curriculum: Yup.string().required("Required"),
  first_reccomandation: Yup.string().required("Required"),
  second_reccomandation: Yup.string().required("Required"),
});

const title = "Submit a detailed profile/CV + 2 reccomandation";
const content = `While the submission form must strictly follow the format requested by IDArb, in order to warrant uniformity of the presentation, the detailed profile can be drafted freely by each prospective arbitrator.<br>
The selecting committee reserves the right to verify the profile, but will not interfere with its contents unless in very exceptional cases.`;

function IdarbCV({
  shouldValidate,
  resetValidation,
  saveAndContinue,
  hideNavigation,
  hideSteps,
  values,
}) {
  const [toastId, setToastId] = useState();
  const [showThankYou, setShowThankYou] = useState(false);
  const { idarb } = useIdarb();
  const formik = useFormik({
    initialValues: {
      curriculum:
        values && values.hasOwnProperty("curriculum") ? values.curriculum : "",
      first_reccomandation:
        values && values.hasOwnProperty("first_reccomandation")
          ? values.first_reccomandation
          : "",
      second_reccomandation:
        values && values.hasOwnProperty("second_reccomandation")
          ? values.second_reccomandation
          : "",
      group: null
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      const res = await saveAndContinue(values);
      doSubmit(res);
    },
  });

  useEffect(() => {
    if (shouldValidate)
      formik.validateForm().then((errors) => {
        if (Object.keys(errors).length > 0) resetValidation();
        else formik.handleSubmit();
      });
  }, [shouldValidate]);

  const doSubmit = async (res) => {
    const id = toast.loading("Please wait...");
    setToastId(id);

    const resSubmit = await submitIdarb(res);
    console.log(resSubmit);
    console.log(
      resSubmit && resSubmit.status && resSubmit.status === "success"
    );
    if (resSubmit && resSubmit.status && resSubmit.status === "success") {
      toast.update(id, {
        render: "All went good!",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
      setShowThankYou(true);
      hideNavigation();
      hideSteps();
    } else {
      console.log("show error");
      toast.update(id, {
        render: resSubmit.message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  return !showThankYou ? (
    <div className="membership__section">
      <div className={`${style["signup__form"]}`}>
        <MembershipTitle title={title} />
        <PageDescription textContent={content} />
        <form onSubmit={formik.handleSubmit}>
          <div>
            <p className={`${style["idarbCv__subtitle"]}`}>
              Detailed profile/CV *
              <InfoIcon tooltipText={"Pdf max 4MB"}/>
            </p>

            <FileUpload
              setFieldValue={formik.setFieldValue}
              name={`curriculum`}
              hasErrors={
                formik.errors.curriculum &&
                formik.errors.curriculum &&
                formik.errors.curriculum
              }
              value={formik.values.curriculum}
              errors={
                formik.errors.curriculum &&
                formik.errors.curriculum &&
                formik.errors.curriculum
              }
            />
          </div>
          <div>
            <p className={`${style["idarbCv__subtitle"]}`}>
              First reccomandation *
              <InfoIcon tooltipText={"Pdf max 4MB"}/>
            </p>

            <FileUpload
              setFieldValue={formik.setFieldValue}
              name={`first_reccomandation`}
              hasErrors={
                formik.errors.first_reccomandation &&
                formik.errors.first_reccomandation &&
                formik.errors.first_reccomandation
              }
              value={formik.values.first_reccomandation}
              errors={
                formik.errors.first_reccomandation &&
                formik.errors.first_reccomandation &&
                formik.errors.first_reccomandation
              }
            />
          </div>
          <div>
            <p className={`${style["idarbCv__subtitle"]}`}>
              Second reccomandation *
              <InfoIcon tooltipText={"Pdf max 4MB"}/>
            </p>

            <FileUpload
              setFieldValue={formik.setFieldValue}
              name={`second_reccomandation`}
              hasErrors={
                formik.errors.second_reccomandation &&
                formik.errors.second_reccomandation &&
                formik.errors.second_reccomandation
              }
              value={formik.values.second_reccomandation}
              errors={
                formik.errors.second_reccomandation &&
                formik.errors.second_reccomandation &&
                formik.errors.second_reccomandation
              }
            />
          </div>
        </form>
      </div>
    </div>
  ) : (
    <ThankYou
      title={"Submission completed"}
      content={`Thank you for having submitted your application. Your profile will be evaluated by the IDArb Selecting Committee. 
        You will be notified of the final decision by email`}
    />
  );
}

export default IdarbCV;
