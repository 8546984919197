import React, { useState, useContext } from "react"
export const AccessDeniedContext = React.createContext();

export const AccessDeniedProvider = ({ children }) => {
  const [showAccessDeniedModal, setAccessDeniedModal] = useState(false);

  const showAccessDenied = () => {
    setAccessDeniedModal(true)
  };

  const hideAccessDenied = () => {
    setAccessDeniedModal(false)
  };

  return (
    <AccessDeniedContext.Provider value={{ showAccessDeniedModal, showAccessDenied, hideAccessDenied }}>
      {children}
    </AccessDeniedContext.Provider>
  );
};

export const AccessDeniedConsumer = AccessDeniedContext.Consumer;

export function useAccessDenied() {
    const context = useContext(AccessDeniedContext);
    return context;
};