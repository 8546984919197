import React, { useEffect, useState } from "react";
import style from './style.module.css';
import SignUp from '../../components/SignUp'
import CompanyInformation from '../../components/CompanyInformation'
import AddUser from '../../components/AddUser';
import FlowManager from '../../components/FlowManager';
import ShoppingCartSection from '../../components/ShoppingCartSection';
import PaymentScreen from '../../components/PaymentScreen';
import { useCart } from "../../context/CartContext";
import { conferenceCart } from "../../utils/defaultValues";



function SignupAndMembership() {
    const {cart, saveCart} = useCart()
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString);
    const freeTrial = urlParams.get('freetrial')
    const normalStep = [
       {
            id: 'mainUser',
            title: 'Sign Up',
            component: SignUp,
            redButtonLabel: 'Continue',
        }, 
        {
            id: 'company',
            title: 'Company Information',
            component: CompanyInformation,
            redButtonLabel: 'Create new Account',
            whiteButtonLabel:'Back to',     
        },
        {
            id: 'additionalUsers',
            title: 'Add Person',
            component: AddUser,
            redButtonLabel: 'Continue',
            whiteButtonLabel:'Back to',
        },
        {
            id: 'cart',
            title: 'Shopping Cart',
            component: ShoppingCartSection,
            redButtonLabel: 'Checkout',
            whiteButtonLabel:'Back to',
        },
        {
            id: 'billing_information',
            title: 'Payment Details',
            component: PaymentScreen,
            redButtonLabel: 'Pay Now',
            whiteButtonLabel:'Back to',
        }]
    const freeTrialStep = [
        {
            id: 'mainUser',
            title: 'Sign Up',
            component: SignUp,
            redButtonLabel: 'Continue',
        }, 
        {
            id: 'company',
            title: 'Company Information',
            component: CompanyInformation,
            redButtonLabel: 'Create new Account',
            whiteButtonLabel:'Back to',     
        },
        {
            id: 'additionalUsers',
            title: 'Add Person',
            component: AddUser,
            redButtonLabel: 'Continue',
            whiteButtonLabel:'Back to',
        },
        {
            id: 'billing_information',
            title: 'Free Trial Confirmation',
            component: PaymentScreen,
            redButtonLabel: 'Confirm',
            whiteButtonLabel:'Back to',
        }]
    const steps = freeTrial === null ? normalStep : freeTrialStep;

    useEffect(async () => {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString);
        const freetrial = urlParams.get('freetrial')

        saveCart({
            ...cart,
            additionalUsers: [],
            companyUsers: [],
            freetrial: freetrial ? true : false
        })

        // per avere il form precompilato

        //    saveCart({
        //     ...conferenceCart
        //    });
    }, [])

    
    return (
        <>
        <div className={`${style["signup__container"]}`}>
                <FlowManager stepArray={steps} defaultStep={0} />
        </div>
        </>
    )
}

export default SignupAndMembership
