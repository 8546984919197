import style from './style.module.css'
import RedButton from '../RedButton'
import { goToUrl } from '../../utils/constants'

function CommonBanner({banner}) {
    if (!banner) return <div></div>
    return (
        <div className={`${style["banner_container"]}`}>
            <h3 className={`${style["banner_title"]}`} >{banner.label}</h3>
            <RedButton textButton={"Discover more"} onClick={(e) => goToUrl(banner.link, e)} />
        </div>
    )
}

export default CommonBanner
