import style from './style.module.css';

export default function RedButtonLogin({textButton , onClick}){


    return(
        
            <a className={`${style['red__button']}`} onClick={onClick}>{textButton}</a>
        
    )
}