import DocumentsDownloadLink from '../DocumentsDownloadLink'
import style from './style.module.css'


function DocumentRow({twoColumns, title, content, documents}) {
    return (
        <div className={`${style["legislation_container"]}`}>
            <h2 className={`${style["legislation_title"]}`}>{title}</h2>
            <div className={`${style["legislation_column"]}`} style={ twoColumns ? {display:'flex'} : {display:'block'} }>
                {content && <p>{content}</p>}
                {documents.map((document, index) => <div key={`${document.id}${index}`} className={`${style["legislation_content"]}`} style={ twoColumns ? {width:'50%'} : {width:'100%'} }>
                    <DocumentsDownloadLink linkText={document.title} files={document.file} />
                </div>)}
            </div>
        </div>
    )
}

export default DocumentRow
