export async function submitIdarb(arbitrator) {
    try {
        const token = localStorage.getItem('token')
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/arbitrators`, {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
            body: JSON.stringify(
                {
                    arbitrator: {
                        user_id: arbitrator.user_id,
                        distribution_cases: arbitrator.distribution_cases.cases,
                        ...arbitrator.submission,
                        ...arbitrator.cv_reccomandation,
                        arbitration_experience: arbitrator.submission.arbitration_experience.join(','),
                        experience_field_distribution: arbitrator.submission.experience_field_distribution.join(','),
                        experience_in_house: arbitrator.submission.experience_in_house.join(','),
                        litigation_experience: arbitrator.submission.litigation_experience.join(','),
                        professional_qualification: arbitrator.submission.professional_qualification.join(','),
                    }
                }
            ),
        })

        const result = await res.json()
        if (result.status === "success") return result
        return {status: "error", message: result.message}
    } catch (e) {
        console.log(e)
        return false
    }
}