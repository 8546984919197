import React, { useState, useEffect } from "react";
import style from './style.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useCart } from '../../context/CartContext';
import { getActiveConference, getCoupon, getPrices } from '../../utils/api';
import { useUser } from "../../context/UserContext";
import { formatPrice } from "../../utils/constants";
import { toast, ToastContainer } from "react-toastify";



export default function ShoppingCartSection({shouldValidate, saveAndContinue}){
    const {cart} = useCart()
    const {isMember, user} = useUser()
    const [users, setUsers] = useState([])
    const [discount, setDiscount] = useState(null)
    const [coupon, setCoupon] = useState("")
    const [activeConference, setActiveConference] = useState({})
    const [prices, setPrices] = useState({})

    async function discountSubmit(){
        if (coupon) {
            const res = await getCoupon(coupon)
            if (res){
                setDiscount(res);
                toast.success('Discount accepted!')
            } else{
                toast.error('Discount not valid!');
            }
        }
    }

    const calculateDiscount = () => {
        if (!discount) return
        if (discount.amount_off) return `- ${formatPrice(discount.amount_off)} €`
        if (discount.percent_off) return `- ${discount.percent_off} %`
    }

    // Prezzo per l'utente che sta facendo il signup
    const getUserPrice = () => {
        switch (user.profile_name) {
            case 'expert': return prices['PRICE_MEMBERSHIP_EXPERT'].price
            case 'arbitrator': return prices['PRICE_IDARB'].price
            default: return prices['PRICE_MEMBERSHIP_STANDARD'].price // TODO: aggiungere caso in cui company esiste
        }
    }

    const calculateCartTotal = () => {
        if (Object.keys(prices).length === 0) return
        return users.reduce((previousValue, currentValue) => {
            if (currentValue.freetrial) previousValue += 0
            if (currentValue.membership) previousValue += formatPrice((currentValue.additionalUser || cart.company.companyExists) ? prices['PRICE_MEMBERSHIP_GUESTS'].price : getUserPrice())
            if (currentValue.conferences) {
                previousValue += formatPrice(currentValue.additionalUser ? activeConference.discount_price_guests : activeConference.discount_price)

                if (currentValue.conferences.optionals) {
                    currentValue.conferences.optionals.forEach(optional => {
                        const currentOptional = getOptional(optional)
                        if (currentOptional) {
                            previousValue += formatPrice(currentOptional.price * optional.quantity)
                        }
                    })
                }
            }

            return previousValue.toFixed(2)*1
        }, 0)
    }

    const calculateVAT = () => {
        return (calculateCartTotal() * 0.22).toFixed(2)*1
    }

    const calculateTotal = () => {
        const totalAmount = calculateCartTotal()
        if (discount) {
            if (discount.amount_off) return (totalAmount - formatPrice(discount.amount_off)).toFixed(2)*1
            if (discount.percent_off) return (totalAmount - (totalAmount * (discount.percent_off/100))).toFixed(2)*1
        } else return totalAmount.toFixed(2)*1
    }

    const getOptional = (optional) => {
        return activeConference.optionals.find(activeOptional => optional.id*1 === activeOptional.id) || false
    }

    useEffect(async () => {

        if (cart.conferences) {
            const res = await getActiveConference()
            setActiveConference(res)
        }
        const cartProducts = []
        cartProducts.push({
            ...cart.mainUser, 
            freetrial: false,
            membership: cart.conferences ? false : true,
            conferences: cart.conferences ? cart.conferences.find(conference => conference.email === cart.mainUser.email) : false
        })
        if (cart.additionalUsers) cart.additionalUsers.map(user => cartProducts.push({
            ...user, 
            freetrial: false,
            membership: cart.conferences ? false : true,
            conferences: cart.conferences ? cart.conferences.find(conference => conference.email === user.email) : false
        }))
        setUsers(cartProducts)
        cart.cart && cart.cart.discount && setDiscount(cart.cart.discount)
    }, [cart])

    useEffect(() => {
        if (shouldValidate) return saveAndContinue({coupon, discount, amount: calculateTotal(), subtotal: calculateCartTotal()})
    }, [shouldValidate])

    useEffect(async () => {
        // Get Prices
        const res = await getPrices()
        if (res) setPrices(res)
    }, [])

    return(Object.keys(prices).length > 0 &&
        <>
        <div className= "membership__section">
            <div className={`${style["shopping-cart-box"]}`}>
            {users.map(user => <div key={`${user.name} ${user.surname}`}>
                <h4 className={`${style['user-conference']}`}>{`${user.name} ${user.surname}`}</h4>
                <div className={`${style['shopping-cart-flex']}`}>
                    <div className={`${style['shopping-cart-large']}`}>
                        <span className={`${style['grey-title']}`}>Order Summary</span>
                    </div>
                    <div className={`${style['shopping-cart-large']}`}>
                        <span className={`${style['grey-title']}`}>Person / Annual</span>
                    </div>
                    <div className={`${style['shopping-cart-small']}`}>
                        <span className={`${style['grey-title']}`}>Price</span>
                    </div>
                </div>
                {user.freetrial && <div className={`${style['shopping-cart-flex']}`}>
                        <div className={`${style['shopping-cart-large']}`}>
                            <span className={`${style['cart-item']}`}>IDI 7-Day Free Trial</span>
                        </div>
                        <div className={`${style['shopping-cart-large']}`}>
                            <span className={`${style['cart-item']}`}>1</span>
                        </div>
                        <div className={`${style['shopping-cart-small']}`}>
                            <span className={`${style['cart-item']}`}>0 €</span>
                        </div>
                    </div> 
                }
                {user.membership && <div className={`${style['shopping-cart-flex']}`}>
                        <div className={`${style['shopping-cart-large']}`}>
                            <span className={`${style['cart-item']}`}>IDI Membership</span>
                        </div>
                        <div className={`${style['shopping-cart-large']}`}>
                            <span className={`${style['cart-item']}`}>1</span>
                        </div>
                        <div className={`${style['shopping-cart-small']}`}>
                            <span className={`${style['cart-item']}`}>{formatPrice((user.additionalUser || cart.company.companyExists) ? prices['PRICE_MEMBERSHIP_GUESTS'].price : getUserPrice())}€</span>
                        </div>
                    </div> 
                }
                {user.conferences && activeConference && 
                    <>
                    <div className={`${style['shopping-cart-flex']}`}>
                        <div className={`${style['shopping-cart-large']}`}>
                            <span className={`${style['cart-item']}`}>Conferences & Seminars</span>
                        </div>
                        <div className={`${style['shopping-cart-large']}`}>
                            <span className={`${style['cart-item']}`}>1</span>
                        </div>
                        <div className={`${style['shopping-cart-small']}`}>
                            <span className={`${style['cart-item']}`}>{formatPrice(user.additionalUser ? activeConference.discount_price_guests : activeConference.discount_price)}€</span>
                        </div>
                    </div>
                    
                    {user.conferences.optionals && user.conferences.optionals.map(optional => 
                        <div className={`${style['shopping-cart-flex']}`}>
                            <div className={`${style['shopping-cart-large']}`}>
                                <span className={`${style['cart-item']}`}>{getOptional(optional) && getOptional(optional).title}</span>
                            </div>
                            <div className={`${style['shopping-cart-large']}`}>
                                <span className={`${style['cart-item']}`}>{optional.quantity}</span>
                            </div>
                            <div className={`${style['shopping-cart-small']}`}>
                                <span className={`${style['cart-item']}`}>{formatPrice(getOptional(optional) && getOptional(optional).price * optional.quantity)} €</span>
                            </div>
                        </div>)}
                    </>
                }
            </div>)}
            <div className={`${style['discount-code-section']}`}>
                <span className={`${style['grey-title']}`}>Discount code</span>
                <p className={`${style['grey-text']}`}>Please, enter your discount code here (if any)</p>
                <form className={`${style['discount-form']}`}>
                    <input className={`${style['discount-input']}`} type="text" placeholder="Insert Code" onChange={(event) => setCoupon(event.target.value)}/>
                    <div onClick={discountSubmit} className={`${style['discount-submit']}`}>
                        <FontAwesomeIcon icon={faChevronRight} className={`${style["tooltip_icon"]}`} />
                    </div>
                </form>
            </div>
            <div className={`${style['shopping-cart-flex']} ${style['reverse']}`}>
                <div className={`${style['total-box']}`}>
                    <div className={`${style['shopping-cart-flex']}`}>
                        <span className={`${style['grey-title']} ${style['total-title']}`}>Subtotal*</span>
                        <span className={`${style['total-value']}`}>{calculateCartTotal()} &euro;</span>
                    </div>
                    {/* <div className={`${style['shopping-cart-flex']}`}>
                        <span className={`${style['grey-title']} ${style['total-title']}`}>VAT</span>
                        <span className={`${style['total-value']}`}>{calculateVAT()} €</span>
                    </div> */}
                    <div className={`${style['shopping-cart-flex']}`}>
                        <span className={`${style['grey-title']} ${style['total-title']}`}>Discount</span>
                        <span className={`${style['total-value']}`}>{calculateDiscount()}</span>
                    </div>
                    <div className={`${style['shopping-cart-flex']}`}>
                        <span className={`${style['grey-title']} ${style['total-title']} ${style['black']}`}>TOTAL</span>
                        <span className={`${style['total-value-big']}`}>{calculateTotal()} €</span>
                    </div>
                    <p className={`${style['no-vat-text']}`}>*22% of VAT will be added on the next step, where applicable.</p>
                </div>
            </div>
            </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
            />
        </>
    )
}