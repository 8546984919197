import React, { useState, useEffect } from "react";
import style from './style.module.css';
import ConferenceDetails from '../../components/ConferenceDetails';
import { getActiveConference } from '../../utils/api';

function ConferenceCompletedBlock() {
    const [activeConference, setActiveConference] = useState({})

    var date = new Date(activeConference.date_start).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })
    

    useEffect(async () => {
        const res = await getActiveConference()
        setActiveConference(res)
    }, [])
    return (
        <>
           
                <div className={`${style['thankyou-grey-section']}`}>
                    <h4 className={`${style['grey-title']}`}>Event Details</h4>
                    <div className={`${style['thankyou-flex']}`}>
                    <ConferenceDetails image={activeConference.image} title={activeConference.title} description={activeConference.description}/>
                    <span className={`${style['event-date']}`}>{date}</span>
                    </div>
                </div>
                
           
        </>
    )
}

export default ConferenceCompletedBlock
