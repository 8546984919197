import style from './style.module.css';
import circleSvg from '../../assets/circleSvg.svg'
import deleteUser from '../../assets/delete-user.svg'


export default function AddedUser({userName, userMail, userDate, manuallyInserted, onDelete}){


    return(
        <div className={`${style['addeduser__container']}`}>
           <span className={`${style['user_name']}`} style={{paddingLeft:'25px'}}>{userName}</span>
           <span className={`${style['user_name']} ${style['break']}` }>{userMail}</span>
           <span className={`${style['user_name']}`}>{userDate ? userDate : ''}</span>
           {manuallyInserted && <img src={deleteUser} className={`${style['user_delete']}`} onClick={onDelete} />}
        </div>
    )
}