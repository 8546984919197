import style from './style.module.css';
import Avatar from '../../components/Avatar';
import expert from '../../assets/country-expert.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { getExpert, getExperts } from '../../utils/api';
import { getFile, getImage, goToUrl } from '../../utils/constants';

function CountryExpertProfile() {
    const [expert, setExpert] = useState({})

    

    useEffect(async () => {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString);
        const id = urlParams.get('id')
        if (id) {
            const res = await getExpert(id)
            if (res) setExpert(res)
        } else {
            const expert = urlParams.get('expert')
            if (expert) {
                const expertName = expert.toLowerCase().split("-")
                const experts = await getExperts()
                const foundExpert = experts.find(expert => expert.name.toLowerCase().split(" ").every(element => {
                    return expertName.includes(element);
                  }) &&  expert.surname.toLowerCase().split(" ").every(element => {
                    return expertName.includes(element);
                  }))

                if (foundExpert) {
                    const res = await getExpert(foundExpert.id)
                    if (res) setExpert(res)
                }
            }
        }
    }, [])

    return expert ? (
        <>     
    {/*header*/}
    <div className={`${style["s-country__header"]}`}>
        <div className="main__container">
            <div className={`${style["flex__start-center"]}`}>
            <Avatar imgSrc={expert.image && getImage(expert.image)} imgSize="extra-large"/>  
              <div className={`${style["s-country__detail"]}`}>
                    <h1 id="sce-name" className={`${style["s-country__name"]}`}>{expert.name} {expert.surname}</h1>
                    <h2 id="sce-details" className={`${style["s-country__details"]}`}><strong>{expert.country_name}</strong> | {expert.job_title}</h2>
                </div>
            </div>
        </div>
    </div>
    {/*end header*/}
    {/*country expert boxes*/}
    {/*DESKTOP*/}
    <div className={`${style["main__container"]} ${style["sce__desktop"]}`}>
        <div className={`${style["flex__start-between"]} ${style["s-country__content"]}`}>
            {/*SX*/}
            <div className={`${style["s-country__large"]}`}>
                <div className={`${style["s-country__box"]}`}>
                    {/*section 1*/}
                    <div className={`${style["box__detail"]}`}>
                        <div className={`${style["box__title"]}`}>
                            <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Year of birth</h3>
                        </div>
                        <div className={`${style["box__description"]}`}>
                            <p id="sce-birth-yr" className={`${style["box__description-text"]} ${style["m-0"]}`}>{new Date(expert.date_of_birth).getYear() + 1900 }</p>
                        </div>
                    </div>
                    {/*end section 2*/}
                    {/*section*/}
                    {expert.education != '...' &&
                        <div className={`${style["box__detail"]}`}>
                            <div className={`${style["box__title"]}`}>
                                <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Education</h3>
                            </div>
                            <div className={`${style["box__description"]}`}>
                                <p id="sce-education" className={`${style["box__description-text"]} ${style["m-0"]}`}>{expert.education}</p>
                            </div>
                        </div>
                    }
                    {/*end section*/}
                     {/*section*/}
                     {expert.academic_position != '...' &&
                        <div className={`${style["box__detail"]}`}>
                            <div className={`${style["box__title"]}`}>
                                <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Academic position</h3>
                            </div>
                            <div className={`${style["box__description"]}`}>
                                <p id="sce-education" className={`${style["box__description-text"]} ${style["m-0"]}`}>{expert.academic_position}</p>
                            </div>
                        </div>
                    }
                    {/*end section*/}
                    {/*section*/}
                    {expert.professional_qualification != '...' && expert.professional_qualification != '' &&  expert.professional_qualification != null &&
                        <div className={`${style["box__detail"]}`}>
                            <div className={`${style["box__title"]}`}>
                                <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Professional qualifications</h3>
                            </div>
                            <div className={`${style["box__description"]}`}>
                                <p id="sce-education" className={`${style["box__description-text"]} ${style["m-0"]}`}>{expert.professional_qualification}</p>
                            </div>
                        </div>
                       
                    }
                    {/*end section*/}
                    {/*section 3*/}
                    {expert.current_professional_position != '...' &&
                        <div className={`${style["box__detail"]}`}>
                            <div className={`${style["box__title"]}`}>
                                <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Current professional position</h3>
                            </div>
                            <div className={`${style["box__description"]}`}>
                                <p id="sce-curr-position" className={`${style["box__description-text"]} ${style["m-0"]}`}>{expert.current_professional_position}</p>
                            </div>
                        </div>
                    }
                    {/*end section*/}
                    {/*section 4*/}
                    {expert.description != '...' &&
                        <div className={`${style["box__detail"]}`}>
                            <div className={`${style["box__title"]}`}>
                                <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Membership and functions</h3>
                            </div>
                            <div className={`${style["box__description"]}`}>
                                <p id="sce-others" className={`${style["box__description-text"]} ${style["m-0"]}`}>{expert.description}</p>
                            </div>
                        </div>
                    }
                    {/*end section*/}
                    {/*section 5*/}
                    {expert.previous_position != '...' &&
                        <div className={`${style["box__detail"]}`}>
                            <div className={`${style["box__title"]}`}>
                                <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Former professional experience</h3>
                            </div>
                            <div className={`${style["box__description"]}`}>
                                <p id="sce-prev-position" className={`${style["box__description-text"]} ${style["m-0"]}`}>{expert.previous_position}</p>
                            </div>
                        </div>
                    }
                    {/*end section*/}
                    {/*section*/}
                    {expert.practical_experience != '...' &&
                        <div className={`${style["box__detail"]}`}>
                            <div className={`${style["box__title"]}`}>
                                <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Practical experience</h3>
                            </div>
                            <div className={`${style["box__description"]}`}>
                                <p id="sce-experience" className={`${style["box__description-text"]} ${style["m-0"]}`}>{expert.practical_experience}</p>
                            </div>
                        </div>
                    }
                    {/*end section*/}
                    {/*section*/}
                    {expert.languages != '...' &&
                        <div className={`${style["box__detail"]}`}>
                            <div className={`${style["box__title"]}`}>
                                <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Languages</h3>
                            </div>
                            <div className={`${style["box__description"]}`}>
                                <p id="sce-languages" className={`${style["box__description-text"]} ${style["m-0"]}`}>{expert.languages}</p>
                            </div>
                        </div>
                    }
                    {/*end section*/}
                      {/*section*/}
                      {expert.short_publication != '...' &&
                        <div className={`${style["box__detail"]}`}>
                            <div className={`${style["box__title"]}`}>
                                <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Publications</h3>
                            </div>
                            <div className={`${style["box__description"]}`}>
                                <p id="sce-languages" className={`${style["box__description-text"]} ${style["m-0"]}`}>{expert.short_publication}</p>
                            </div>
                        </div>
                    }
                    {/*end section*/}
                </div>
            </div>
            {/*DX*/}
            <div className={`${style["s-country__small"]}`}>
               {/*contacts*/}
                <div className={`${style["s-country__box-small"]}`}>
                    <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Contacts</h3>
                    <div className={`${style["flex__start-between"]} ${style["sce-contact__box"]}`}>
                       {expert.phone != '...' && <div className={`${style["flex__start-center"]} ${style["contact__expert-m"]}`}>
                           <div className={`${style["contact-icon-box"]} `}>
                           <FontAwesomeIcon icon={faPhoneAlt} className={`${style["contact__icon"]} `}/>
                           </div>
                            <p id="sce-xnumber" className={`${style["box__description-text"]} `}><a className={`${style["contact__link"]}`} href={`tel:+${expert.phone}`}>{expert.phone}</a></p>
                       </div>}
                       {expert.email != '...' && <div className={`${style["flex__start-center"]} ${style["contact__expert-m"]}`}>
                            <div className={`${style["contact-icon-box"]} `}>
                            <FontAwesomeIcon icon={faEnvelope} className={`${style["contact__icon"]} `} />
                            </div>
                            <p id="sce-mail" className={`${style["box__description-text"]} `}><a className={`${style["contact__link"]}`} href={`mailto:${expert.email}`}>{expert.email}</a></p>
                        </div>}
                        {expert.website != '...' && <div className={`${style["flex__start-center"]} ${style["contact__expert-m"]}`}>
                            <div className={`${style["contact-icon-box"]} `}>
                            <FontAwesomeIcon icon={faGlobe} className={`${style["contact__icon"]} `} />
                            </div>
                            <p id="sce-website" className={`${style["box__description-text"]} `}><a className={`${style["contact__link"]}`} href={expert.website}>{expert.website}</a></p>
                        </div>}
                    </div>
                </div>
                {/*reports*/}
                <div className={`${style["s-country__box-small-report"]} `}>
                    <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Reports</h3>
                    <div className={`${style["flex-column"]} ${style["sce-contact__box"]} `}>
                       {/*report links*/}
                       <a href="#" onClick={(e) => goToUrl(`documents-reports/?search=${expert.surname}`, e) } className={`${style["idi-arrow__link"]} ${style["sce-report__link"]} `}>Agency Report <FontAwesomeIcon icon={faArrowRight} className={`${style["arrow-right"]}`} /></a>
                       <a href="#" onClick={(e) => goToUrl(`documents-reports/?search=${expert.surname}`, e) } className={`${style["idi-arrow__link"]} ${style["sce-report__link"]} `}>Franchising Report <FontAwesomeIcon icon={faArrowRight} className={`${style["arrow-right"]}`} /></a>
                       <a href="#" onClick={(e) => goToUrl(`documents-reports/?search=${expert.surname}`, e) } className={`${style["idi-arrow__link"]}`}>Distribution Report <FontAwesomeIcon icon={faArrowRight} className={`${style["arrow-right"]}`} /></a>
                       {/*end report links*/}
                    </div>
                </div>
                {/*articles*/}
                <div className={`${style["s-country__box-small-article"]}`}>
                    <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Content</h3>
                    <div className={`${style["flex-column"]}${style["sce-contact__box"]} `}>
                        {/*article content*/}
                        <p className={`${style["box__description-text"]} ${style["m-0"]}`}>See country expert articles and publications</p>
                        <div className={`${style["sce-article__link"]}`}>
                            <a href="#" onClick={(e) => goToUrl(`news?writer=${encodeURIComponent(expert.name)}%20${encodeURIComponent(expert.surname)}`, e) } className={`${style["idi-arrow__link"]} ${style["sce-article__link"]}`}>View Articles <FontAwesomeIcon icon={faArrowRight} className={`${style["arrow-right"]}`} /></a>
                        </div>
                        {expert.curriculum != '...' && expert.curriculum != null ? <div className={`${style["sce-article__link"]}`}>
                            <a href={getFile(expert.curriculum)} className={`${style["idi-arrow__link"]} ${style["sce-article__link"]}`}>View Publications <FontAwesomeIcon icon={faArrowRight} className={`${style["arrow-right"]}`} /></a>
                        </div> : <div></div>}
                        {/*end article content*/}
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/*end country expert boxes*/}
    {/*END DESKTOP*/}  

    {/*MOBILE*/}
    <div className={`${style["main__container"]} ${style["sce__mobile"]}`}>
        {/*contacts*/}
        <div className={`${style["s-country__box__small"]}`}>
            <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Contacts</h3>
            <div className={`${style["flex__start-between"]} ${style["sce-contact__box"]}`}>
               <div className={`${style["flex__start-center"]} ${style["contact__expert-m"]}`}>
               <div className={`${style["contact-icon-box"]} `}>
                    <FontAwesomeIcon icon={faPhoneAlt} className={`${style["contact__icon"]} `}/>
                </div>                    
                <p id="sce-number" className={`${style["box__description-text"]} `}><a className={`${style["contact__link"]}`} href={`tel:+${expert.phone}`}>{expert.phone}</a></p>
               </div>
               <div className={`${style["flex__start-center"]} ${style["contact__expert-m"]}`}>
               <div className={`${style["contact-icon-box"]} `}>
                    <FontAwesomeIcon icon={faEnvelope} className={`${style["contact__icon"]} `}/>
                </div>                    
                <p id="sce-mail" className={`${style["box__description-text"]} `}><a className={`${style["contact__link"]}`} href={`mailto:${expert.email}`}>{expert.email}</a></p>
                </div> 
                <div className={`${style["flex__start-center"]} ${style["contact__expert-m"]}`}>
                <div className={`${style["contact-icon-box"]} `}>
                    <FontAwesomeIcon icon={faGlobe} className={`${style["contact__icon"]} `}/>
                </div>                    
                <p id="sce-website" className={`${style["box__description-text"]} `}><a className={`${style["contact__link"]}`} href={expert.website}>{expert.website}</a></p>
                </div>
            </div>
        </div>
        {/*main content*/}
        <div className={`${style["s-country__large"]}`}>
            <div className={`${style["s-country__box"]}`}>
                 {/*section*/}
                <div className={`${style["box__detail"]}`}>
                    <div className={`${style["box__title"]}`}>
                        <h3 className={`${style["box__title-name"]}${style["m-0"]}`}>Year of birth</h3>
                    </div>
                    <div className={`${style["box__description"]}`}>
                        <p id="sce-birth-yr" className={`${style["box__description-text"]} ${style["m-0"]}`}>{new Date(expert.date_of_birth).getYear() + 1900 }</p>
                    </div>
                </div>
                 {/*end section*/}
                 {/*section*/}
                 {expert.education != '...' && 
                    <div className={`${style["box__detail"]}`}>
                        <div className={`${style["box__title"]}`}>
                            <h3 className={`${style["box__title-name"]}${style["m-0"]}`}>Education</h3>
                        </div>
                        <div className={`${style["box__description"]}`}>
                            <p id="sce-education" className={`${style["box__description-text"]}${style["m-0"]}`}>{expert.education}</p>
                        </div>
                    </div>
                }
                 {/*end section*/}
                 {/*section*/}
                 {expert.professional_qualification != '...' && expert.professional_qualification != '' &&  expert.professional_qualification != null &&
                    <div className={`${style["box__detail"]}`}>
                        <div className={`${style["box__title"]}`}>
                            <h3 className={`${style["box__title-name"]}${style["m-0"]}`}>Professional qualifications</h3>
                        </div>
                        <div className={`${style["box__description"]}`}>
                            <p id="sce-education" className={`${style["box__description-text"]}${style["m-0"]}`}>{expert.professional_qualification}</p>
                        </div>
                    </div>
                }
                 {/*end section*/}
                {/*section*/}
                {expert.current_professional_position != '...' && <div className={`${style["box__detail"]}`}>
                    <div className={`${style["box__title"]}`}>
                        <h3 className={`${style["box__title-name"]}${style["m-0"]}`}>Current professional position</h3>
                    </div>
                    <div className={`${style["box__description"]}`}>
                        <p id="sce-curr-position" className={`${style["box__description-text"]}${style["m-0"]}`}>{expert.current_professional_position}</p>
                    </div>
                    </div>
                }
                 {/*end section*/}
                 {/*section*/}
                 {expert.description != '...' &&
                    <div className={`${style["box__detail"]}`}>
                        <div className={`${style["box__title"]}`}>
                            <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Membership and functions</h3>
                        </div>
                        <div className={`${style["box__description"]}`}>
                            <p id="sce-others" className={`${style["box__description-text"]} ${style["m-0"]}`}>{expert.description}</p>
                        </div>
                    </div>
                }
                 {/*end section*/}
                 {/*section*/}
                 {expert.previous_position != '...' &&
                <div className={`${style["box__detail"]}`}>
                    <div className={`${style["box__title"]}`}>
                        <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Former professional experience</h3>
                    </div>
                    <div className={`${style["box__description"]}`}>
                        <p id="sce-prev-position" className={`${style["box__description-text"]} ${style["m-0"]}`}>{expert.previous_position}</p>
                    </div>
                </div>
                }
                 {/*end section*/}
                 {/*section*/}
                {expert.practical_experience != '...' &&
                    <div className={`${style["box__detail"]}`}>
                        <div className={`${style["box__title"]}`}>
                            <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Practical experience</h3>
                        </div>
                        <div className={`${style["box__description"]}`}>
                            <p id="sce-experience" className={`${style["box__description-text"]} ${style["m-0"]}`}>{expert.practical_experience}</p>
                        </div>
                    </div>
                }
                 {/*end section*/}
                 {/*section*/}
                 {expert.languages != '...' &&
                    <div className={`${style["box__detail"]} ${style["last-box__detail"]}`}>
                        <div className={`${style["box__title"]}`}>
                            <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Languages</h3>
                        </div>
                        <div className={`${style["box__description"]}`}>
                            <p id="sce-languages" className={`${style["box__description-text"]} ${style["m-0"]}`}>{expert.languages}</p>
                        </div>
                    </div>
                }
                 {/*end section*/}
            </div>
        </div>
         {/*end main content*/}
         {/*reports*/}
        <div className={`${style["s-country__box-small-report"]} `}>
            <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Reports</h3>
            <div className={`${style["flex-column"]} ${style["sce-contact__box"]} `}>
               {/*report links*/}
                <a href="#" onClick={(e) => goToUrl(`documents-reports/?search=${expert.surname}`, e) } className={`${style["idi-arrow__link"]} ${style["sce-report__link"]} `}>Agency Report <FontAwesomeIcon icon={faArrowRight} className={`${style["arrow-right"]}`} /></a>
                <a href="#" onClick={(e) => goToUrl(`documents-reports/?search=${expert.surname}`, e) } className={`${style["idi-arrow__link"]} ${style["sce-report__link"]} `}>Franchising Report <FontAwesomeIcon icon={faArrowRight} className={`${style["arrow-right"]}`} /></a>
                <a href="#" onClick={(e) => goToUrl(`documents-reports/?search=${expert.surname}`, e) } className={`${style["idi-arrow__link"]}`}>Distribution Report <FontAwesomeIcon icon={faArrowRight} className={`${style["arrow-right"]}`} /></a>
               {/*end report links*/}
            </div>
        </div>
        {/*articles*/}
        <div className={`${style["s-country__box-small-article"]}`}>
            <h3 className={`${style["box__title-name"]} ${style["m-0"]}`}>Content</h3>
            <div className={`${style["flex-column"]}${style["sce-contact__box"]} `}>
                {/*article content*/}
                <p className={`${style["box__description-text"]} ${style["m-0"]}`}>See country expert articles and publications</p>
                <div className={`${style["sce-article__link"]}`}>
                    <a href="#" onClick={(e) => goToUrl(`news?writer=${encodeURIComponent(expert.name)}%20${encodeURIComponent(expert.surname)}`, e) } className={`${style["idi-arrow__link"]}`}>View Articles <FontAwesomeIcon icon={faArrowRight} className={`${style["arrow-right"]}`} /></a>
                </div>
                {expert.curriculum != '...' && expert.curriculum != null ? <div className={`${style["sce-article__link"]}`}>
                            <a href={getFile(expert.curriculum)} className={`${style["idi-arrow__link"]} ${style["sce-article__link"]}`}>View Publications <FontAwesomeIcon icon={faArrowRight} className={`${style["arrow-right"]}`} /></a>
                </div> : <div></div>}

                {/*end article content*/}
            </div>
        </div>
    </div>
    {/*END MOBILE*/}
                
           
        </>
    ) : <div></div>
}

export default CountryExpertProfile