export const MEMBERSHIP_PRICE = 900
export const CONFERENCE_PRICE = 700

const formatter = new Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
});

export function formatPrice (price) {
    return (price / 100).toFixed(2) * 1
}

export function priceFormatted(price) {
    return formatter.format(price / 100);
}

export function goToUrl(url, e) {
    
    if (e){
        e.stopPropagation();
        e.preventDefault()
    } 
    window.location = `${process.env.REACT_APP_PUBLIC_SITE}/${url}`
}

export function goToUrlNewTab(url, e) {
    
    if (e){
        e.stopPropagation();
        e.preventDefault()
    } 
    window.open(`${process.env.REACT_APP_PUBLIC_SITE}/${url}`, '_blank')
}

export function getImage(file) {
    return `${process.env.REACT_APP_API_HOST}/api/image/${file}`
}

export function getFile(file) {
    return `${process.env.REACT_APP_API_HOST}/api/file/${file}`
}