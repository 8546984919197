import React, { useState, useContext, useEffect } from "react"
import { checkAuth, doAuth } from "../utils/api";
import { useUser } from "./UserContext";

export const LoginContext = React.createContext();

export const LoginProvider = ({ children }) => {
  const {saveUser} = useUser()
  const [showLoginModal, setLoginModal] = useState(false);

  const showLogin = (event) => {
    setLoginModal(true)
  };

  const hideLogin = () => {
    setLoginModal(false)
  };

  const doLogin = async ({email, password}) => {
    const res = await doAuth(email, password)
    return res
  }

  const checkLogin = async () => {
    try {
      await checkAuth(localStorage.getItem('token'))
    } catch(e) {
      resetUser()
    }
  }

  const updateUser = (values) => {
    if (values.auth_token) localStorage.setItem('token', values.auth_token)
    saveUser(values)
    hideLogin()
  }

  const doLogout = () => {
    localStorage.removeItem('token')
    const event = new Event('logout');
    window.dispatchEvent(event);
  }

  const resetUser = () => {
    hideLogin()
    saveUser({})
  }

  useEffect(() => {
    if (localStorage.getItem('token')) checkLogin()
    window.addEventListener('doLogin', (e) => updateUser(e.detail));
    window.addEventListener('checkAuth', (e) => updateUser(e.detail));
    window.addEventListener('logout', (e) => resetUser());
  }, []);
  

  return (
    <LoginContext.Provider value={{ showLoginModal, showLogin, hideLogin, doLogin, doLogout, checkLogin }}>
      {children}
    </LoginContext.Provider>
  );
};

export const LoginConsumer = LoginContext.Consumer;

export function useLogin() {
    const context = useContext(LoginContext);
    return context;
};