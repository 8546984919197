import InputField from '../../InputField'
import MembershipTitle from '../../MembershipTitle'
import RedButton from '../../RedButton'
import InputSelect from '../../InputSelect'
import style from './style.module.css'
import { useUser } from '../../../context/UserContext'
import { Formik } from 'formik'
import * as Yup from "yup";
import { updateCompany } from '../../../utils/api'
import { useLogin } from '../../../context/LoginContext'
import { toast, ToastContainer } from 'react-toastify'
import { countries } from '../../../utils/countries'

const CompanyInformationSchema = Yup.object().shape({
        name: Yup.string().required("Required"),
        vat: Yup.string().nullable(),
        country: Yup.string().nullable(),
        address: Yup.string().nullable(),
        postal_code: Yup.string().nullable(),
        city: Yup.string().nullable(),
        province: Yup.string().nullable(),
    });

function ProfileCompanyInformation() {
    const {user} = useUser()
    const {checkLogin} = useLogin()
    const {company} = user

   

    const doSave = async values => {
        const res = await updateCompany(company.id, values)
        if (res === true){
            toast.success("Profile Updated!")
            return checkLogin()
             
        }else{
            toast.error("Something went wrong")
        }
    }
    return (
           <div className={`${style["myProfile__container"]}`}>
            <MembershipTitle title="Company information"/>
            <Formik
                initialValues={company}
                validationSchema={CompanyInformationSchema}
                onSubmit = {(values) => { 
                    doSave(values)
                }}
            >
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit
            }) => ( 
                <>  
                {
                    Object.keys(errors).map(error => <p>{errors[error]}</p>)
                } 
                <div className={`${style["myProfile__input-container"]}`}>
                    <InputField 
                        fieldType="text" 
                        fieldLabel="Company Name"
                        name="name"
                        handleChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        errors={errors.name}                   
                    />
                    <InputField 
                        fieldType="text" 
                        fieldLabel="Field of activity"
                        name="field"
                        value={values.type}
                        disabled
                          />
                     {/* <InputSelect 
                    labelText="Select your field of activity"
                    placeholderText="Field of activity"
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    values={[
                        {value: "individual", label: "Individual"},
                        {value: "company", label: "Company"},
                        {value: "law firm", label: "Law firm"},
                        {value: "lawyer", label: "Lawyer"},
                        {value: "association", label: "Association"}
                        ]}  /> */}
                    <InputSelect 
                        labelText="Country"
                        placeholderText="Country"
                        name="country"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        selectedValue={values.country}
                        values={countries.map(country => {
                            return country;
                        })} 
                        errors={errors.country} />
                    <InputField 
                        fieldType="text" 
                        fieldLabel="Address"
                        name="address"
                        handleChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address}
                        errors={errors.address}  />
                    <InputField 
                        fieldType="text" 
                        fieldLabel="Postal code"
                        name="postal_code"
                        handleChange={handleChange}
                        onBlur={handleBlur}
                        value={values.postal_code}
                        errors={errors.postal_code} />
                    <InputField 
                        fieldType="text" 
                        fieldLabel="City"
                        name="city"
                        handleChange={handleChange}
                        onBlur={handleBlur}
                        value={values.city}
                        errors={errors.city}  />
                    <InputField 
                        labelText="Province"
                        name="province"
                        fieldLabel="Province"
                        handleChange={handleChange}
                        onBlur={handleBlur}
                        value={values.province}
                        errors={errors.province}
                    />
                </div>
                <RedButton textButton="Save" position={{marginTop: '45px'}} onClick={handleSubmit}/>
                </>
            )} 
            </Formik>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
            />
           </div>  
    )
}

export default ProfileCompanyInformation
