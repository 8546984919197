import React, { useState } from "react";
import style from './style.module.css'
import verifiedImg from '../../assets/verified.svg'
function SignupStep({stepStatus, stepsArray, currentStep}) {

    return (
        stepsArray.map((step, index) =>{
            return(
            <div key={index} className={`${style["step__container"]}`}>
                {index < currentStep  ? <img src={verifiedImg} className={`${style["step_svg"]}`}/> : <h2 className={`${style["step_number"]}`}>{index+1}</h2>}
                {index === currentStep ? <h3 style={{color: '#22333B'}} className={`${style["step_description"]}`}>{step.title}</h3> : <h3 className={`${style["step_description"]}`}>{step.title}</h3>}
            </div>
            )
        })
    )
}

export default SignupStep
