import style from './style.module.css'
import closeSvg from '../../assets/close-login.svg'
import React from 'react'

function DistributionCase({cardTitle, cardDescription, onClose}) {

    return (
        <>
            <div className={`${style["case__container"]}`}>
                <div className={`${style["case__card-container"]}`}>
                <div className={`${style["case__card-header"]}`}>
                    <img className={`${style["case__card-header-close"]}`} src={closeSvg} alt="close_img" onClick={onClose}></img>
                </div>
                <h2 className={`${style["case__card-title"]}`}>{cardTitle}</h2>
                <div className={`${style["case__card-description"]}`}>{cardDescription}</div>
                </div>
            </div>
            
        </>
    )
}

export default DistributionCase
