export default function filter(object, value) {
    if (Array.isArray(object)) return filterArray(object, value);
    else if (typeof object == 'object' && object) return filterObject(object, value);
    if (typeof object == 'string' && object) return object.toLowerCase() === value.toLowerCase();
    return object === value;
}

function filterObject(obj, searchValue) {
    var value;
    var found = {}

    for (let key in obj) {
      value = filter(obj[key], searchValue);

      if (typeof obj[key] == 'string' &&  obj[key] && obj[key].toLowerCase() === searchValue.toLowerCase()) {
        found = obj
        break;
      }
      if (obj[key] === searchValue || value === true || Object.keys(value).length > 0) {
        found = obj
        break;
     }
    }

    return found
}

function filterArray(array, searchValue) {
    var filtered = [];

    for (let index in array) {
        const res = filter(array[index], searchValue);
        if (res === true || Object.keys(res).length > 0) filtered.push(res);
        else {
          continue;
        }
    }

    return filtered;
}