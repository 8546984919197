import style from './style.module.css';
import Accordion from 'react-bootstrap/Accordion';
import 'bootstrap/dist/css/bootstrap.min.css';
import DocumentRow from '../DocumentRow';
import { useEffect, useState } from 'react';
import { useUser } from '../../context/UserContext';

export default function AccordionSection({sections, twoColumns, category}){

    const [activeSessions, setActiveSessions] = useState([])
    const {isLogged} = useUser()

    useEffect(() => {
        if (sections) {
            if (isLogged) setActiveSessions([...sections])
            else setActiveSessions([sections[0]])
        }
    }, [isLogged, sections]);

    return(  
    <div className={`${style["documents__container"]}`}>    
        <div>
            <Accordion defaultActiveKey="0" className={`${style["accordion__container"]}`}>
                {activeSessions && activeSessions.map((section, index) => 
                <Accordion.Item eventKey={`${section.id}${index}`} key={`${section.id}${index}`}>
                    <Accordion.Header><h2 className={`${style["accordion__title"]}`}>{category === 'Arbitral Cases' ? section.title.split('-')[0].trim() : section.title}</h2></Accordion.Header>
                    <Accordion.Body>
                        {section.rows.map(row => <DocumentRow twoColumns={twoColumns} key={`${section.id}${row.id}`} title={row.title} content={row.content} documents={row.documents} />)}
                    </Accordion.Body>
                </Accordion.Item>)}
            </Accordion>    
        </div>
    </div> 
    )
}