import { useEffect, useState } from 'react'
import ReportsRow from '../ReportsRow'

const ITEMS_PER_PAGE = 10

function ReportsPage({sections, category, country}) {
    const [currentPage, setCurrentPage] = useState(0)
    const [pageElements, setPageElements] = useState([])
    const [sortedReports, setSortedReports] = useState([])
    // const [filteredDocuments, setFilteredDocuments] = useState([])
    const [filteredElements, setFilteredElements] = useState([])

    const showPagination = () => {
        if (sections && sections.length > 0) {
            return Array.from({length: Math.ceil(sections.length / ITEMS_PER_PAGE)}, (_, i) => i + 1 ).map(page => 
                <div key={page} onClick={() => setCurrentPage(page - 1)} className={currentPage === page -1 ? 'active' : 'inactive'}>{page}</div>
            )
        }
    }

    useEffect(() =>{
        // if (!category) {
        const sortedSections = sections.sort(( a , b ) =>{
            return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
        })
        setSortedReports(sortedSections)
        // }
    }, [sections])

    useEffect(() => {

      return () => {
        setCurrentPage(1)
      };
    }, []);

    useEffect(() => {
        if (sections && sections.length > 0) {
            const start = currentPage * ITEMS_PER_PAGE // 1 * 10 = 10
            const end = currentPage * ITEMS_PER_PAGE + ITEMS_PER_PAGE // 1 * 10 + (10 - 1) = 9
            setPageElements(sortedReports.slice(start, end))
        }
    }, [currentPage, sections, sortedReports]);

    return <div>
        {pageElements ? pageElements.map(section => section.rows.map(row => <ReportsRow key={`${section.id}_${row.id}`} category={category} countryName={row.title} documents={row.documents} />)) : <div></div>}
        <div className='documents__pagination'>
            {sortedReports.length > 10 ? showPagination() : null}
        </div>
    </div>
}

export default ReportsPage
