import React, { useState, useContext } from "react"
export const CartContext = React.createContext();

const defaultCart = {};

export const CartProvider = ({ children, cart }) => {
  const [currentCart, setCurrentCart] = useState(
    cart || {...defaultCart}
  );

  const saveCart = (values) => {
    setCurrentCart(values)
  };

  return (
    <CartContext.Provider value={{ cart: currentCart, saveCart }}>
      {children}
    </CartContext.Provider>
  );
};

export const CartConsumer = CartContext.Consumer;

export function useCart() {
    const context = useContext(CartContext);
    return context;
};