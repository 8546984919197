import { useUser } from '../../../context/UserContext';
import style from './style.module.css';

export default function Sidebar({onChange, sectionActive}){
        const {user} = useUser()

    return(
        <div className={`${style['blocco-menu__container']}`}>
        <div className={`${style['sidebar__container']}`}>
            <ul className={`${style['blocco-menu__list']}`}>
                <li className={`${style['blocco-menu__list-item']} ${style[sectionActive === 'personalInformation' && 'block-activate']}`}>
                        <a onClick={() => onChange('personalInformation')}>Personal Information</a>
                </li>
                {/* {user.company_founder === 1 && } */}
                <li className={`${style['blocco-menu__list-item']} ${style[sectionActive === 'companyInformation' && 'block-activate']}`}>
                        <a onClick={() => onChange('companyInformation')}>Company Information</a>
                </li>
                <li className={`${style['blocco-menu__list-item']} ${style[sectionActive === 'accountInformation' && 'block-activate']}`}>
                        <a onClick={() => onChange('accountInformation')}>Account Information</a>
                </li>
                <li className={`${style['blocco-menu__list-item']} ${style[sectionActive === 'membershipInformation' && 'block-activate']}`}>
                        <a onClick={() => onChange('membershipInformation')}>Membership Information</a>
                </li>
                <li className={`${style['blocco-menu__list-item']} ${style[sectionActive === 'memberArea' && 'block-activate']}`}>
                        <a onClick={() => onChange('memberArea')}>Member Area</a>
                </li>
            </ul>
        </div>
        </div>
    )
}