import InputField from '../../InputField'
import MembershipTitle from '../../MembershipTitle'
import RedButton from '../../RedButton'
import ChangeAccountAvatar from '../../ChangeAccountAvatar'
import style from './style.module.css'
import { useUser } from '../../../context/UserContext'
import { Formik } from 'formik'
import * as Yup from "yup";
import { emailValidation } from '../../../utils/validations'
import { updateUser } from '../../../utils/api'
import { getImage } from '../../../utils/constants'
import { useState } from 'react'
import { useLogin } from '../../../context/LoginContext'
import { ToastContainer, toast } from 'react-toastify';

const LoginSchema = Yup.object().shape({
    email: Yup.string()
        .required("Required")
        .email("Please enter a valid email")
        .matches(/^\S+$/, "Please remove any spaces")
        .test("email-is-not-used", "Email is already in use", function() {
            return emailValidation;
        }),
    new_password: Yup.string()
        .nullable(true)
        .min(8, "Please use at least 8 characters")
        .matches(/[0-9]+/, "Please use at least 1 number")
        .matches(/[a-z]+/, "Please use at leat one lowercase letter")
        .matches(/[A-Z]+/, "Please use at leat one uppercase letter")
        .matches(/[-+_!@#$%^&*.,?]+/, "Please use at least one special character"),
    new_password_confirm: Yup.string()
        .nullable(true)
        .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
        .when('new_password', {
            is: (val) => val,
            then: Yup.string().required("Passwords must match")
        }),  
    current_password: Yup.string()
        .nullable(true)
        .when('new_password', {
            is: (val) => val,
            then: Yup.string().required("Must enter current password")
        })
    });

function ProfileAccountInformation() {
    const {user} = useUser()
    const {checkLogin} = useLogin()
    const [errorMessage , setErrorMessage] = useState("")


    const doSave = async (values) => {
        const res = await updateUser(user.id, values)
        console.log("res",res);
        if (res !== true) {
            setErrorMessage(res)
            toast.error("Something went wrong")
        }
        else{
            checkLogin()
            toast.success("Profile Updated!")
        } 
    }
    console.log("user", user)
    return (
           <div className={`${style["myProfile__container"]}`}>
            <MembershipTitle title="Personal Information" AlignText="left"/> 
            <Formik
                initialValues={{ email: user.email, password: '', password_confirm: '', image: user.image, email_marketing: user.email_marketing === 0 ? false : true }}
                validationSchema={LoginSchema}
                onSubmit = {(values) => { 
                    doSave(values)
                    console.log('on submit values',values)
                }}
            >
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue
            }) => ( 
                <> 
                    <div className={`${style["myProfile__input-container"]}`}>
                        <InputField 
                            fieldType="text" 
                            fieldLabel="E-mail address" 
                            name="email"
                            value={values.email}
                            disabled />
                        <InputField 
                            fieldType="current_password" 
                            fieldLabel="Current password"
                            name="current_password" 
                            hasErrors={errors.current_password} 
                            handleChange={handleChange} 
                            value={values.current_password}
                            errors={errors.current_password}
                        />
                        <InputField 
                            fieldType="new_password" 
                            fieldLabel="New password"
                            name="new_password"
                            handleChange={handleChange}
                            onBlur={handleBlur}
                            value={values.new_password}
                            errors={errors.new_password}
                        />
                        <InputField 
                            fieldType="new_password_confirm" 
                            fieldLabel="Confirm password"
                            name="new_password_confirm" 
                            hasErrors={errors.new_password_confirm} 
                            handleChange={handleChange} 
                            value={values.new_password_confirm}
                            errors={errors.new_password_confirm}
                        />
                       
                    </div>
                    <div className={`${style['switch__container']}`}>
                        <label className={`${style['switch']}`}>
                        <input type="checkbox" 
                            fieldType="email_marketing" 
                            name="email_marketing" 
                            defaultChecked={values.email_marketing}
                            onChange={() => setFieldValue("email_marketing", !values.email_marketing)}
                            value={values.email_marketing}                       
                        />
                        <span className={`${style['slider']} ${style['round']}`}></span>
                        </label> 
                        <span>Enable email marketing</span>
                    </div>
                    <ChangeAccountAvatar currentImage={getImage(user.image)} onChange={(image) => setFieldValue('image', image, false)} />
                    <RedButton textButton="Save" onClick={handleSubmit} />
                    <p>{errorMessage}</p>
                </> )}
            </Formik>
            
           </div>  
    )
}

export default ProfileAccountInformation
