import style from './style.module.css'

function CheckboxField({checkboxText, name, handleChange, value, errors}) {

    return (
        <> 
            <label className={`${style["checkbox_label"]}`}>
            <input
                className={`${style["checkbox_field"]}`}
                type="checkbox"
                name={name}
                onChange={handleChange}
                checked={value}
                
            />
           
                {checkboxText}
                <span className={`${style["input_error"]}`}>
               {errors}
                </span>
                
            </label>
           
            
        </>
    )
}

export default CheckboxField
