import React, { useEffect, useState } from 'react'
import style from './style.module.css'
import chevronDown from '../../assets/chevronDown.svg'
import chevronRight from '../../assets/chevronRight.svg'
import clear from '../../assets/clear.svg'
import { toast } from 'react-toastify'

export default function SearchDropdown({ defaultString, options, stringAttribute, handleChoice, shouldReset, resetValue, demoValue, readOnly }) {
    const [currentString, setCurrentString] = useState(defaultString);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const reset = (e) => {
        if (readOnly) {
            e.stopPropagation();
            toast.error("Login to use this feature");
            return;
        }
        setCurrentString(defaultString);
        resetValue(defaultString);
    }

    useEffect(() => {
        if (shouldReset) {
            reset();
        }
    }, [shouldReset]);

    const handleClick = (option) => {
        setCurrentString(option[stringAttribute]);
        setDropdownOpen(false);
        handleChoice(option, defaultString);
    }

    const showIcon = () => {
        if (currentString !== defaultString) {
            return <img src={clear} onClick={reset} />
        } else {
            return <img src={chevronDown} />
        }
    }

    const handleDropdown = () => {

        if (readOnly) {
            toast.error("Login to use this feature");
            return;
        }

        if (currentString === defaultString) {
            setDropdownOpen(!dropdownOpen);
        }
    }


    useEffect(() => {
        if (demoValue) {
            setCurrentString(demoValue)
        }
    }, [demoValue])

  return (
    <div className={`${style["dropdown-container"]}`}>
        <div
            onClick={handleDropdown}
            className={`${style["custom-select"]} ${currentString !== defaultString ? style["selected"] : ""}`}
        >
            <span className={`${style["select__text"]}`}>{currentString}</span>
            {showIcon()}
        </div>
        {dropdownOpen && (
            <div className={`${style["dropdown"]}`}>
                <ul>

                {options && options.map((option) => (

                    <li
                        key={option.id}
                        onClick={() => handleClick(option)}
                    >
                        <span>{option[stringAttribute]}</span>
                        <img src={chevronRight} />
                    </li>
                ))}
                </ul>
            </div>
        )}
    </div>
  )
}
