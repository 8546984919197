import { Formik } from "formik";
import * as Yup from "yup";
import { emailValidation } from "../../../utils/validations";
import InputField from "../../InputField";
import MembershipTitle from "../../MembershipTitle";
import RedButton from "../../RedButton";
import InputSelect from "../../InputSelect";
import style from "./style.module.css";
import { useUser } from "../../../context/UserContext";
import { updateUser } from "../../../utils/api";
import { useLogin } from "../../../context/LoginContext";
import { toast, ToastContainer } from "react-toastify";
import redArrow from "../../../assets/red-arrow.svg";

const PersonalInformationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Required")
    .email("Please enter a valid email")
    .matches(/^\S+$/, "Please remove any spaces")
    .test("email-is-not-used", "Email is already in use", function() {
      return emailValidation;
    }),
  name: Yup.string().required("Required"),
  surname: Yup.string().required("Required"),
  vat: Yup.string().nullable(),
  fiscal_code: Yup.string().nullable(),
  phone: Yup.string()
    .min(6, "Please use at least 6 numbers")
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      "Phone number is not valid"
    )
    .required("Required"),
  mobile: Yup.string()
    .nullable()
    .min(6, "Please use at least 6 numbers")
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      "Phone number is not valid"
    ),

  fax: Yup.number("Non è un numero").nullable(),
  email_alternative: Yup.string()
    .nullable()
    .email("Please enter a valid email")
    .matches(/^\S+$/, "Please remove any spaces")
    .test("email-is-not-used", "Email is already in use", function() {
      return emailValidation;
    }),
});

function ProfilePersonalInformation() {
  const { user } = useUser();
  const { checkLogin } = useLogin();

  const doSave = async (values) => {
    const res = await updateUser(user.id, values);
    if (res === true) {
      toast.success("Profile Updated!");
      return checkLogin();
    } else {
      toast.error("Something went wrong");
    }
  };

  return (
    <div className={`${style["myProfile__container"]}`}>
      <MembershipTitle title="Personal Information" AlignText="left" />
      <Formik
        initialValues={{
          email: user.email,
          name: user.name,
          surname: user.surname,
          vat: user.vat,
          fiscal_code: user.fiscal_code,
          phone: user.phone,
          mobile: user.mobile,
          fax: user.fax,
          email_alternative: user.email_alternative,
          field_of_activity: user.field_of_activity,
        }}
        validationSchema={PersonalInformationSchema}
        onSubmit={doSave}
      >
        {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
          <>
            {Object.keys(errors).length > 0 &&
              Object.keys(errors).map((error) => <p>{errors[error]}</p>)}
            <div className="myProfile__input-wrapper">
                <div className={`${style["myProfile__input-container"]}`}>
                <InputField
                    fieldType="text"
                    fieldLabel="Name"
                    name="name"
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    errors={errors.name}
                />
                <InputField
                    fieldType="text"
                    fieldLabel="Surname"
                    name="surname"
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    value={values.surname}
                    errors={errors.surname}
                />
                {/*  <InputField fieldType="text" fieldLabel="VAT number(if personal)"
                                name="vat"
                                handleChange={handleChange}
                                onBlur={handleBlur}
                                value={values.vat}
                                errors={errors.vat} /> */}
                <InputField
                    fieldType="text"
                    fieldLabel="Fiscal code"
                    name="fiscal_code"
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fiscal_code}
                    errors={errors.fiscal_code}
                />
                <InputField
                    fieldType="text"
                    fieldLabel="Current email"
                    name="email"
                    disabled
                    value={values.email}
                />
                </div>
                <div className={`${style["myProfile__input-container"]}`}>
                <InputField
                    fieldType="text"
                    fieldLabel="Email alternative"
                    name="email_alternative"
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email_alternative}
                    errors={errors.email_alternative}
                />
                <InputField
                    fieldType="text"
                    fieldLabel="Phone"
                    name="phone"
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    errors={errors.phone}
                />
                <InputField
                    fieldType="text"
                    fieldLabel="Mobile"
                    name="mobile"
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    value={values.mobile}
                    errors={errors.mobile}
                />
                <InputField
                    fieldType="text"
                    fieldLabel="Fax"
                    name="fax"
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fax}
                    errors={errors.fax}
                />
                <InputSelect
                    name="field_of_activity"
                    labelText="Working at"
                    placeholderText="Working at"
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    selectedValue={user.field_of_activity}
                    values={[
                    { value: "individual", label: "Individual" },
                    { value: "company", label: "Company" },
                    { value: "other", label: "Other" },
                    ]}
                />
                </div>
            </div>    
            <RedButton textButton="Save" onClick={handleSubmit} />
          </>
        )}
      </Formik>
      <ToastContainer position="bottom-right" autoClose={3000} />
      <a
        className={`${style["footer_link-href"]}`}
        href="mailto:info@idiproject.com?subject=Request - Change email"
        target="_blank"
      >
        <h5 className={`${style["footer_link-title"]}`}>
          Need to change your email? Contact us!
        </h5>
        <img src={redArrow} className={`${style["footer_link-svg"]}`} />
      </a>
    </div>
  );
}

export default ProfilePersonalInformation;
