
import { useEffect, useState } from 'react'
import style from './style.module.css'
import downloadSvg from '../../assets/download-svg.svg'
import DocumentLanguageSelect from '../DocumentLanguageSelect'
import InfoIcon from '../InfoIcon'
import { useUser } from '../../context/UserContext'
import { useAccessDenied } from '../../context/AccessDeniedContext'
import { getFile } from '../../utils/constants'


function DocumentsDownloadLink({linkText , infoIcon, files, languageSelect}) {
    const {isLogged, isMember} = useUser()
    const {showAccessDenied} = useAccessDenied()
    const [downloadLink, setDownloadLink] = useState("")
    const tooltipText = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra augue imperdiet mauris, ornare imperdiet rhoncus. "

    const setLanguage = (language) => {
        setDownloadLink(getFile(files[language].file))
    }

    useEffect(() => {
        if (Object.keys(files).length > 0) {
            setDownloadLink(getFile(linkText.toLowerCase().includes("report") ? `${files[Object.keys(files)[0]].file}?pdf=true` : files[Object.keys(files)[0]].file))
        }
    }, [files])

    return (
        downloadLink ? <div className={`${style["link__wrapper"]}`}>
            <a className={`${style["link__container"]}`} href={isLogged && isMember ? downloadLink : '#'} onClick={(event) => {
                if (isLogged && isMember) return false 
                event.preventDefault();
                showAccessDenied()
            }} download>
                <img className={`${style["link__svg"]}`} src={downloadSvg} alt="svg-download"></img>
                <h4 className={`${style["link__content"]}`}>
                    {linkText}
                </h4> 
            </a>
            { Object.keys(files).length > 1 && <DocumentLanguageSelect languages={files} onChange={setLanguage} />}
            { infoIcon && <InfoIcon tooltipText={tooltipText} />}
        </div> : <div></div>
    )
}

export default DocumentsDownloadLink
