import style from './style.module.css'
import closeSvg from '../../assets/close-login.svg'
import React, { useState } from 'react'

function LoginPopup({cardLabel, cardTitle, cardDescription, onClose, children}) {

    const [forgotPassword, setForgotPassword] = useState(false)

    return (
        <>
            <div className={`${style["login__container"]}`}>
                <div className={`${style["login__card-container"]}`}>
                <div className={`${style["login__card-header"]}`}>
                    <h5 className={`${style["login__card-header-title"]}`}>{forgotPassword ? "Forgot Password" : cardLabel}</h5>
                    <img className={`${style["login__card-header-close"]}`} src={closeSvg} alt="close_img" onClick={onClose}></img>
                </div>
                <h2 className={`${style["login__card-title"]}`}>{forgotPassword ?  "Forgot Password" : cardTitle}</h2>
                <div className={`${style["login__card-description"]}`}>{forgotPassword ?  "We will send by email a link to reset your password." : cardDescription}</div>
                {/* Childer che cambia il contenuto del popup */}
                {React.cloneElement(children, { onForgotPassword: () => setForgotPassword(true) })}
                </div>
            </div>
            
        </>
    )
}

export default LoginPopup
