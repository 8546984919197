import style from './style.module.css'

function InputField({fieldType, placeholderText, name, fieldLabel, handleChange, value, hasErrors, disabled, variableWidth, variableHeight, errors}) {

   
    return (      
        <div className={`${style["input_container"]}`}>
            <label className={`${style["input_label"]}`}>{fieldLabel} </label>
            <input
            className={`${style["input_field"] } ${style[variableWidth]} ${style[variableHeight]}`}
            type={fieldType}
            name={name}
            placeholder={placeholderText}
            onChange={handleChange}
            value={value}
            disabled={disabled} />
            <span className={`${style["input_error"]}`}>
               {errors}
            </span>
        </div>
    )
}

export default InputField
