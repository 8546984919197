import { useLogin } from '../../context/LoginContext';
import { goToUrl } from '../../utils/constants';
import style from './style.module.css';


export default function AccessMembersOnly({bannerText}){
    const {showLogin} = useLogin()

    return(
        <div className={`${style["access-member__block"]}`}>
            <h3 className={`${style["access-member__title"]}`}>Access for Members only</h3>
            <p className={`${style["access-member__text"]}`}>{bannerText}</p> 
            <div className={`${style["flex-center"]}`}>
                <a onClick={showLogin} className={`${style["white__button"]}`} style={{cursor: "pointer"}}>Log in</a>
                <a href="#" onClick={(e) => goToUrl('sign-up', e)} className={`${style["red__button"]}`} style={{cursor: "pointer"}}>Become a member</a>
            </div>   
        </div>
    )
}