import style from './style.module.css';

export default function Areatext({areaText}){


    return(
        
            <p className={`${style['area__text']}`}>{areaText}</p>
        
    )
}