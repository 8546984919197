import { CartProvider } from './CartContext';
import { UserProvider } from './UserContext';
import { LoginProvider } from './LoginContext';
import { AccessDeniedProvider } from './AccessDeniedContext';
import { IdarbProvider } from './IdarbContext';

export function AppWrapper({ children }) {
  return (
    <UserProvider>
      <LoginProvider>
        <AccessDeniedProvider>
            <CartProvider>
              <IdarbProvider>
                {children}
              </IdarbProvider>
            </CartProvider>
        </AccessDeniedProvider>
      </LoginProvider>
    </UserProvider>
  );
}