import style from './style.module.css';
import Avatar from "../Avatar";
import { getImage } from '../../utils/constants';
import noImg from '../../assets/login_avatar.svg'

export default function MemberCardInfo({values}){


    return(
        <div className={`${style['memberinfo__flex']}`}>
            <Avatar imgSrc={values.image != null ? getImage(values.image) : noImg} imgSize="medium"/>
            <div className={`${style['membercard__info']}`}>
                <h4 className={`${style['membercard__name']}`}>{values.name} {values.surname}</h4>
                {(values.country_name || values.job_title) && <p className={`${style['membercard__data']}`}><span className={`${style['membercard__strong']}`}>{values.country_name || ""}</span> | <span>{values.job_title || ""}</span></p>}
            </div>
        </div>
    )
}