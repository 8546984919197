import React from 'react'
import style from './style.module.css'

export default function SearchAutocomplete({suggestions, handleClick, category, inputName, attribute, size}) {
    console.log(suggestions)
  return (
    <div className={`${style['autocomplete']} ${style['no-border']} ${size === "large" ? style['large'] : ''}`}>
        <ul>
            {suggestions && suggestions.map((suggestion, i) => (
                <li onClick={() => handleClick(suggestion, inputName)} key={suggestion.id}>{category === 'Arbitral Cases' ? suggestion[attribute].split("-")[0].trim() : suggestion[attribute]}</li>
            ))}
        </ul>
    </div>
  )
}
