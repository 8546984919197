import { useEffect, useState } from 'react';
import { useUser } from '../../context/UserContext';
import DocumentRow from '../DocumentRow';
import style from './style.module.css';

function RegularSection({sections, twoColumns, forceAllSections = false}) {
    const [activeSessions, setActiveSessions] = useState([])
    const {isLogged} = useUser()

    useEffect(() => {
        if (sections) {
            if (isLogged || forceAllSections) setActiveSessions([...sections])
            else setActiveSessions([sections[0]])
        }
    }, [isLogged, sections]);

    return activeSessions && activeSessions.map((section) => <div key={section.id} className={`${style["documents__container"]}`}>
                <h2 className={`${style["documents__container-title"]}`}>
                    {section.title}
                </h2>
                {section.rows.map(row => <DocumentRow twoColumns={twoColumns} key={`${section.id}${row.id}`} title={row.title} content={row.content} documents={row.documents} />)}
            </div>)
}

export default RegularSection
