import style from './style.module.css';
import { useEffect, useState } from 'react';
import { getIdarbs } from '../../utils/api';
import { goToUrl } from '../../utils/constants';

function IdarbPage() {

  const [idarbs, setIdarbs] = useState([])

  useEffect(async () => {
    const res = await getIdarbs()
    if (res) {
      const idarbsResult = res.sort((a, b) => a.surname.toLowerCase() > b.surname.toLowerCase() ? 1:-1)
      setIdarbs(idarbsResult)
    }
  }, [])


  return (
    <div className="grey__container docs-tools__container arbitrators__container">
      <h3 className="arbitrators-list__title">Chair/sole arbitrators</h3>
      <div className="arbitrators__list docs-tools__flex">
        <div className="arbitrators-list__box first-arb__list">
          <ul className="arb__list">
            {idarbs.filter(idarbItem => idarbItem.group === "Chair/sole arbitrators").map(idarbItem => <li><span className="strong" onClick={(e) => goToUrl(`idarb-profile?id=${idarbItem.id}`, e)} >{idarbItem.name} {idarbItem.surname.toUpperCase()}</span> | {idarbItem.country}</li>)}
          </ul>
        </div>
      </div>
      <h3 className="arbitrators-list__title arbitrators-second__title">Party appointed arbitrators</h3>
      <div className="arbitrators__list docs-tools__flex">
        <div className="arbitrators-list__box">
          <ul className="arb__list">
            {idarbs.filter(idarbItem => idarbItem.group === "Party appointed arbitrators").map(idarbItem => <li><span className="strong" onClick={(e) => goToUrl(`idarb-profile?id=${idarbItem.id}`, e)} >{idarbItem.name} {idarbItem.surname.toUpperCase()}</span> | {idarbItem.country}</li>)}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default IdarbPage;