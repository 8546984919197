import React, { useEffect } from "react";
import style from './style.module.css';
import IdarbSubmission from "../../components/IdarbSubmission";
import { useIdarb } from "../../context/IdarbContext";
import IdarbCases from "../../components/IdarbCases";
import IdarbCV from "../../components/IdarbCV";
import NotloggedIn from "../../components/NotLoggedIn";
import { useUser } from "../../context/UserContext";
import IdarbFlowManager from "../../components/IdarbFlowManager";

function Idarb() {
    const {idarb, saveIdarb} = useIdarb()
    const {isLogged, user} = useUser()
    const steps = [
       {
            id: 'submission',
            title: 'Submission',
            component: IdarbSubmission,
            redButtonLabel: 'Continue',
        }, 
        {
            id: 'distribution_cases',
            title: 'Cases',
            component: IdarbCases,
            redButtonLabel: 'Continue',
            whiteButtonLabel:'Go back',     
        },
        {
            id: 'cv_reccomandation',
            title: 'CV & Reccomandation',
            component: IdarbCV,
            redButtonLabel: 'Submit',
            whiteButtonLabel:'Go back',
        },
        ]

    useEffect(async () => {
        saveIdarb({
            ...idarb
        })
    }, [])

    useEffect(async () => {
        saveIdarb({
            ...idarb,
            user_id: user.id
        })
    }, [isLogged])

    
    return (
        <>
        <div className={`${style["idarb__container"]}`}>
            {isLogged ? <IdarbFlowManager stepArray={steps} defaultStep={0} /> : <NotloggedIn/>}
        </div>
        </>
    )
}

export default Idarb
