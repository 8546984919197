/**
* Use the CSS tab above to style your Element's container.
*/
import React from 'react';
import {IbanElement} from '@stripe/react-stripe-js';

// Custom styling can be passed as options when creating an Element.
const IBAN_STYLE = {
  base: {
    color: '#32325d',
    fontSize: '16px',
    '::placeholder': {
      color: '#6F6F6F'
    },
    ':-webkit-autofill': {
      color: '#6F6F6F',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
    ':-webkit-autofill': {
      color: '#fa755a',
    },
  }
};

const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ['SEPA'],
  // Elements can use a placeholder as an example IBAN that reflects
  // the IBAN format of your customer's country. If you know your
  // customer's country, we recommend that you pass it to the Element as the
  // placeholderCountry.
  placeholderCountry: 'IT',
  style: IBAN_STYLE,
};

export default function IbanForm({onChange}) {
  return (
    <form>
      <div className="form-row inline">
        <div className="col">
          <label className='iban__row'>
            Name
            <input
              className='iban__input'
              name="accountholder-name"
              placeholder="Jenny Rosen"
              required
              onChange={(e) => onChange('name', e.target.value)}
            />
          </label>
        </div>

        <div className="col">
          <label className='iban__row'>
            Email Address
            <input
              className='iban__input'
              name="email"
              type="email"
              placeholder="jenny.rosen@example.com"
              required
              onChange={(e) => onChange('email', e.target.value)}
            />
          </label>
        </div>
      </div>

      <div className="form-row">
        <label className='payment-type__label'>
          IBAN
          <IbanElement options={IBAN_ELEMENT_OPTIONS} />
        </label>
      </div>
      {/* Display mandate acceptance text. */}
      <div className="mandate-acceptance">
        By providing your payment information and confirming this payment,
        you authorise (A) Rocket Rides and Stripe, our payment service
        provider, to send instructions to your bank to debit your account and
        (B) your bank to debit your account in accordance with those
        instructions. As part of your rights, you are entitled to a refund
        from your bank under the terms and conditions of your agreement with
        your bank. A refund must be claimed within 8 weeks starting from the
        date on which your account was debited. Your rights are explained in
        a statement that you can obtain from your bank. You agree to receive
        notifications for future debits up to 2 days before they occur.
      </div>
    </form>
  );
};