import React, {useState} from "react"
import style from './style.module.css'
import defaultAvatar from '../../assets/default_avatar.svg';
import { uploadFile } from "../../utils/api";
import { toast, ToastContainer } from "react-toastify";



function ChangeAccountAvatar({currentImage, onChange}) {

    const [selectedImg, setSelectedImg] = useState(currentImage || defaultAvatar)


    const imageChange = async (e) => {
        const id = toast.loading("Image loading...")
        if (e.target.files !== undefined) {
            const fileSize = e.target.files[0].size / 1024 / 1024;
            if (fileSize > 2) return alert('File size exceeds 2 MB');

            var reader = new FileReader();
            reader.onload = async function () {
                var dataURL = reader.result;
                setSelectedImg(dataURL);
                const file = await uploadFile(e.target.files[0])
                onChange(file)
                toast.update(id, { render: "Image uploaded!", type: "success", isLoading: false, autoClose: 2000 });
            };
            reader.readAsDataURL(e.target.files[0]);

        } else {
            const response = await fetch(e.target.src);
            const data = await response.blob();
            var reader = new FileReader();
            reader.onload = async function () {
                var dataURL = reader.result;
                setSelectedImg(dataURL);
                const file = await uploadFile(dataURL)
                onChange(file)
            };
            reader.readAsDataURL(data);
        }
    }

    return (
        <>
        <div className={`${style["avatar__container"]}`}>
            <h5 className={`${style["avatar__title"]}`}>Picture</h5>
            <form className={`${style["avatar__image-form"]}`}>
                <img id="avatar_image" src={selectedImg} onError={(e) => (e.currentTarget.src = defaultAvatar)} className={`${style["avatar__photo"]}`} alt="avatar_img"></img>    
 
                <input
                    className={`${style["avatar__input-file"]}`}
                    type="file"
                    title = ""
                    onChange= {imageChange}
                    accept = ".png , .jpg"
                />
            </form>    
            <p>Image file format: png/jpg <br></br>
            Size: max 2mb</p>
        </div>
        </>
    )
}

export default ChangeAccountAvatar
