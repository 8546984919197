export async function createCustomer({email, name, surname}) {
    const customer = await fetch(`${process.env.REACT_APP_API_HOST}/api/stripe/create-customer`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          name,
          surname
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          return result.customer_id;
        });
    return customer
}
export async function createPaymentMethod({ card, customerId, billing_details, priceId, displayError, stripe }) {
    // Set up payment method for recurring usage
    stripe
      .createPaymentMethod({
        type: 'card',
        card: card,
        //billing_details,
      })
      .then((result) => {
        if (result.error) {
          displayError(result);
        } else {
           return {
            customerId: customerId,
            paymentMethodId: result.paymentMethod.id,
            priceId,
          }
        }
      });
}

export function createSubscription({ customer, freetrial, paymentMethodId, manual_transfer, priceId, paymentIntentId, amount, email, emails, showCardError, coupon }) {
    return (
      fetch(`${process.env.REACT_APP_API_HOST}/api/stripe/create-subscription`, {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          customer_id: customer,
          payment_method_id: paymentMethodId,
          price_id: priceId,
          payment_intent_id: paymentIntentId,
          coupon,
          amount,
          freetrial,
          manual_transfer,
          email,
          emails
        }),
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          console.log(result)
          if (result.status === "error") {
            // The card had an error when trying to attach it to a customer.
            return showCardError(result.message);
          }
          return result.subscription;
        })
        .catch((error) => {
          console.log(error)
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          return  showCardError(error);
        })
    );
  }