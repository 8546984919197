import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
  IbanElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import InputField from "../InputField";
import MembershipTitle from "../MembershipTitle";
import PageDescription from "../PageDescription";
import style from "./style.module.css";
import PaymentDetailButton from "../PaymentDetailButton";
import { createCustomer, createSubscription } from "../../utils/stripe";
import { useCart } from "../../context/CartContext";
import ThankYou from "../ThankYou";
import InputSelect from "../InputSelect";
import IbanForm from "./IbanForm";
import { toast, ToastContainer } from "react-toastify";
import {
  doRegistration,
  getCartPaymentMethod,
  getSepaPaymentMethod,
} from "../../utils/payment";
import { countries } from "../../utils/countries";
import { formatPrice } from "../../utils/constants";

const BillingValidationSchema = Yup.object().shape({
  isCompany: Yup.boolean(),
  invoice_type: Yup.string().required("Required"),
  company_name: Yup.string()
    .nullable()
    .when("invoice_type", {
      is: (invoice_type) =>
        ["company", "law firm", "association"].includes(invoice_type),
      then: Yup.string().required("Must enter company name"),
    }),
  address: Yup.string().required("Required"),
  postal_code: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  name: Yup.string()
    .nullable()
    .when("invoice_type", {
      is: (invoice_type) => ["individual", "lawyer"].includes(invoice_type),
      then: Yup.string().required("Must enter name"),
    }),
  surname: Yup.string()
    .nullable()
    .when("invoice_type", {
      is: (invoice_type) => ["individual", "lawyer"].includes(invoice_type),
      then: Yup.string().required("Must enter surname"),
    }),
  vies: Yup.string()
    .nullable()
    .when("territory", {
      is: "UE",
      then: Yup.string().required("Must enter VIES"),
    }),
  territory: Yup.string().required("Field is required"),
  recipient_code: Yup.string()
    .nullable()
    .when(["invoice_type", "territory"], {
      is: (invoice_type, territory) =>
        ["company", "lawyer", "law firm"].includes(invoice_type) &&
        territory === "Italy",
      then: Yup.string().required("Recipient Code / PEC must be filled"),
    }),
  fiscal_code: Yup.string()
    .nullable()
    .when("vies", {
      is: (vies) => vies === "false",
      then: Yup.string().required("Must enter Fiscal Code"),
    })
    .when(["invoice_type", "territory"], {
      is: (invoice_type, territory) =>
        ["company", "lawyer", "law firm"].includes(invoice_type) &&
        territory === "Italy",
      then: Yup.string().required("Must enter Fiscal Code"),
      otherwise: Yup.string()
        .nullable()
        .when(["vat", "territory"], {
          is: (vat, territory) =>
            territory !== "Extra UE" && (!vat || vat.length === 0),
          then: Yup.string().required("VAT or Fiscal Code must be filled"),
        }),
    }),
  vat: Yup.string()
    .nullable()
    .when("vies", {
      is: (vies) => vies === "true",
      then: Yup.string().required("Must enter VAT"),
    })
    .when(["invoice_type", "territory"], {
      is: (invoice_type, territory) =>
        ["company", "lawyer", "law firm"].includes(invoice_type) &&
        territory === "Italy",
      then: Yup.string().required("Must enter VAT"),
      otherwise: Yup.string()
        .nullable()
        .when(["fiscal_code", "territory"], {
          is: (fiscal_code, territory) =>
            territory !== "Extra UE" &&
            (!fiscal_code || fiscal_code.length === 0),
          then: Yup.string().required("VAT or Fiscal Code must be filled"),
        }),
    }),
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const title = "Payment Details";
const content = `EU VAT Information.

Please insert a valid VAT Number or insert a Fiscal Code if you don’t have a VAT. We will send you the relevant invoice as soon as we will receive the payment.`;

const freeTrialText = `By clicking Confirm you start a Free Trial`;

function PaymentScreen({
  shouldValidate,
  resetValidation,
  saveAndContinue,
  values,
  hideNavigation,
  hideSteps,
  setIsLoading,
}) {
  const [isCompany, setIsCompany] = useState(true);
  const [makePayment, setMakePayment] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [toastId, setToastId] = useState();
  const [paymentMethod, setPaymentMethod] = useState("card");
  const { cart, saveCart } = useCart();

  const [clientSecret, setClientSecret] = useState(null);
  const [paymentIntentId, setPaymentIntentId] = useState(null);

  useEffect(async () => {
    if (cart.mainUser) {
      // CREATE CUSTOMER
      const customer =
        cart.stripe_customer_id ||
        (await createCustomer({
          email: cart.mainUser.email,
          name: cart.mainUser.name,
          surname: cart.mainUser.surname,
        }));
      saveCart({ ...cart, stripe_customer_id: customer });

      if (!cart.freetrial) {
        // Create PaymentIntent as soon as the page loads
        fetch(
          `${process.env.REACT_APP_API_HOST}/api/stripe/create-payment-intent`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              type: cart.conferences ? "conference" : "",
              amount: cart.cart.amount * 100,
              customer,
              payment_method_types: ["card", "sepa_debit"],
              coupon: cart.cart.coupon && cart.cart.coupon,
            }),
          }
        )
          .then((res) => res.json())
          .then((data) => {
            setClientSecret(data.client_secret);
            setPaymentIntentId(data.payment_intent_id);
          });
      }
    }
  }, []);

  const appearance = {
    theme: "stripe",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      company_name: values && values.company_name ? values.company_name : "",
      vat: values && values.vat ? values.vat : "",
      fiscal_code: values && values.fiscal_code ? values.fiscal_code : "",
      country: values && values.country ? values.country : "",
      address: values && values.address ? values.address : "",
      city: values && values.city ? values.city : "",
      province: values && values.province ? values.province : "",
      postal_code: values && values.postal_code ? values.postal_code : "",
      territory: values && values.territory ? values.territory : "",
      invoice_type: values && values.invoice_type ? values.invoice_type : "",
      name: values && values.name ? values.name : "",
      surname: values && values.surname ? values.surname : "",
      recipient_code:
        values && values.recipient_code ? values.recipient_code : "",
      vies: values && values.vies ? values.vies : "",
    },
    validationSchema: !cart.freetrial ? BillingValidationSchema : null,

    onSubmit: async (values) => {
      const id = toast.loading("Please wait...");
      setToastId(id);
      saveAndContinue(values);
      // do registration here ONLY THE FIRST TIME
      if (!cart.isRegistered && !cart.freetrial) {
        const registrationResult = await doRegistration(
          cart.conferences ? true : false,
          { ...cart, billing_information: values }
        );
        if (
          registrationResult !== true &&
          registrationResult.status === "error"
        )
          return toast.update(id, {
            render: registrationResult.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
          });

        saveCart({ ...cart, billing_information: values, isRegistered: true });

        if (paymentIntentId) {
          // Update Payment Intent before making payment
          // Create PaymentIntent as soon as the page loads
          fetch(
            `${
              process.env.REACT_APP_API_HOST
            }/api/stripe/update-payment-intent`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                customer: cart.stripe_customer_id,
                amount: calculateTotal() * 100,
                payment_intent_id: paymentIntentId,
                type: cart.conferences ? "conference" : "",
                order_id: registrationResult.order_id
                  ? registrationResult.order_id
                  : null,
                coupon: cart.cart.coupon && cart.cart.coupon,
                manual_transfer:
                  paymentMethod === "manual_transfer" ? true : false,
                email: cart.mainUser && cart.mainUser.email,
              }),
            }
          )
            .then((res) => res.json())
            .then((data) => {
              setMakePayment(true);
            })
            .catch((err) => toast.error("Something went wrong"));
        } else {
          setMakePayment(true);
        }
      } else {
        setMakePayment(true);
      }
    },
  });

  const copyFromPersonalData = () => {
    setIsCompany(false);
    if (!cart.mainUser) return;
    formik.setValues({
      ...formik.values,
      company_name: `${cart.mainUser.name} ${cart.mainUser.surname}`,
      country: "",
      vat: "",
      address: "",
      city: "",
      postal_code: "",
    });
  };

  const copyFromCompanyData = () => {
    setIsCompany(true);
    if (!cart.company) return;
    formik.setValues({
      ...formik.values,
      company_name: cart.company.name,
      country: cart.company.country,
      vat: cart.company.vat,
      address: cart.company.address,
      city: cart.company.city,
      postal_code: cart.company.postal_code,
    });
  };

  const onPaymentConfirm = async () => {
    toast.update(toastId, {
      render: "All went good!",
      type: "success",
      isLoading: false,
      autoClose: 5000,
    });
    setShowThankYou(true);
    hideNavigation();
    hideSteps();
  };

  const doFreeTrialSubscription = async () => {
    const subscription = await createSubscription({
      customer: cart.stripe_customer_id,
      coupon: null,
      freetrial: true,
      amount: 0,
      email: cart.mainUser && cart.mainUser.email,
      emails:
            cart.additionalUsers && cart.additionalUsers.length > 0
              ? cart.additionalUsers.map((additionalUser) => additionalUser.email)
              : null,
      showCardError: (error) => {
        toast.update(toastId, {
          render: error,
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        setMakePayment(false);
      },
    });

    if (subscription) {
      if (!cart.isRegistered) {
        const registrationResult = await doRegistration(false, {
          ...cart,
          billing_information: null,
        });
        if (
          registrationResult !== true &&
          registrationResult.status === "error"
        )
          return toast.error(registrationResult);
        saveCart({ ...cart, isRegistered: true });
        onPaymentConfirm();
      }
    }
  };

  /*
  VAT Conferenze
  se vat_rule === 'italians' la pagano solo gli italiani (formik.values.country === "Italy")
  se vat_rule === 'all' la pagano tutti
  */
  const calculateVAT = () => {
    if (formik.values.territory === "Italy") {
      return (cart.cart.amount * 0.22).toFixed(2) * 1;
    }
    if (cart.conferences?.length > 0) {
      const activeConference = cart.conferences[0];
      if ((activeConference.vat_rule === "italians" && formik.values.territory === "Italy") || activeConference.vat_rule === "all") {
        return (cart.cart.amount * 0.22).toFixed(2) * 1;
      }
    }

    return 0;
    /*
    return formik.values.country === "Italy" || cart.conferences
      ? (cart.cart.amount * 0.22).toFixed(2) * 1
      : 0;*/
  };

  const calculateDiscount = () => {
    if (!cart.cart.discount) return;
    if (cart.cart.discount.amount_off)
      return `- ${formatPrice(cart.cart.discount.amount_off)} €`;
    if (cart.cart.discount.percent_off)
      return `- ${cart.cart.discount.percent_off} %`;
  };

  const calculateTotal = () => {
    const totalAmount = cart.cart.amount + calculateVAT();
    return totalAmount.toFixed(2) * 1;
  };

  useEffect(() => {
    if (shouldValidate)
      formik.validateForm().then((errors) => {
        if (Object.keys(errors).length > 0) resetValidation();
        else {
          resetValidation();
          formik.handleSubmit();
        }
      });
  }, [shouldValidate]);

  useEffect(() => {
    if (makePayment && cart.freetrial === "1") {
      doFreeTrialSubscription();
    }
  }, [makePayment]);

  return !showThankYou ? (
    <div className="membership__section">
      <div className={`${style["signup__form"]}`}>
        <MembershipTitle
          title={!cart.freetrial ? "Payment Details" : "Confirm"}
        />
        <PageDescription
          textContent={!cart.freetrial ? content : freeTrialText}
        />
        {!cart.freetrial && (
          <>
            <div className={`${style["detail-button__container"]}`}>
              {/* <div className={`${style["detail-button__margin"]}`}>
                  <PaymentDetailButton textButton={'I\'m buying as a Person'} onClick={copyFromPersonalData} />
                  </div>
                  <PaymentDetailButton textButton={'I\'m buying as a Company'} onClick={copyFromCompanyData} /> */}
            </div>
            <div className={`${style["full__width"]}`}>
              <p>Billing Information</p>
            </div>
            <InputSelect
              placeholderText="Territory"
              name={"territory"}
              onChange={formik.handleChange}
              values={[
                { value: "Italy", label: "Italy" },
                { value: "UE", label: "Other EU Country" },
                { value: "Extra UE", label: "Extra EU" },
              ]}
              selectedValue={formik.values.territory}
              errors={formik.errors.territory}
            />
            <form
              onSubmit={formik.handleSubmit}
              className={`${style["form__container"]}`}
            >
              <InputSelect
                placeholderText="Invoice Type"
                name={"invoice_type"}
                onChange={(event) =>
                  formik.setFieldValue("invoice_type", event.target.value)
                }
                values={[
                  { value: "individual", label: "Individual" },
                  { value: "company", label: "Company" },
                  { value: "law firm", label: "Law firm" },
                  { value: "lawyer", label: "Lawyer" },
                  { value: "association", label: "Association" },
                ]}
                selectedValue={formik.values.invoice_type}
                errors={formik.errors.invoice_type}
              />
              <InputField
                name="company_name"
                hasErrors={
                  formik.touched.company_name && formik.errors.company_name
                }
                handleChange={formik.handleChange}
                value={formik.values.company_name}
                placeholderText="Company Name"
                fieldType="text"
                errors={formik.errors.company_name}
              />
              <InputField
                name="name"
                hasErrors={formik.touched.name && formik.errors.name}
                handleChange={formik.handleChange}
                value={formik.values.name}
                placeholderText="Name"
                fieldType="text"
                errors={formik.errors.name}
              />
              <InputField
                name="surname"
                hasErrors={formik.touched.surname && formik.errors.surname}
                handleChange={formik.handleChange}
                value={formik.values.surname}
                placeholderText="Surname"
                fieldType="text"
                errors={formik.errors.surname}
              />
              <InputField
                name="address"
                hasErrors={formik.touched.address && formik.errors.address}
                handleChange={formik.handleChange}
                value={formik.values.address}
                placeholderText="Address"
                fieldType="text"
                errors={formik.errors.address}
              />
              <InputField
                name="city"
                hasErrors={formik.touched.city && formik.errors.city}
                handleChange={formik.handleChange}
                value={formik.values.city}
                placeholderText="City"
                fieldType="text"
                errors={formik.errors.city}
              />
              <InputField
                name="postal_code"
                hasErrors={
                  formik.touched.postal_code && formik.errors.postal_code
                }
                handleChange={formik.handleChange}
                value={formik.values.postal_code}
                placeholderText="Postal Code"
                fieldType="text"
                errors={formik.errors.postal_code}
              />
              <InputSelect
                placeholderText="Country"
                name={"country"}
                onChange={(event) =>
                  formik.setFieldValue("country", event.target.value)
                }
                values={countries.map((country) => {
                  return country;
                })}
                selectedValue={formik.values.country}
                errors={formik.errors.country}
              />
              {formik.values.territory === "Italy" && (
                <>
                  <InputField
                    name="vat"
                    hasErrors={formik.touched.vat && formik.errors.vat}
                    handleChange={formik.handleChange}
                    value={formik.values.vat}
                    placeholderText={
                      ["company", "lawyer", "law firm"].includes(
                        formik.values.invoice_type
                      )
                        ? "VAT"
                        : "VAT Number and/or Fiscal Code"
                    }
                    fieldType="text"
                    errors={formik.errors.vat}
                  />
                  {["company", "lawyer", "law firm"].includes(
                    formik.values.invoice_type
                  ) && (
                    <InputField
                      name="fiscal_code"
                      hasErrors={
                        formik.touched.fiscal_code && formik.errors.fiscal_code
                      }
                      handleChange={formik.handleChange}
                      value={formik.values.fiscal_code}
                      placeholderText={
                        formik.values.vies === true
                          ? "Fiscal Code"
                          : "Fiscal Code"
                      }
                      fieldType="text"
                      errors={formik.errors.fiscal_code}
                    />
                  )}
                  <InputField
                    name="recipient_code"
                    hasErrors={
                      formik.touched.recipient_code &&
                      formik.errors.recipient_code
                    }
                    handleChange={formik.handleChange}
                    value={formik.values.recipient_code}
                    placeholderText="SDI Code"
                    fieldType="text"
                    errors={formik.errors.recipient_code}
                  />
                </>
              )}
              {formik.values.territory === "UE" && (
                <>
                  <div className={`${style["full__width"]}`}>
                    <InputSelect
                      name={"vies"}
                      onChange={(event) =>
                        formik.setFieldValue(
                          "vies",
                          event.target.value === "true" ? true : false
                        )
                      }
                      values={[
                        { value: true, label: "Registered with the VIES" },
                        { value: false, label: "Not Registred with the VIES" },
                      ]}
                      placeholderText="Registration with the VIES"
                      selectedValue={formik.values.vies}
                      errors={formik.errors.vies}
                    />
                  </div>
                  {formik.values.vies === true && (
                    <InputField
                      name="vat"
                      hasErrors={formik.touched.vat && formik.errors.vat}
                      handleChange={formik.handleChange}
                      value={formik.values.vat}
                      placeholderText="VAT Number"
                      fieldType="text"
                      errors={formik.errors.vat}
                    />
                  )}
                  {formik.values.vies === false && (
                    <InputField
                      name="fiscal_code"
                      hasErrors={
                        formik.touched.fiscal_code && formik.errors.fiscal_code
                      }
                      handleChange={formik.handleChange}
                      value={formik.values.fiscal_code}
                      placeholderText={
                        formik.values.vies === true
                          ? "VAT number or Fiscal Code"
                          : "VAT number or Fiscal Code"
                      }
                      fieldType="text"
                      errors={formik.errors.fiscal_code}
                    />
                  )}
                </>
              )}
            </form>
            {clientSecret && !cart.freetrial && (
              <Elements
                options={{
                  clientSecret,
                  appearance,
                }}
                stripe={stripePromise}
              >
                <CheckoutForm
                  onConfirm={onPaymentConfirm}
                  email={cart.mainUser ? cart.mainUser.email : null}
                  clientSecret={clientSecret}
                  paymentIntentId={paymentIntentId}
                  conference={cart.conferences ? true : false}
                  makePayment={makePayment}
                  resetPayment={() => {
                    toast.update(toastId, {
                      render:
                        "Something went wrong with your payment. Please check the data you typed.",
                      type: "error",
                      isLoading: false,
                      autoClose: 5000,
                    });
                    setMakePayment(false);
                  }}
                  setIsLoading={setIsLoading}
                  calculateTotal={calculateTotal}
                  onChangePaymentMethod={setPaymentMethod}
                />
              </Elements>
            )}
            {!cart.freetrial && (
              <div
                className={`${style["shopping-cart-flex"]} ${style["reverse"]}`}
              >
                <div className={`${style["total-box"]}`}>
                  <div className={`${style["shopping-cart-flex"]}`}>
                    <span
                      className={`${style["grey-title"]} ${
                        style["total-title"]
                      }`}
                    >
                      Subtotal
                    </span>
                    <span className={`${style["total-value"]}`}>
                      {cart.cart.subtotal} &euro;
                    </span>
                  </div>
                  <div className={`${style["shopping-cart-flex"]}`}>
                    <span
                      className={`${style["grey-title"]} ${
                        style["total-title"]
                      }`}
                    >
                      Discount
                    </span>
                    <span className={`${style["total-value"]}`}>
                      {calculateDiscount()}
                    </span>
                  </div>
                  <div className={`${style["shopping-cart-flex"]}`}>
                    <span
                      className={`${style["grey-title"]} ${
                        style["total-title"]
                      }`}
                    >
                      VAT
                    </span>
                    <span className={`${style["total-value"]}`}>
                      {calculateVAT()} €
                    </span>
                  </div>
                  <div className={`${style["shopping-cart-flex"]}`}>
                    <span
                      className={`${style["grey-title"]} ${
                        style["total-title"]
                      } ${style["black"]}`}
                    >
                      TOTAL
                    </span>
                    <span className={`${style["total-value-big"]}`}>
                      {calculateTotal()} €
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <ToastContainer />
    </div>
  ) : (
    <ThankYou
      title={
        cart.hasOwnProperty("conferences")
          ? "Thank You"
          : !cart.freetrial
          ? "Membership Completed"
          : "Free Trial Completed"
      }
      content={
        cart.hasOwnProperty("conferences") ? (
          cart.hasOwnProperty("streaming") ? (
            ''
            // <div>
            //   You will receive an email with all the relevant instructions on
            //   how to join the event in streaming some days before the conference
            // </div>
          ) : (
            <div>
              Thank you for having registered to the IDI Annual conference
              <br /> You will receive the main information about the event in
              due course.
            </div>
          )
        ) : (
          "Please check your mail box and click on the link for activating your account"
        )
      }
      conference={cart.hasOwnProperty("conferences")}
    />
  );
}

function CheckoutForm({
  onConfirm,
  clientSecret,
  makePayment,
  resetPayment,
  conference,
  setIsLoading,
  calculateTotal,
  onChangePaymentMethod,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [method, setMethod] = useState("card");
  const [sepaDetails, setSepaDetails] = useState({});
  const [message, setMessage] = useState(null);
  const { cart } = useCart();

  useEffect(() => {
    if (!stripe) {
      return;
    }
    if (!cart) {
      return;
    }

    if (!clientSecret) {
      return;
    }
  }, [stripe]);

  useEffect(() => {
    if (makePayment) handleSubmit();
  }, [makePayment]);

  const onSepaChange = (field, value) => {
    setSepaDetails({ ...sepaDetails, [field]: value });
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const paymentMethod =
      method === "manual_transfer"
        ? {}
        : method === "card"
        ? await getCartPaymentMethod(
            elements,
            stripe,
            CardElement,
            resetPayment
          )
        : await getSepaPaymentMethod(
            elements,
            stripe,
            IbanElement,
            resetPayment,
            sepaDetails
          );
    if (paymentMethod) doPayment(paymentMethod, stripe);

    setIsLoading(false);
  };

  const doPayment = async (paymentMethod) => {
    if (conference) {
      /** MANUAL TRANSFER METHOD */
      if (method === "manual_transfer") return onConfirm({ method });
      /** END OF MANUAL TRANSFER METHOD */
      const paymentFunction =
        method === "card"
          ? stripe.confirmCardPayment
          : stripe.confirmSepaDebitPayment;
      await paymentFunction(clientSecret, {
        payment_method: paymentMethod.id,
        setup_future_usage: "off_session",
      }).then(function(result) {
        if (result.error) {
          // Show error to your customer
          toast.error("Something went wrong");
          resetPayment();
        } else {
          if (
            result.paymentIntent.status === "succeeded" ||
            result.paymentIntent.status === "processing"
          ) {
            onConfirm();
          }
        }
      });
    } else {
      /** MANUAL TRANSFER METHOD */
      if (method === "manual_transfer") {
        const subscription = await createSubscription({
          customer: cart.stripe_customer_id,
          manual_transfer: true,
          freetrial: cart.freetrial,
          coupon: cart.cart.coupon && cart.cart.coupon,
          paymentMethodId: paymentMethod.id,
          priceId: process.env.REACT_APP_MEMBERSHIP_PRICE_ID,
          email: cart.mainUser && cart.mainUser.email,
          emails: cart.additionalUsers && cart.additionalUsers.length > 0 ? cart.additionalUsers.map((additionalUser) => additionalUser.email) : null,
          amount: calculateTotal() * 100,
          showCardError: (error) => {
            toast.error(error);
            resetPayment();
          },
        });
        if (subscription)
          return onConfirm({ method, subscription: subscription.id });
        else return false;
      }
      /** END OF MANUAL TRANSFER METHOD */
      const paymentFunction =
        method === "card"
          ? stripe.confirmCardSetup
          : stripe.confirmSepaDebitSetup;
      await paymentFunction(clientSecret, {
        payment_method: paymentMethod.id,
      }).then(async function(result) {
        if (result.error) {
          // Show error to your customer

          toast.error(result.error.message);
          resetPayment();
        } else {
          if (
            result.setupIntent.status === "succeeded" ||
            result.setupIntent.status === "processing"
          ) {
            const subscription = await createSubscription({
              customer: cart.stripe_customer_id,
              freetrial: cart.freetrial,
              coupon: cart.cart.coupon && cart.cart.coupon,
              paymentMethodId: paymentMethod.id,
              priceId: process.env.REACT_APP_MEMBERSHIP_PRICE_ID,
              email: cart.mainUser && cart.mainUser.email,
              emails: cart.additionalUsers && cart.additionalUsers.length > 0 ? cart.additionalUsers.map((additionalUser) => additionalUser.email) : null,
              amount: calculateTotal() * 100,
              showCardError: (error) => {
                toast.error(error);
                resetPayment();
              },
            });
            if (subscription) onConfirm({ subscription: subscription.id });
            else {
              resetPayment();
            }
          }
        }
      });
    }
  };

  return (
    <>
      <div className={`${style["detail-button__container"]}`}>
        <a
          className={`${style["detail__button"]} ${
            method === "card" ? "active" : ""
          }`}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setMethod("card");
            onChangePaymentMethod("card");
          }}
        >
          Card Payment
        </a>

        <a
          className={`${style["detail__button"]} ${
            method === "manual_transfer" ? "active" : ""
          }`}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setMethod("manual_transfer");
            onChangePaymentMethod("manual_transfer");
          }}
        >
          Bank Transfer
        </a>
        <a
          className={`${style["detail__button"]} ${
            method === "sepa_debit" ? "active" : ""
          }`}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setMethod("sepa_debit");
            onChangePaymentMethod("manual_transfer");
          }}
        >
          SEPA Debit
        </a>
      </div>

      {method === "card" && (
        <form id="payment-form" onSubmit={handleSubmit}>
          <CardElement options={{ hidePostalCode: true }}/>

          {/* Show any error or success messages */}
          {message && <div id="payment-message">{message}</div>}
        </form>
      )}
      {method === "sepa_debit" && <IbanForm onChange={onSepaChange} />}
      {method === "manual_transfer" && (
        <div className={`${style["bank-transfer__text"]}`}>
          You are choosing <strong>Bank transfer</strong> as a Payment Method.
          <br />
          We’ll email you the payment instructions once you complete the order.{" "}
          <br />
          If you won’t receive an email within 24 hours, please contact us at{" "}
          <a
            className={`${style["bank-transfer__link"]}`}
            href="mailto:info@idiproject.com"
          >
            info@idiproject.com
          </a>
          .<br />
          <br />
          Click Pay now to proceed.
        </div>
      )}
    </>
  );
}

export default PaymentScreen;
