import style from './style.module.css';
import MemberCardInfo from "../MemberCardInfo";
import MemberCardText from "../MemberCardText";
import MemberCardIcons from "../MemberCardIcons";

export default function MemberCard({values}){
    return(
        <div className={`${style['member__card']}`}>
            <MemberCardInfo values={values}/>
            {(values.phone || values.email || values.website) &&  <MemberCardIcons values={values}/>}
        </div>
    )
}