import React, { useEffect } from "react";
import { useFormik } from 'formik';
import * as Yup from "yup";
import InputField from '../InputField';
import MembershipTitle from '../MembershipTitle';
import PageDescription from '../PageDescription';
import style from './style.module.css';

const schema = Yup.object().shape({
    /* type: Yup.string().required("Required"), */
    cases: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string(),
        description: Yup.string(),
      }).test({
        name: 'one-true',
        message: 'You must fill at least 5 cases',
        test: (val, context) => {
            return context.options.index >=5 || (context.options.index <=4 && val.title && val.description)
        },
      })
    )
})

const basicCase = {
    title: '',
    description: '',
  }

const defaultState = {
    cases: [basicCase,basicCase,basicCase,basicCase,basicCase]
  }

function IdarbCases({shouldValidate, resetValidation, saveAndContinue, values }) {
    const title = 'Distribution Cases';
    const content = `The prospective arbitrator must submit the <strong>description of a number of distribution cases</strong> (minimum 5 - maximum 10) he/she has been involved in as litigator, arbitrator or in house counsel. The selecting committee believes that this type of information constitutes an <strong>essential characteristic of the project</strong> which is meant to give users a tool for gathering a better understanding the actual experience of the prospective arbitrator.<br>
    The cases described by the prospective arbitrator may concern experience gained in litigation (as arbitrator or counsel in arbitration or in court litigation), but they may also describe the experience made in negotiating agreements or preventing litigation through negotiation, settlement agreements, etc..<br>
    What is important for those who consult the list is to have a clear understanding about the actual experience in distribution of the prospective arbitrator in order to make sure that he is familiar with the issues that may be raised in the litigation.<br>
    <strong>The cases must be submitted in the format recommended by IDArb</strong>.`;

    const formik = useFormik({
        initialValues: values || defaultState,
        validationSchema: schema,
        onSubmit:(values) => { 
            saveAndContinue(values)
        }
      });

      const addCase = (event) => {
        event.preventDefault();
        const cases = [...formik.values.cases, basicCase]
        formik.setFieldValue('cases', cases)
      }

    useEffect(() => {
        if (shouldValidate) formik.validateForm().then(errors => {
            if (Object.keys(errors).length > 0) resetValidation()
            else formik.handleSubmit()
        })
    }, [shouldValidate])

    return (
        <div className= "membership__section">
            <div className={`${style["signup__form"]}`}>
                <MembershipTitle  title={title} />
                <PageDescription  textContent={content}/>
                <p className={`${style["grey__subtitle-top"]}`}>Distribution</p>
                <form onSubmit={formik.handleSubmit}>
                {formik.values.cases.map((row, index) => <div>
                    <p className={`${style["case__subtitle"]}`}>Case {index+1}</p>
                    <InputField 
                        variableWidth="subscribeWidth"
                        name={`cases[${index}].title`} 
                        hasErrors={formik.errors.cases && formik.errors.cases[index] && formik.errors.cases[index]} 
                        handleChange={formik.handleChange} 
                        value={row.title} 
                        placeholderText="Title*" 
                        fieldType="text"
                        errors={formik.errors.cases && formik.errors.cases[index] && formik.errors.cases[index]} /> 
                    <InputField 
                        variableWidth="subscribeWidth"
                        variableHeight="caseHeight"
                        name={`cases[${index}].description`} 
                        hasErrors={formik.errors.cases && formik.errors.cases[index] && formik.errors.cases[index]} 
                        handleChange={formik.handleChange} 
                        value={row.description} 
                        placeholderText="Description*" 
                        fieldType="textarea"
                        errors={formik.errors.cases && formik.errors.cases[index] && formik.errors.cases[index]} /> 
                </div>)}
                <button className={`${style["more-cases__button"]}`} onClick={addCase}>+ Add More Cases</button>
                </form>
            </div>
        </div>
    )
}

export default IdarbCases
