import style from './style.module.css';
import { getImage, goToUrl, goToUrlNewTab } from "../../utils/constants";



export default function ConferenceDetails({image, title, description}){
    return(
        <>
        <div className={`${style['conference-details-flex']}`}>
            <img className={`${style['conference-image']}`} src={getImage(image)} alt="Conference Image"/>
            <div className={`${style['conference-details-text-box']}`}>
                <h5 className={`${style['conference-details-name']}`}>{title}</h5>
                <p className={`${style['conference-details-description']}`}>{description}</p>
                <p className={`${style['conference-red-link']}`} target="_blank" onClick={(e) => goToUrlNewTab('conferences-and-seminars',(e))}>See the event</p>
            </div>
        </div>
        </>
       
    )
}