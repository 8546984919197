import React from 'react'
import AddedUser from '../AddedUser';
import MembershipTitle from '../MembershipTitle';
import PageDescription from '../PageDescription';
import RedButton from '../RedButton';
import WhiteButton from '../WhiteButton';
import style from './style.module.css';
import Signup from '../SignUp';
import { useCart } from '../../context/CartContext';

function AddUser({showNavigation, hideNavigation, shouldValidate, saveAndContinue, values}) {
    const [shouldValidateUser, setShouldValidateUser] = React.useState(false)
    const [showAddUserForm, setShowAddUserForm] = React.useState(false)
    const [users, setUsers] = React.useState([])
    const [additionalUsers, setAdditionalUsers] = React.useState([])
    const {cart, saveCart} = useCart()

    const title =  () => showAddUserForm ?  'Add Person' : 'Select Person or Add new ones';
    const content = 'Please, add one or more additional members by filling-in the following boxes';

    const saveUser = (values) => {
        setUsers(previousState => [...previousState, {...values, additionalUser: true, manuallyInserted:true, freetrial: cart.freetrial ? true : false }])
        saveCart({...cart, companyUsers: [...cart.companyUsers, {...values, additionalUser: true, manuallyInserted:true, freetrial: cart.freetrial ? true : false}]})
        setShowAddUserForm(false)
        setShouldValidateUser(false)
        showNavigation()
    }

    const deleteUser = (user) => {
        //console.log("deleteUser");
        let newAdditionalUsers;
        const findUser = additionalUsers.find(currentUser => JSON.stringify(currentUser) === JSON.stringify(user));
        if (findUser !== undefined) {
            newAdditionalUsers = additionalUsers.filter(currentUser => JSON.stringify(currentUser) !== JSON.stringify(user));
            setAdditionalUsers(newAdditionalUsers)
        }
        const newUsers = users.filter(currentUser => JSON.stringify(currentUser) !== JSON.stringify(user));
        const newCompanyUsers = cart.companyUsers.filter(currentUser => JSON.stringify(currentUser) !== JSON.stringify(user));
        setUsers(newUsers)
        // TODO: dovrebbe svuotare additionalUsers ?
        // Perchè viene svuotato companyUsers? 
        // testare su caso in cui ci sono additional inseriti manualmente e non
        saveCart({...cart, additionalUsers: newAdditionalUsers, companyUsers: newCompanyUsers})
    }

    const toggleUser = (user) => {
        if (additionalUsers.find(currentUser => JSON.stringify(currentUser) === JSON.stringify(user))) setAdditionalUsers([...additionalUsers.filter(currentUser => JSON.stringify(currentUser) !== JSON.stringify(user))]) 
        else setAdditionalUsers([...additionalUsers, user]) 
    }

    const resetValidation = () => setShouldValidateUser(false)

    React.useEffect(() => {
        if (shouldValidate) saveAndContinue(additionalUsers)
    }, [shouldValidate])

    React.useEffect(() => {
        // effect riempie di nuovo additionalUsers perchè nel deleteUser non viene svuotato
        cart.additionalUsers && cart.additionalUsers.length > 0 && setAdditionalUsers(cart.additionalUsers)
        cart.companyUsers && cart.companyUsers.length > 0 && setUsers(cart.companyUsers.map(companyUser => { return {...companyUser, freetrial: cart.freetrial ? true : false}}))
    }, [cart])
    
    return (
        <>
                {!showAddUserForm ? 
                    <div className='element__container element__margin'>
                        <MembershipTitle title={title()} />
                        <PageDescription textContent={content}/>

                        <div className={`${style['scrollable']}`}>
                            {users.length != 0 && <div className={`${style['user__table-flex']}`}>
                                <div className={`${style['user__table']}`}>Name</div>
                                <div className={`${style['user__table']}`}>Email</div>
                                <div className={`${style['user__table']}`}>Expiration date</div>
                            </div>}
                            
                            {users.map ((user, index) =>
                            <div key={index} className={`${style['user__table-flex']}`}>
                                
                                <AddedUser userName={`${user.name} ${user.surname}`} userMail={user.email} userDate={user.date_end ? user.date_end : ''} manuallyInserted={user.manuallyInserted ? true : false} onDelete={() => deleteUser(user)} />
                                <input className={`${style['input__table']}`} type="checkbox" onChange={() => toggleUser(user)} checked={ additionalUsers.find(currentUser => JSON.stringify(currentUser) === JSON.stringify(user)) ? true : false}/>
                                 

                            </div>)}
                        </div>  
                        <WhiteButton textButton={'Add new Person'} onClick={() => 
                            {
                                setShowAddUserForm(true)
                                hideNavigation()
                            }} />
                    </div> :
                    <div>
                        <Signup 
                            title="Add Person" 
                            description="Add a new person from your Company" 
                            saveAndContinue={saveUser} 
                            resetValidation = {resetValidation} 
                            shouldValidate={shouldValidateUser} />
                        <div className={`${style["buttons_container"]}`} style={{justifyContent:'space-between'}}>
                            <WhiteButton textButton={'Back'} onClick={() => {
                                showNavigation()
                                setShowAddUserForm(false)
                            }} />
                            <RedButton textButton={'Add Person'} onClick={() => setShouldValidateUser(true)} />
                        </div>
                    </div>
                }
        </>
    )
}

export default AddUser
