import React from 'react'

export default function Pagination({items, itemsPerPage, currentPage, setCurrentPage}) {
  
  if (items.length <= itemsPerPage) return null;

  return (
    <div className="documents__pagination">
        {Array.from({length: Math.ceil(items.length / itemsPerPage)}, (_, i) => i + 1 ).map(page => 
            <div key={page} onClick={() => setCurrentPage(page - 1)} className={currentPage === page -1 ? 'active' : 'inactive'}>{page}</div>
        )}
    </div>
  )
}
