import React, { useEffect } from "react";
import { useFormik } from 'formik';
import * as Yup from "yup";
import InputField from '../InputField';
import MembershipTitle from '../MembershipTitle';
import PageDescription from '../PageDescription';
import style from './style.module.css';
import InputSelect from "../InputSelect";
import { countries } from "../../utils/countries";
import CheckboxField from "../CheckboxField";

const schema = Yup.object().shape({
     year_of_birth: Yup.number().required("Required"), 
     languages: Yup.string().required("Required"), 
     country: Yup.string().required("Required"),
})

function IdarbSubmission({shouldValidate, resetValidation, saveAndContinue, values }) {
    const title = 'Fill in the submission form';
    const content = `<strong>Submission form</strong><br>
    The submission form is very simple. All the questions must be answered by yes or no, except for the law firm or company (if any) of the prospective arbitrator and the e-mail address.<br>
    All further details will be included by the prospective arbitrator in the detailed profile/CV.<br>
    <strong>Experience in distribution</strong>. As regards the box “Experience in the field distribution”, we propose to use the terms of the following list in order to warrant the highest possible uniformity:<br>
    • Commercial agency<br>
    • Intermediaries<br>
    • Distributorship<br>
    • Sales networks<br>
    • Selective distribution<br>
    • Franchising<br>
    • Distribution antitrust<br>
    • Trademark issues<br>
    • Department stores<br>
    • Distribution joint ventures<br><br>
    
    <strong>The terms used in this box must reflect the contents of the cases submitted by the candidate.</strong><br>
    <strong>Litigation and arbitration experience</strong>. IDArb does not require an actual experience as arbitrator, since the actual experience in distribution (e.g. as in-house-counsel) may in certain cases be more important, but it is essential that the type of experience in litigation is clearly evidenced in the arbitrator’s profile. Thus a person without any experience in litigation, might in certain cases be the appropriate choice for the role of party arbitrator, in consideration of his specific experience in distribution matters.<br>
    <strong>Experience as in-house counsel</strong>. We think that this may be of great importance because this type of experience may warrant a special understanding of the underl¬ying economic and managerial situation.`;

    const formik = useFormik({
        initialValues: { 
            year_of_birth: values && values.hasOwnProperty('year_of_birth') ? values.year_of_birth : '', 
            languages: values && values.hasOwnProperty('languages') ? values.languages : '', 
            country: values && values.hasOwnProperty('country') ? values.country : '', 
            professional_qualification: values && values.hasOwnProperty('professional_qualification') ? values.professional_qualification : [], 
            experience_field_distribution: values && values.hasOwnProperty('experience_field_distribution') ? values.experience_field_distribution : [], 
            litigation_experience: values && values.hasOwnProperty('litigation_experience') ? values.litigation_experience : [], 
            arbitration_experience: values && values.hasOwnProperty('arbitration_experience') ? values.arbitration_experience : [], 
            experience_in_house: values && values.hasOwnProperty('experience_in_house') ? values.experience_in_house : [], 
        },
        validationSchema: schema,
        onSubmit:(values) => { 
            saveAndContinue(values)
        }
      });

    useEffect(() => {
        if (shouldValidate) formik.validateForm().then(errors => {
            if (Object.keys(errors).length > 0) resetValidation()
            else formik.handleSubmit()
        })
    }, [shouldValidate])

    return (
        <div className= "membership__section">
            <div className={`${style["signup__form"]}`}>
                <MembershipTitle  title={title} />
                <PageDescription  textContent={content}/>
                <form onSubmit={formik.handleSubmit} className={`${style["form__container"]}`}>
                    <p className={`${style["grey__subtitle"]}`}>Personal Data</p> 
                    <div className={`${style["form__container"]}`}>
                        <InputSelect 
                            placeholderText="Nationality*"
                            name={"country"}
                            onChange={(event) => formik.setFieldValue('country', event.target.value)}
                            values={countries.map(country => {
                                return country;
                            })}
                            selectedValue={formik.values.country}
                            errors={formik.errors.country}
                            />
                        <InputField 
                            name="languages" 
                            hasErrors={formik.touched.languages && formik.errors.languages} 
                            handleChange={formik.handleChange} 
                            value={formik.values.languages} 
                            placeholderText="Languages" 
                            fieldType="text"
                            errors={formik.errors.languages} /> 
                        <InputField 
                            name="year_of_birth" 
                            hasErrors={formik.touched.year_of_birth && formik.errors.year_of_birth} 
                            handleChange={formik.handleChange} 
                            value={formik.values.year_of_birth} 
                            placeholderText="Year of Birth" 
                            fieldType="text"
                            errors={formik.errors.year_of_birth} />
                    </div> 
                    <div>
                        <p className={`${style["grey__subtitle-top"]}`}>Professional Qualifications</p>
                        <div className={`${style["form__container"]}`}>
                        {['Law Degree', 'Admitted to Bar'].map((item,index) => <CheckboxField
                            hasErrors={formik.touched.professional_qualification && formik.errors.professional_qualification}
                            checkboxText={item} 
                            key={index}
                            name={`professional_qualification[${index}]`} 
                            handleChange={(e) => {
                                e.target.checked ? formik.setFieldValue(`professional_qualification`, [...formik.values.professional_qualification, item]) : formik.setFieldValue(`professional_qualification`, formik.values.professional_qualification.filter( el => el !== item))
                            }} 
                            value={formik.values.professional_qualification.find(el => el === item)}
                            errors={formik.errors.professional_qualification}/>)}
                        </div>
                    </div>
                    <div>
                        <p className={`${style["grey__subtitle-top"]}`}>Experience in the field of distribution</p>
                        <div className={`${style["form__container"]}`}>
                        {['Commercial Agency','Franchising', 'Intermediaries', 'Distribution Antitrust', 'Distributorship', 
                        'Trademark issues','Sale Networks','Department stores', 'Selective distribution','Distribution joint ventures'].map((item,index) => <CheckboxField
                            hasErrors={formik.touched.experience_field_distribution && formik.errors.experience_field_distribution}
                            checkboxText={item} 
                            key={index}
                            name={`experience_field_distribution[${index}]`} 
                            handleChange={(e) => {
                                e.target.checked ? formik.setFieldValue(`experience_field_distribution`, [...formik.values.experience_field_distribution, item]) : formik.setFieldValue(`experience_field_distribution`, formik.values.experience_field_distribution.filter( el => el !== item))
                            }} 
                            value={formik.values.experience_field_distribution.find(el => el === item)}
                            errors={formik.errors.experience_field_distribution}/>)}
                        </div>
                    </div>
                    <div>
                        <p className={`${style["grey__subtitle-top"]}`}>Litigation</p>
                        <div className={`${style["form__container"]}`}>
                        {['Litigation','As in house counsel assisting external counsel'].map((item,index) => <CheckboxField
                            hasErrors={formik.touched.litigation_experience && formik.errors.litigation_experience}
                            checkboxText={item} 
                            key={index}
                            name={`litigation_experience[${index}]`} 
                            handleChange={(e) => {
                                e.target.checked ? formik.setFieldValue(`litigation_experience`, [...formik.values.litigation_experience, item]) : formik.setFieldValue(`litigation_experience`, formik.values.litigation_experience.filter( el => el !== item))
                            }} 
                            value={formik.values.litigation_experience.find(el => el === item)}
                            errors={formik.errors.litigation_experience}/>)}
                        </div>
                    </div>
                    <div>
                        <p className={`${style["grey__subtitle-top"]}`}> Arbitration experience</p>
                        <div className={`${style["form__container"]}`}>
                        {['As arbitrator','As counsel','As in house counsel assisting external counsel'].map((item,index) => <CheckboxField
                            hasErrors={formik.touched.arbitration_experience && formik.errors.arbitration_experience}
                            checkboxText={item} 
                            key={index}
                            name={`arbitration_experience[${index}]`} 
                            handleChange={(e) => {
                                e.target.checked ? formik.setFieldValue(`arbitration_experience`, [...formik.values.arbitration_experience, item]) : formik.setFieldValue(`arbitration_experience`, formik.values.arbitration_experience.filter( el => el !== item))
                            }} 
                            value={formik.values.arbitration_experience.find(el => el === item)}
                            errors={formik.errors.arbitration_experience}/>)}
                        </div>
                    </div>
                    <div>
                        <p className={`${style["grey__subtitle-top"]}`}> Experience as in house counsel</p>
                        <div className={`${style["form__container"]}`}>
                        {['Experience as in house counsel'].map((item,index) => <CheckboxField
                            hasErrors={formik.touched.experience_in_house && formik.errors.experience_in_house}
                            checkboxText={item} 
                            key={index}
                            name={`experience_in_house[${index}]`} 
                            handleChange={(e) => {
                                e.target.checked ? formik.setFieldValue(`experience_in_house`, [...formik.values.experience_in_house, item]) : formik.setFieldValue(`experience_in_house`, formik.values.experience_in_house.filter( el => el !== item))
                            }} 
                            value={formik.values.experience_in_house.find(el => el === item)}
                            errors={formik.errors.experience_in_house}/>)}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default IdarbSubmission
