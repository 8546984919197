import React from 'react'
import style from './style.module.css'
import clear from '../../assets/clear.svg'
import { toast } from 'react-toastify'

export default function SearchInput({placeholder, value, onChange, name, readOnly}) {
  return (
    <div className={`${style["input-wrapper"]}`} >
        <input className={`${style["text-input"]} ${value && style["with-value"]}`} type="text" readOnly={readOnly} value={value} onChange={onChange} placeholder={placeholder} name={name} />
        {value && <img src={clear} onClick={readOnly ? () => toast.error("Login to use this feature") : () => onChange({target: {value: '', name}})} />}
    </div>
  )
}
