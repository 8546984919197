import style from './style.module.css'

function DocumentLanguageSelect({languages, onChange}) {
    const mappingArray = Object.keys(languages).map(key => {
        return {
            key: languages[key].language_name,
            value: key
        }
    })
    // A partire dall'array ordinato di lingue mi creo un map (un oggetto con coppie chiave-valore) tra Lingua e chiave (ad es. Arabic => AR)
    // const mapping = {};
    // for (let i = 0; i < mappingArray.length; i++) {
    //     const item = mappingArray[i];
    //     const { key, value } = item;
    //     mapping[key] = value;
    // }
    const mapping = Object.fromEntries(mappingArray.map(item => [item.key, item.value]))

    // Creo un array con le lingue e lo ordino alfabeticamente
    const languagesNames = Object.keys(languages).map(key => {
        return languages[key].language_name
    }).sort();

    console.log(mapping)

    return (
        languages && <div className={`${style["select_container-documents"]}`}>
            <select className={`${style["select_document"]}`} name="languages" onChange={(event) => onChange(event.target.value)}>
                <option value=" " disable="true" hidden>English</option>
                {languagesNames.map(language => 
                <option key={mapping[language]} value={mapping[language]}>{language}</option>
                )}
            </select>
       </div>
    )
}

export default DocumentLanguageSelect
