import style from './style.module.css'
import crown from '../../assets/crown.svg'

function Avatar({imgSrc, imgSize, showCrown = false}) {
    return (
        <div className={`${style["avatar_container"]}`}>
        <img src={imgSrc} className={`${style[imgSize]} ${style["avatar_img"]}`}></img>
        {showCrown && <img className={`${style["avatar_crown"]}`} src={crown}></img>}
        </div>
    )
}

export default Avatar
