import React, { useState, useContext } from "react"
export const IdarbContext = React.createContext();

const defaultIdarb = {};

export const IdarbProvider = ({ children, idarb }) => {
  const [currentIdarb, setCurrentIdarb] = useState(
    idarb || {...defaultIdarb}
  );

  const saveIdarb = (values) => {
    setCurrentIdarb(values)
  };

  return (
    <IdarbContext.Provider value={{ idarb: currentIdarb, saveIdarb }}>
      {children}
    </IdarbContext.Provider>
  );
};

export const IdarbConsumer = IdarbContext.Consumer;

export function useIdarb() {
    const context = useContext(IdarbContext);
    return context;
};