import React, { useState, useContext, useEffect } from "react"
import { doAuth } from "../utils/api";
export const UserContext = React.createContext();

const defaultUser = {};

export const UserProvider = ({ children, user }) => {
  const [currentUser, setCurrentUser] = useState(
    user || defaultUser
  );
  const [showTrialModal, setTrialModal] = useState(false);
  const [showMembershipExpireModal, setMembershipExpireModal] = useState(false);

  const hideTrialModal = () => {
    setTrialModal(false)
  };

  const hideMembershipExpireModal = () => {
    setMembershipExpireModal(false)
  };

  const saveUser = (values) => {
    setCurrentUser(values)
  };

  const isLogged = () => {
    const token = localStorage.getItem('token')
    return Object.keys(currentUser).length > 0 && token !== null
  }
  const isMember = () => isLogged() && currentUser.membership 

  const doLogin = async (user, password) => {
    // Call login API
    const res = await doAuth(user, password)
    setCurrentUser(res)
  }

  useEffect(() => {
    window.addEventListener('showTrial', (e) => {
      setTrialModal(true)
    });

    window.addEventListener('showMembershipExpire', (e) => {
      setMembershipExpireModal(true)
    });
  }, []);
  

  return (
    <UserContext.Provider value={{ user: currentUser, saveUser, isLogged: isLogged(), isMember: isMember(), doLogin, showTrialModal, hideTrialModal, showMembershipExpireModal, hideMembershipExpireModal }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserConsumer = UserContext.Consumer;

export function useUser() {
    const context = useContext(UserContext);
    return context;
};