import style from './style.module.css'
function CountrySelect({onChange, countries, initialCountry}) {
    return (
       <div className="select_container">
           <select className={`${style["select_container"]}`} name="languages" onChange={(event) => onChange(event.target.value)}>
                <option className={`${style["select_option"]}`} value={initialCountry || ''}>{'Choose Country'}</option>
                {countries && countries.map(country => <option key={country} className={`${style["select_option"]}`} value={country}>{country}</option>)}
            </select>
       </div>
    )
}

export default CountrySelect
