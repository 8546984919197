import style from './style.module.css'
import redArrow from '../../assets/red-arrow.svg'
import { goToUrl } from '../../utils/constants'

function LegislationFooterLink ({footerLink, linkContent}){
    return (
        <a className={`${style["footer_link-href"]}`} href="#" onClick={(e) => goToUrl(footerLink, e)} target="_blank">
            <h5 className={`${style["footer_link-title"]}`} >{linkContent}</h5>
            <img src={redArrow} className={`${style["footer_link-svg"]}`} ></img>
        </a>
    )
}

function LegislationFooter({country}) {
    const linkText = "Intl Conventions"
    const fistLinkLegislation = '#';
    const secondLinkLegislation = '#';
    const thirdLinkLegislation = '#';
    const fourthLinkLegislation = '#';

    return (
        <div className={`${style["legislation_footer-container"]}`}>
            <LegislationFooterLink linkContent={`${country.charAt(0).toUpperCase() + country.slice(1).toLowerCase()}'s Reports`} footerLink={`documents-reports?search=${country.toLowerCase()}`}/>
            <LegislationFooterLink linkContent={`${country.charAt(0).toUpperCase() + country.slice(1).toLowerCase()}'s Experts`} footerLink={`country-experts?search=${country.toLowerCase()}`}/>
            <LegislationFooterLink linkContent={`${country.charAt(0).toUpperCase() + country.slice(1).toLowerCase()}'s News`} footerLink={`news/category/${country.toLowerCase()}`}/>
            <LegislationFooterLink linkContent={linkText} footerLink={`documents-international-conventions`}/>
        </div>
    )
}

export default LegislationFooter
