import React, { useEffect } from "react";
import style from './style.module.css';
import AddUser from '../../components/AddUser';
import FlowManager from '../../components/FlowManager';
import ShoppingCartSection from '../../components/ShoppingCartSection';
import PaymentScreen from '../../components/PaymentScreen';
import { useCart } from "../../context/CartContext";
import { useUser } from "../../context/UserContext";
import SignUpConferenceSection from "../../components/SignUpConferenceSection";
import { getMembership } from "../../utils/api";
import LoginStep from "../../components/LoginStep";

function LoginAndConference() {

    const {cart, saveCart} = useCart()
    const {isLogged, isMember} = useUser()
    
    const steps = [
        {
            id: 'login',
            title: 'Login',
            component: LoginStep, 
            props: {signupUrl: 'conference-signup'},
            redButtonLabel: 'Login',
            whiteButtonLabel:'Back to',
            hideNavigation: true
        },
        {
            id: 'additionalUsers',
            title: 'Add Person',
            component: AddUser,
            redButtonLabel: 'Continue',
            whiteButtonLabel:'Back to',
            hideNavigation: false
        },
        {
            id: 'conferences',
            title: 'Conferences & Seminars',
            component: SignUpConferenceSection,
            redButtonLabel: 'Continue',
            whiteButtonLabel:'Back to',
            hideNavigation: false
        },
        {
            id: 'cart',
            title: 'Shopping Cart',
            component: ShoppingCartSection,
            redButtonLabel: 'Checkout',
            whiteButtonLabel:'Back to',
            hideNavigation: false
        },
        {
            id: 'billing_information',
            title: 'Payment Details',
            component: PaymentScreen,
            redButtonLabel: 'Pay Now',
            whiteButtonLabel:'Back to',
            hideNavigation: false
        }
    ];


    useEffect(async () => {
        if (isLogged && localStorage.getItem('token')) {
            const res = await getMembership()
            if (res)
                saveCart({
                    renew: true,
                    stripe_customer_id: res.user.stripe_customer_id,
                    mainUser: {...res.user},
                    additionalUsers: [],
                    companyUsers: res.add_users.map(add_user => { return {...add_user, additionalUser: true}}),
                    billing_information: res.billing_information,
                    company: res.company
                })
        }
    }, [isLogged])

    return (
        <>
        <div className={`${style["signup__container"]}`}>
            <FlowManager stepArray={steps} defaultStep={isLogged ? 1 : 0}/>
        </div>
        </>
    )
}

export default LoginAndConference
