import style from './style.module.css';
import parse from "html-react-parser";

function PageDescription({textContent, AlignText}) {

    return (
        <div className= {style.pageDescription} style={{textAlign:AlignText}}>
            {parse(textContent)}
        </div>
    )
}

export default PageDescription
